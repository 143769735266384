/**
 * Application Name: JanSarkar(Mantine Version 6.0.1)
 * Author: Zevcore Private Limited
 * Starting date: 14.03.2023
 * Developer Name: Anand Krishna
 * Last modified date: 14.03.2023
 * Last modified by: Anand Krishna
 **/

const BASE_URL = `https://backend.jansarkar.org/`;
// const BASE_URL = `http://192.168.1.84:3030/`;
const API_URL = BASE_URL + "api";

export const URL_CONSTANTS = {
  // Auth
  LOGIN_URL: `${API_URL}/auth/signin`,
  STATE_URL: `${API_URL}/state`,
  STATE_UPLOAD_URL: `${API_URL}/state_image`,
  STATE_IMAGE: `${BASE_URL}public/images/state/`,
  DISTRICT_URL: `${API_URL}/district`,
  DISTRICT_UPLOAD_URL: `${API_URL}/district_image`,
  DISTRICT_IMAGE: `${BASE_URL}public/images/district/`,
  DESIGNATION_URL: `${API_URL}/designation`,
  TALUK_URL: `${API_URL}/taluk`,
  TALUK_UPLOAD_URL: `${API_URL}/taluk_image`,
  TALUK_IMAGE: `${BASE_URL}public/images/taluk/`,
  GRAMPANCHAYAT_URL: `${API_URL}/gram_panchayat`,
  GRAMPANCHAYAT_UPLOAD_URL: `${API_URL}/gram_panchayat_image`,
  GRAMPANCHAYAT_IMAGE: `${BASE_URL}public/images/grampanchayath/`,
  COMPLAINT_TYPE_URL: `${API_URL}/complaint_type`,
  COMPLAINT_URL: `${API_URL}/complaints`,
  COMPLAINT_IMAGE: `${BASE_URL}public/images/complaints/`,
  CORPORATION_URL: `${API_URL}/corporation`,
  CORPORATION_WARD_URL: `${API_URL}/corporation_ward`,
  CORPORATION_WARD_UPLOAD_URL: `${API_URL}/corporation_ward_image`,
  CORPORATION_WARD_IMAGE: `${BASE_URL}public/images/corporation_ward/`,
  GP_WARD_URL: `${API_URL}/gp_ward`,
  GP_WARD_UPLOAD_URL: `${API_URL}/gp_ward_image`,
  GP_WARD_IMAGE: `${BASE_URL}public/images/gp_ward/`,
  DASHBOARD_BANNER_URL: `${API_URL}/dashboard_banner`,
  DASHBOARD_BANNER_IMAGE: `${BASE_URL}public/images/dashboard/`,
  DEPARTMENT_TYPE_URL: `${API_URL}/department_type`,
  LOKSABHA_URL: `${API_URL}/loksabha`,
  LOKSABHA_UPLOAD_URL: `${API_URL}/loksabha_image`,
  LOKSABHA_IMAGE: `${BASE_URL}public/images/constituency/`,
  VIDHANSABHA_URL: `${API_URL}/vidhansabha`,
  VIDHANSABHA_UPLOAD_URL: `${API_URL}/vidhansabha_image`,
  USER_ONE_URL: `${API_URL}/user`,
  ADMIN_SETTINGS: `${API_URL}/admin_settings`,
  ADMIN_UPLOAD_URL: `${API_URL}/admin_upload`,
  USER_URL: `${API_URL}/user`,
  SCHEME_URL: `${API_URL}/scheme`,
  SCHEME_UPLOAD_URL: `${API_URL}/scheme_image`,
  SCHEME_IMAGE: `${BASE_URL}public/images/scheme/`,
  SCHEME_DEPARTMENT_URL: `${API_URL}/scheme_department`,
  DEPARTMENT_URL: `${API_URL}/govt_department`,
  DEPARTMENT_SEARCH_URL: `${API_URL}/govt_department/search`,
  GOVERNMENT_MINISTRY_URL: `${API_URL}/govt_ministry`,
  QUOTE_URL: `${API_URL}/quote`,
  POLITICAL_PARTY_URL: `${API_URL}/political_party`,
  POLITICAL_PARTY_IMAGE: `${BASE_URL}public/images/politics/`,
  POLITICAL_PARTY_UPLOAD_URL: `${API_URL}/political_party_image`,
  ANNOUNCEMENT_URL: `${API_URL}/announcement`,
  ANNOUNCEMENT_STATUS_URL: `${API_URL}/announcement_status`,
  ANNOUNCEMENT_UPLOAD_URL: `${API_URL}/announcement_image`,
  ANNOUNCEMENT_IMAGE: `${BASE_URL}public/images/announcement/`,
  FEEDBACK_URL: `${API_URL}/feedback`,
  REPRESENTATIVE_URL: `${API_URL}/representative`,
  REPRESENTATIVE_IMAGE: `${BASE_URL}public/images/profile/`,
  REPRESENTATIVE_UPLOAD_URL: `${API_URL}/representative_image`,
  MP_URL: `${API_URL}/representative_mp`,
  MLA_URL: `${API_URL}/representative_mla`,
  CORPORATOR_URL: `${API_URL}/representative_corporators`,
  GPMEMBER_URL: `${API_URL}/representative_gp_member`,
  ASPIRANTS_URL: `${API_URL}/representative_aspirants`,
  ASPIRANTS_USER_URL: `${API_URL}/aspirants_user`,
  VIDHANSABHA_DISTRICT_URL: `${API_URL}/vidhansabha_district2`,
  REGULAR_USER_URL: `${API_URL}/regular_users`,
  REGULAR_USER_DELETE_URL: `${API_URL}/user_delete`,
  LESSON_URL: `${API_URL}/lesson`,
  LESSON_UPLOAD_URL: `${API_URL}/lesson_image`,
  LESSON_CATEGORY_URL: `${API_URL}/lesson_category`,
  LESSON_BANNER_URL: `${API_URL}/lesson_banner`,
  LESSON_BANNER_IMAGE: `${BASE_URL}public/images/lesson/`,
  GOVERNMENT_JOBS_URL: `${API_URL}/govt_jobs`,
  DASHBOARD_COUNT_URL: `${API_URL}/dashboard_counts`,
  DASHBOARD_NATIONAL_PARTY_URL: `${API_URL}/dashboard_national`,
  DASHBOARD_STATE_PARTY_URL: `${API_URL}/dashboard_state`,
  DASHBOARD_USERS_URL: `${API_URL}/dashboard_users`,
  DASHBOARD_ASPIRANTS_URL: `${API_URL}/dashboard_aspirants`,
  DASHBOARD_LESSON_URL: `${API_URL}/dashboard_lessons`,
  DASHBOARD_COMPLAINTS_URL: `${API_URL}/dashboard_complaints`,
};
