import React, { useState } from "react";
import {
  AdjustmentsAlt,
  BuildingBank,
  CircleCheck,
  DatabaseImport,
  Discount2,
  FileInvoice,
  Location,
  Pencil,
  Map,
  Key,
  TableExport,
  TriangleSquareCircle,
  UserExclamation,
  UserPlus,
  MapPin,
  AdjustmentsHorizontal,
  Photo,
  MapPins,
  Tool,
  Message,
  GridDots,
  ClearAll,
} from "tabler-icons-react";
import BreadCrumb from "../../components/Breadcrumbs/BreadCrumbs";
import {
  Box,
  Container,
  Grid,
  Text,
  createStyles,
  Avatar,
} from "@mantine/core";
import { useNavigate } from "react-router-dom"; // For import the react dom for navigation components // for import react dom navigation components
import BreadCrumbs from "../../components/Breadcrumbs/BreadCrumbs";
import Batman from "../../assets/images/images.png";

const useStyles = createStyles((theme) => ({
  // For login page wallpaer style
  SettingBox2: {
    height: 180,
    width: 180,
    padding: 50,
    position: "relative",
    textAlign: "center",
    background: theme.colorScheme === "dark" ? "#25262b" : "#eeeeee",
    color: theme.colorScheme === "dark" ? "white" : "black",
    borderRadius: "8px",
    border: `1px solid ${theme.colorScheme === "dark" ? "#424242" : "white"}`,
    boxShadow: `0 0px 2px 2px ${
      theme.colorScheme === "dark"
        ? "rgba(75, 79, 83, 0.007)"
        : "rgba(175, 183, 190, 0.007)"
    }`,
    transition: "all .4s",
    cursor: "pointer",
    "&:hover": {
      border: `1px solid ${theme.colorScheme === "dark" ? "#546E7A" : "black"}`,
      background: theme.colorScheme === "dark" ? "orange" : "white",
      color: theme.colorScheme === "dark" ? "white" : "#292929",
      boxShadow: "-1px 3px 10px 0 rgb(0 0 0 / 6%)",
      "& svg": {
        opacity: 1,
      },
    },
  },
  editIcon2: {
    position: "absolute",
    top: "6px",
    left: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "18px",
    width: "18px",
    borderRadius: "2px",
    opacity: 0,
    pointerEvents: "none",
    transition: "all .5s",
    "&:hover": {
      opacity: 1,
    },
  },
  Text1: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.gray[3]
        : theme.colors.dark[6],
    fontSize: "20px",
  },
  Text2: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.gray[3]
        : theme.colors.dark[6],
    fontSize: "14px",
  },
}));

function Settings() {
  let navigate = useNavigate();
  const [data, setData] = useState("");
  const { classes } = useStyles();

  const links = [
    { label: "STATE", icon: MapPin, link: "/state" },
    { label: "DISTRICT", icon: Map, link: "/district" },
    { label: "DESIGNATION", icon: Key, link: "/designation" },
    {
      label: "COMPLAINT DEPARTMENT",
      icon: AdjustmentsHorizontal,
      link: "/department",
    },
    { label: "DASHBOARD BANNER", icon: Photo, link: "/dashboard_banner" },
    { label: "TEHSIL", icon: MapPins, link: "/taluk" },
    { label: "ACCOUNT", icon: Tool, link: "/profile" },
    { label: "COMPLAINT", icon: Message, link: "/complaint" },
    { label: "GRAMPANCHAYAT", icon: GridDots, link: "/gramp" },
    { label: "CORPORATION LIST", icon: ClearAll, link: "/corporation_list" },
  ];

  return (
    <div>
      <BreadCrumbs Text="Settings" />
      <div style={{ textAlign: "center", marginTop: 60 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Avatar
            alt="Remy Sharp"
            src={Batman}
            sx={{ width: 156, height: 156 }}
            radius="sm"
          />
        </div>
        <Text className={classes.Text1}>Admin</Text>
      </div>
      <div
        style={{
          width: "65%",
          alignItems: "center",
          margin: "auto",
          // backgroundColor: "#333333",
        }}
      >
        <Grid style={{ marginTop: 10 }}>
          {links.map((item, index) => (
            <Grid.Col md={2} lg={2} style={{ marginRight: 10, marginLeft: 10 }}>
              <div
                className={classes.SettingBox2}
                onClick={() => {
                  navigate(item.link);
                }}
              >
                <Pencil className={classes.editIcon2} size={15} />
                <Box>
                  <item.icon size={50} strokeWidth="0.9" />
                  <Text sx={{ fontSize: "11px" }}>{item.label}</Text>
                </Box>
              </div>
            </Grid.Col>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default Settings;
