/**
 * Application Name: JanSarkar(Mantine Version 6.0.1)
 * Author: Zevcore Private Limited
 * Starting date: 14.03.2023
 * Developer Name: Anand Krishna
 * Last modified date: 15.03.2023
 * Last modified by: Anand Krishna
 **/

import {
  ActionIcon,
  Avatar,
  Anchor,
  Button,
  Card,
  Grid,
  Group,
  Loader,
  Menu,
  Modal,
  Select,
  NativeSelect,
  NumberInput,
  Pagination,
  ScrollArea,
  Skeleton,
  Table,
  Text,
  TextInput,
  useMantineTheme,
  Paper,
  FileButton,
  Badge,
} from "@mantine/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import {
  CloudUpload,
  Dots,
  Download,
  Pencil,
  Photo,
  Search,
  Trash,
  X,
} from "tabler-icons-react";
import { CSVLink } from "react-csv";
// Image
import excel from "../../assets/images/excel.png";
import pdf from "../../assets/images/pdf.png";
import BreadCrumb from "../../components/Breadcrumbs/BreadCrumbs";
import {
  dataSlice,
  getActiveColor,
  imageModal,
  selectFilter,
  onDownload,
  pageModal,
} from "../../helpers/common";
import notificationHelper from "../../helpers/Notification"; // Import notification for this page
import { Th, dataSearch, setSorting, print } from "../../helpers/tableFunction"; // For table data functions
import useStyles from "../../components/Styles/style"; // Import the style from
import {
  handleAddAnnouncement,
  handleAddState,
  handleDeleteAnnouncement,
  handleDeleteState,
  handleEditAnnouncement,
  handleEditState,
  handleGetAnnouncement,
  handleGetDistrict,
  handleGetState,
  handleGetVidhansabha,
  handleGetVidhansabhaDistrict,
  handleUpdateStatusAnnouncement,
  handleUploadAnnouncementImage,
} from "../../helpers/Apis";
import { modals } from "@mantine/modals";
import { useForm } from "@mantine/form";
import { URL_CONSTANTS } from "../../constants/constants";
import { useDebounceEffect } from "../../common/userDebounceEffect.ts";
// for image crop
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import {
  Dropzone,
  DropzoneStatus,
  MIME_TYPES,
  IMAGE_MIME_TYPE,
} from "@mantine/dropzone";
import "react-image-crop/dist/ReactCrop.css";
import { DateInput } from "@mantine/dates";
// RichtextEditor

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 10, // make it 10 for smaller crop ratio
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function Announcements() {
  const { classes } = useStyles();
  const [search, setSearch] = useState("");
  const [opened, setOpened] = useState(false);
  const [state, SetState] = useState([]);
  const [state2, SetState2] = useState([]);
  const [district, SetDistrict] = useState([]);
  const [district2, SetDistrict2] = useState([]);
  const [districtselect, Setdistrictselect] = useState([]);
  const [assembly, setAssembly] = useState([]);
  const [assembly2, setAssembly2] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const theme = useMantineTheme();
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table

  const [value, setValue] = useState();

  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: true,
    submitLoading: false,
    data: [],
    districtList: [],
    addDrawer: false,
    bulkDrawer: false,
    imageDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });

  //   For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        //   For get all the state data list
        const response = await handleGetAnnouncement();
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data,
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setSortedData(datas);
        }

        const response1 = await handleGetState();
        if (response1.status === 200) {
          var data = response1.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.name.toString(),
            id: data.id,
            name: data.name,
          }));
          SetState(clean);
          SetState2(clean);
        }
        // const listGroup = await selectFilter({
        //   data: response1.data.data,
        // });
        // setVariables((variables) => {
        //   return {
        //     ...variables,
        //     districtList: listGroup,
        //   };
        // });

        const response2 = await handleGetDistrict();
        if (response2.status === 200) {
          var data = response2.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.name.toString(),
            state_id: data.state_id,
            id: data.id,
            name: data.name,
            state_id: data.state_id,
          }));
          SetDistrict(clean);
          SetDistrict2(clean);
        }

        const response3 = await handleGetVidhansabha();
        if (response3.status === 200) {
          var data = response3.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.name.toString(),
            district_id: data.district_id,
            id: data.id,
            name: data.name,
            district_id: data.district_id,
          }));
          setAssembly(clean);
          setAssembly2(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Form values for adding announcement data
  const form = useForm({
    initialValues: {
      title: "",
      type: "All",
      state: "",
      end_date: new Date(),
      status: "Active",
      district: "",
      vidhansabha_id: "",
      newsdescription: "",
    },
  });

  // Edit Form Values for updating the values of announcement data
  const editform = useForm({
    initialValues: {
      title: "",
      type: "",
      state: "",
      end_date: "",
      status: "",
      district: "",
      description: "",
      vidhansabha_id: "",
      lastUpdate: "",
      value: "",
    },
  });

  // Form values for adding state values
  const formImage = useForm({
    initialValues: {
      value: "",
      image: "",
    },
  });

  const handleDistrict = async () => {
    const response3 = await handleGetVidhansabhaDistrict(form.values.district);
    if (response3.status === 200) {
      var data = response3.data.data;
      var clean = data.map((data) => ({
        value: data.id.toString(),
        label: data.name.toString(),
        id: data.id,
        name: data.name,
      }));
      setAssembly(clean);
    }
  };

  // const handleDistrict2 = async (e) => {
  //   const response3 = await handleGetVidhansabhaDistrict(e);
  //   if (response3.status === 200) {
  //     var data = response3.data.data;
  //     var clean = data.map((data) => ({
  //       value: data.id.toString(),
  //       label: data.name.toString(),
  //       id: data.id,
  //       name: data.name,
  //     }));
  //     setAssembly(clean);
  //   }
  // };

  // Adding State values
  const handleAdd = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddAnnouncement(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Announcement added successfully",
      });
      form.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  //For delete confirm modal show Delete
  const openConfirmModalDelete = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the announcement <b>{datas.title}</b>{" "}
          ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  //   For delete db data from table and db
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteAnnouncement(e);
    // Check the response for notification and actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Announcement deleted successfully",
      });
      var filter = variables.data;
      filter = filter.filter((img) => img.value !== e);
      setVariables({
        ...variables,
        data: response.data.data.reverse(),
        skeletonLoading: false,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // For editing and settings the value of state
  const handleEdit = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    editform.setFieldValue("title", datas.title);
    editform.setFieldValue("description", datas.link);
    editform.setFieldValue("lastUpdate", datas.updatedAt);
    editform.setFieldValue("type", datas.type);
    editform.setFieldValue("lastUpdate", datas.updatedAt);
    editform.setFieldValue("end_date", new Date(datas.end_date));
    editform.setFieldValue(
      "vidhansabha_id",
      datas.vidhansabha_id != null &&
        datas.vidhansabha_id != "" &&
        typeof datas.vidhansabha_id != "undefined"
        ? datas.vidhansabha_id.toString()
        : ""
    );
    editform.setFieldValue(
      "state",
      datas.state_id != null &&
        datas.state_id != "" &&
        typeof datas.state_id != "undefined"
        ? datas.state_id.toString()
        : ""
    );
    editform.setFieldValue(
      "district",
      datas.district_id != null &&
        datas.district_id != "" &&
        typeof datas.district_id != "undefined"
        ? datas.district_id.toString()
        : ""
    );
    editform.setFieldValue("value", datas.id);
    // editor.commands.setContent(datas.history);
    setVariables({ ...variables, deleteIndex: e, openEdit: true });
  };

  // Edit function for updating state values
  const handleEdits = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditAnnouncement(e);
    // Check for response data for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Accouncement updated successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Crop Image
  const openRef = useRef();
  const [image, setImage] = useState("");
  const [upImg, setUpImg] = useState("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(9 / 16);
  const [cropConfig, setCropConfig] = useState({
    unit: "%",
    width: 50,
    aspect: 9 / 16,
  });
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: 24 / 12,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const ref = useRef();
  const ref45 = useRef();
  const changeHandler = (e) => {
    if (e) {
      console.log(e);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e);
      ref.current.value = "";
    }
  };
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        const TO_RADIANS = Math.PI / 180;
        const image = imgRef.current;
        const crop = completedCrop;
        const canvas = previewCanvasRef.current;
        const scale = 1;
        const rotate = 1;

        const ctx = canvas.getContext("2d");

        if (!ctx) {
          throw new Error("No 2d context");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio;
        // const pixelRatio = 1

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = "high";

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        const rotateRads = rotate * TO_RADIANS;
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;

        ctx.save();

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY);
        // 3) Rotate around the origin
        ctx.rotate(rotateRads);
        // 2) Scale the image
        ctx.scale(scale, scale);
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY);
        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight
        );
        ctx.restore();
        const base64Image = canvas.toDataURL("image/jpeg");
        setImage(base64Image);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const [id_value, setIdValue] = useState();
  const [imageTitle, setImageTitle] = useState();
  const [profileimage, SetProfileimage] = useState("");
  const [loadingState, setLoadingState] = useState(false);

  const openUpload = (e) => {
    setVariables({ ...variables, submitLoading: true });
    var datas = variables.data.find((img) => img.id === e);
    setImageTitle(datas.title);
    SetProfileimage(datas.image);
    setIdValue(e);
    setVariables({ ...variables, deleteIndex: e, imageDrawer: true });
  };

  // Adding State values
  const handleUpload = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    var req = {
      image: image,
      value: id_value,
    };
    e = { ...e, ...req };
    const response = await handleUploadAnnouncementImage(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Announcement Image upload successfully",
      });
      setUpImg("");
      setImage("");
      setVariables({
        ...variables,
        submitLoading: false,
        imageDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false, addDrawer: false });
    }
  };

  //For delete confirm modal show Delete
  const handleStatus = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Status update confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to update status of the announcement{" "}
          <b>{datas.title}</b> ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "green" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmUpdate(e),
    });
  };

  //   For delete db data from table and db
  const handleConfirmUpdate = async (e) => {
    const response = await handleUpdateStatusAnnouncement(e);
    // Check the response for notification and actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Announcement updated successfully",
      });
      var filter = variables.data;
      filter = filter.filter((img) => img.value !== e);
      setVariables({
        ...variables,
        data: response.data.data.reverse(),
        skeletonLoading: false,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Table data arrange by using function and loop throw each data range to table body
  const rows = sortedData.map((row, index) => (
    <tr key={row.id}>
      <td>{activePage * total - total + index + 1}</td>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.image != "" ? (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.ANNOUNCEMENT_IMAGE + row.image,
                  title: row.title,
                });
              }}
              radius={30}
              size={30}
              src={URL_CONSTANTS.ANNOUNCEMENT_IMAGE + row.image}
            />
          ) : (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.ANNOUNCEMENT_IMAGE + row.image,
                  title: row.title,
                });
              }}
              size={20}
              src={URL_CONSTANTS.ANNOUNCEMENT_IMAGE + row.image}
            />
          )}
          <div>
            <Text fz="sm" fw={500}>
              <Anchor
                onClick={() => handleEdit(row.id)}
                component="button"
                size="sm"
              >
                {row.title}
              </Anchor>
            </Text>
          </div>
        </Group>
      </td>
      <td>{row.link}</td>
      <td>{new Date(row.end_date).toLocaleDateString("en-GB")}</td>
      <td>
        {row.status === "Active" ? (
          <Button
            onClick={() => handleStatus(row.id)}
            variant="outline"
            color="teal"
          >
            {row.status}
          </Button>
        ) : (
          <Button
            onClick={() => handleStatus(row.id)}
            variant="outline"
            color="red"
          >
            {row.status}
          </Button>
        )}
      </td>
      <td>
        <Group spacing={0} position="left">
          <ActionIcon onClick={() => openUpload(row.id)}>
            <Photo color="orange" size={18} />
          </ActionIcon>
          <ActionIcon onClick={() => handleEdit(row.id)}>
            <Pencil size={18} color="gray" />
          </ActionIcon>
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.id)}
            color="red"
          >
            <Trash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  return (
    <div>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb
          Text="Announcement"
          Title="Settings"
          titleLink="/settings"
        />
      </Skeleton>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card className="border">
          <ScrollArea>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* For search the table data input forms */}
              <TextInput
                variant="filled"
                placeholder="Search"
                mb="md"
                size="sm"
                value={search}
                icon={<Search size={16} />}
                onChange={async (e) => {
                  // On change search ofr the data that is enter
                  setSearch(e.currentTarget.value);
                  setPage(1);
                  const datas = await dataSearch({
                    data: variables.data,
                    value: e.currentTarget.value,
                    activePage: activePage,
                    total: total,
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                sx={{ width: 250 }}
              />
              <div>
                <Group spacing="xs">
                  {/* For export the the table data to pdf and excels */}
                  <Text>Exports :</Text>
                  <CSVLink
                    data={variables.data}
                    headers={[
                      { label: "id", key: "id" },
                      { label: "title", key: "title" },
                      { label: "link", key: "link" },
                      { label: "type", key: "type" },
                      { label: "state_id", key: "state_id" },
                      { label: "district_id", key: "district_id" },
                      { label: "vidhansabha_id ", key: "vidhansabha_id " },
                      { label: "createdAt", key: "createdAt" },
                      { label: "updatedAt", key: "updatedAt" },
                    ]}
                    filename="announcement.csv"
                    className={classes.pdfExcel}
                  >
                    <img
                      src={excel}
                      alt="excel"
                      width="25"
                      style={{ margin: "2px" }}
                    />
                  </CSVLink>
                  <button
                    className={classes.pdfExcel}
                    onClick={() =>
                      print({
                        name: "Announcement",
                        data: variables.data,
                        list: [
                          "id",
                          "title",
                          "link",
                          "type",
                          "state_id",
                          "district_id",
                          "vidhansabha_id ",
                          "createdAt",
                          "updatedAt",
                        ],
                        header: [
                          "id",
                          "title",
                          "link",
                          "type",
                          "state_id",
                          "district_id",
                          "vidhansabha_id ",
                          "createdAt",
                          "updatedAt",
                        ],
                      })
                    }
                  >
                    <img
                      src={pdf}
                      alt="pdf"
                      width="19"
                      style={{ margin: "2px" }}
                    />
                  </button>
                  {/* Drawer open for adding new tax data */}
                  <Button
                    variant="outline"
                    color="orange"
                    size="xs"
                    onClick={() =>
                      setVariables({ ...variables, addDrawer: true })
                    }
                  >
                    + Add Announcement
                  </Button>
                </Group>
              </div>
            </div>
            {/* className={classes.striped} */}
            <Table verticalSpacing="sm">
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Announcement Title</th>
                  <th>Announcement Link</th>
                  <th>End Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {rows.length > 0 ? (
                <tbody>{rows}</tbody>
              ) : (
                <>
                  {loadingData === false ? (
                    <tbody>
                      <tr>
                        <td colSpan={4}>
                          <div style={{ textAlign: "center" }}>
                            <Loader size="xl" color="orange" variant="dots" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={4}>
                          <div style={{ textAlign: "center" }}>
                            <Text weight={500} align="center">
                              Nothing found
                            </Text>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </ScrollArea>
          {/* For display the pagination and no of per pages list */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 15,
            }}
          >
            {/* For pagination */}
            <Pagination
              withEdges
              size="sm"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="orange"
            />
          </div>
        </Card>
      </Skeleton>

      {/* State Add Modal */}
      <Modal
        opened={variables.addDrawer}
        onClose={() => setVariables({ ...variables, addDrawer: false })}
        title={"Add New Announcement"}
        size="xl"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        {/* Drawer content */}
        <div style={{ padding: "10px" }}>
          <form onSubmit={form.onSubmit((values) => handleAdd(values))}>
            <Grid>
              <Grid.Col md={6} lg={6}>
                <TextInput
                  label="Title"
                  required
                  withAsterisk
                  placeholder="Title"
                  {...form.getInputProps("title")}
                />
              </Grid.Col>
              <Grid.Col md={6} lg={6}>
                <Select
                  searchable
                  clearable
                  required
                  label="Select Type"
                  withAsterisk
                  data={[
                    { value: "All", label: "All" },
                    { value: "Specific", label: "Specific" },
                  ]}
                  {...form.getInputProps("type")}
                />
              </Grid.Col>

              {form.values.type == "Specific" ? (
                <>
                  <Grid.Col md={6} lg={6}>
                    <Select
                      label="Select State"
                      searchable
                      clearable
                      required
                      withAsterisk
                      placeholder="Select State"
                      data={state}
                      {...form.getInputProps("state")}
                    />
                  </Grid.Col>
                  <Grid.Col md={6} lg={6}>
                    <Select
                      searchable
                      clearable
                      required
                      withAsterisk
                      placeholder="Select District"
                      label="Select District"
                      data={district.filter((raw) => {
                        if (Number(form.values.state) != "") {
                          return (
                            Number(raw.state_id) === Number(form.values.state)
                          );
                        }
                      })}
                      {...form.getInputProps("district")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Select
                      placeholder="Select Assembly Constituency"
                      label="Select Assembly Constituency"
                      searchable
                      required
                      withAsterisk
                      clearable
                      data={assembly.filter((raw) => {
                        if (Number(form.values.district) != "") {
                          return (
                            Number(raw.district_id) ===
                            Number(form.values.district)
                          );
                        }
                      })}
                      {...form.getInputProps("vidhansabha_id")}
                    />
                  </Grid.Col>
                </>
              ) : null}

              <Grid.Col md={12} lg={12}>
                <DateInput
                  value={value}
                  onChange={setValue}
                  label="End Date"
                  placeholder="End Date"
                  {...form.getInputProps("end_date")}
                />
              </Grid.Col>

              <Grid.Col md={12} lg={12}>
                <TextInput
                  label="Link"
                  required
                  withAsterisk
                  placeholder="Link"
                  {...form.getInputProps("description")}
                />
              </Grid.Col>

              <Grid.Col md={12} lg={12}>
                <Button
                  fullWidth
                  radius="md"
                  mt="xl"
                  mb={10}
                  type="submit"
                  size="md"
                  variant="gradient"
                  gradient={{ from: "orange", to: "red" }}
                >
                  Add Announcement
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </Modal>
      {/* // Announcement Add MOdal */}

      {/* Edit Modal */}
      <Modal
        opened={variables.openEdit}
        onClose={() => setVariables({ ...variables, openEdit: false })}
        title={"Update Announcement Details"}
        size="xl"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div style={{ padding: "10px" }}>
          <form onSubmit={editform.onSubmit((values) => handleEdits(values))}>
            <Grid>
              <Grid.Col md={6} lg={6}>
                <TextInput
                  label="Announcement Title"
                  required
                  withAsterisk
                  placeholder="Announcement Title"
                  {...editform.getInputProps("title")}
                />
              </Grid.Col>
              <Grid.Col md={6} lg={6}>
                <Text mt={10} size="xs">
                  Select Type
                </Text>
                <Select
                  searchable
                  clearable
                  required
                  withAsterisk
                  data={[
                    { value: "All", label: "All" },
                    { value: "Specific", label: "Specific" },
                  ]}
                  {...editform.getInputProps("type")}
                />
              </Grid.Col>

              {editform.values.type == "Specific" ? (
                <>
                  <Grid.Col md={6} lg={6}>
                    <Select
                      label="State"
                      searchable
                      clearable
                      required
                      placeholder="Select State"
                      data={state}
                      {...editform.getInputProps("state")}
                    />
                  </Grid.Col>
                  <Grid.Col md={6} lg={6}>
                    <Select
                      searchable
                      clearable
                      label="Select District"
                      placeholder="Select District"
                      data={district}
                      {...editform.getInputProps("district")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Select
                      searchable
                      clearable
                      label="Select Assembly Constituency"
                      placeholder="Select Assembly Constituency"
                      data={assembly}
                      // onDropdownOpen={handleDistrict2}
                      {...editform.getInputProps("vidhansabha_id")}
                    />
                  </Grid.Col>
                </>
              ) : null}

              <Grid.Col md={12} lg={12}>
                <DateInput
                  value={value}
                  onChange={setValue}
                  label="End Date"
                  placeholder="End Date"
                  {...editform.getInputProps("end_date")}
                />
              </Grid.Col>

              <Grid.Col md={12} lg={12}>
                <TextInput
                  label="Announcement Link"
                  placeholder="Announcement Link"
                  {...editform.getInputProps("description")}
                />
              </Grid.Col>
              <Button
                fullWidth
                radius="md"
                type="submit"
                size="md"
                variant="gradient"
                gradient={{ from: "orange", to: "red" }}
              >
                Update Announcement
              </Button>
            </Grid>
          </form>
        </div>
      </Modal>
      {/* Edit Modal */}

      {/* Image Modal */}
      <Modal
        opened={variables.imageDrawer}
        onClose={() => setVariables({ ...variables, imageDrawer: false })}
        title={imageTitle}
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <form onSubmit={formImage.onSubmit((values) => handleUpload(values))}>
          <Grid style={{ padding: "10px" }}>
            <Grid.Col md={12} lg={12} mt={1}>
              {/* <Text>Upload State Image</Text> */}
            </Grid.Col>
            <Grid.Col md={12} lg={12} mt={1}>
              {/* For image croper */}
              {/* For croper */}
              {upImg !== "" && upImg !== null ? (
                <>
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={upImg}
                      style={{
                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                      }}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                  <div>
                    {previewCanvasRef != null ? (
                      <canvas
                        ref={previewCanvasRef}
                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                        style={{
                          width: Math.round(completedCrop?.width ?? 0),
                          height: Math.round(completedCrop?.height ?? 0),
                          marginBottom: 50,
                          display: "none",
                        }}
                      />
                    ) : null}

                    <Group position="right" mt="md" mb={20}>
                      <Button
                        type="submit"
                        color="zevcore"
                        onClick={() => {
                          setUpImg("");
                          setImage("");
                        }}
                      >
                        Clear Image
                      </Button>
                    </Group>
                  </div>
                </>
              ) : (
                // For selecting cropping image dropdown
                <div
                  style={{
                    marginTop: 15,
                    position: "relative",
                    marginBottom: 30,
                  }}
                >
                  <Paper
                    p="lg"
                    sx={(theme) => ({
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[8]
                          : theme.white,
                    })}
                  >
                    <Avatar
                      src={URL_CONSTANTS.ANNOUNCEMENT_IMAGE + profileimage}
                      size={150}
                      radius={100}
                      withBorder
                      mx="auto"
                    />
                    <Text ta="center" fz="lg" weight={500} mt="md">
                      {imageTitle}
                    </Text>

                    <Group position="center">
                      <FileButton
                        resetRef={ref}
                        onChange={changeHandler}
                        accept={[
                          "image/png",
                          "image/jpeg",
                          "image/sgv+xml",
                          "image/gif",
                        ]}
                      >
                        {(props) => (
                          <Button mt={20} {...props}>
                            Select Image
                          </Button>
                        )}
                      </FileButton>
                    </Group>
                  </Paper>
                </div>
              )}
            </Grid.Col>
            <Button
              loading={loadingState}
              fullWidth
              type="submit"
              size="md"
              variant="gradient"
              gradient={{ from: "orange", to: "red" }}
            >
              Upload Announcement Image
            </Button>
          </Grid>
        </form>
      </Modal>
      {/* Image Modal */}
    </div>
  );
}

export default Announcements;
