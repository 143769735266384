/**
 * Application Name: JanSarkar(Mantine Version 6.0.1)
 * Author: Zevcore Private Limited
 * Starting date: 14.03.2023
 * Developer Name: Anand Krishna
 * Last modified date: 14.03.2023
 * Last modified by: Anand Krishna
 **/

import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
// Authentication
import Login from "./pages/Authentication/Login";
// Dashboard
import Dashboard from "./pages/Dashboard";
// Mantine
import {
  MantineProvider,
  ColorSchemeProvider,
  ColorScheme,
} from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { useLocalStorage } from "@mantine/hooks";

// Sidebar
import Sidebar from "./components/Sidebar/Sidebar";

// Protected Routes
import ProtectedRoute from "./components/ProtectedRoutes/ProtectedRoute";

// Error Page
import ErrorBoundary from "./components/Error/Error";
import Settings from "./pages/Settings/Settings";
import State from "./pages/Settings/State";
import District from "./pages/Settings/District";
import Designation from "./pages/Settings/Designation";
import DashboardBanner from "./pages/Settings/DashboardBanner";
import Taluk from "./pages/Settings/Taluk";
import Grampanchayat from "./pages/Settings/Grampanchayat";
import CorporationList from "./pages/Settings/CorporationList";
import Profile from "./pages/Settings/Profile";
import ComplaintType from "./pages/Settings/ComplaintType";
import ComplaintDepartment from "./pages/Settings/ComplaintDepartment";

// Announcement
import Announcements from "./pages/Announcements/Announcements";
// Feedback
import Feedback from "./pages/Feedback/Feedback";
// Quotes
import Quotes from "./pages/Quotes/Quotes";

// Constituency
import LoksabahaConstituency from "./pages/Constituency/LoksabhaConstituency";
import AssemblyConstituency from "./pages/Constituency/AssemblyConstituency";
import CorporationWard from "./pages/Constituency/CorporationWard";
import GPWard from "./pages/Constituency/GPWard";

// Representatives
import MP from "./pages/Representatives/MP";
import MLA from "./pages/Representatives/MLA";
import Corporator from "./pages/Representatives/Corporator";
import GPMemeber from "./pages/Representatives/GPMemeber";
import Aspirants from "./pages/Representatives/Aspirants";
import Representative from "./pages/Representatives/Representative";

// News / Lessons
import LessonBanner from "./pages/News/LessonBanner";
import LessonCategory from "./pages/News/LessonCategory";
import Lessons from "./pages/News/Lessons";

// Complaints
import Complaints from "./pages/Complaints/Complaints";

// Jobs
import Jobs from "./pages/Jobs/Jobs";

// Political Party
import PoliticalParty from "./pages/PoliticalParties/PoliticalParty";
import StateParty from "./pages/PoliticalParties/StateParty";

// Regular User
import RegularUser from "./pages/RegularUser/RegularUser";

// Notifications
import NotificationScreen from "./pages/Notifications/NotificationScreen";

// Government
import Department from "./pages/Government/Department";
import GovernmentMinistry from "./pages/Government/GovernmentMinistry";

// Schemes
import Scheme from "./pages/Schemes/Scheme";
import SchemeDepartment from "./pages/Schemes/SchemeDepartment";

function App() {
  // For get the dark light toggle
  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  // Get the last mode
  const [colorScheme, setColorScheme] = useLocalStorage({
    key: "mantine-color-scheme",
    defaultValue: "light",
  });
  // Change the color if u want to '#043c64' Carefully wile choosing dark color only
  const [color] = useLocalStorage({
    key: "color",
    defaultValue: "#043c64",
  });

  // For make color lighter by 10 colors level
  const LightenDarkenColor = (col, amt) => {
    var usePound = false;
    if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
    }
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    var b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    var g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  };
  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          colorScheme: "light",
          colors: {
            // override dark colors to change them for all components
            dark: [
              "#d5d7e0",
              "#acaebf",
              "#8c8fa3",
              "#666980",
              "#4d4f66",
              "#34354a",
              "#2b2c3d",
              "#1d1e30",
              "#0c0d21",
              "#01010a",
            ],
          },
        }}
      >
        <Notifications position="top-right" autoClose={3000} />
        <ErrorBoundary>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<Sidebar />}>
              <Route
                exact
                path="/"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/settings"
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/state"
                element={
                  <ProtectedRoute>
                    <State />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/district"
                element={
                  <ProtectedRoute>
                    <District />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/designation"
                element={
                  <ProtectedRoute>
                    <Designation />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/department"
                element={
                  <ProtectedRoute>
                    <ComplaintDepartment />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/dashboard_banner"
                element={
                  <ProtectedRoute>
                    <DashboardBanner />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/taluk"
                element={
                  <ProtectedRoute>
                    <Taluk />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/complaint"
                element={
                  <ProtectedRoute>
                    <ComplaintType />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/gramp"
                element={
                  <ProtectedRoute>
                    <Grampanchayat />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/corporation_list"
                element={
                  <ProtectedRoute>
                    <CorporationList />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/announcement"
                element={
                  <ProtectedRoute>
                    <Announcements />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/feedback"
                element={
                  <ProtectedRoute>
                    <Feedback />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/quote"
                element={
                  <ProtectedRoute>
                    <Quotes />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/lessons"
                element={
                  <ProtectedRoute>
                    <Lessons />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/lesson_category"
                element={
                  <ProtectedRoute>
                    <LessonCategory />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/lesson_banner"
                element={
                  <ProtectedRoute>
                    <LessonBanner />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Mp"
                element={
                  <ProtectedRoute>
                    <MP />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Mla"
                element={
                  <ProtectedRoute>
                    <MLA />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/corporator"
                element={
                  <ProtectedRoute>
                    <Corporator />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Gp"
                element={
                  <ProtectedRoute>
                    <GPMemeber />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/aspirants"
                element={
                  <ProtectedRoute>
                    <Aspirants />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/representative"
                element={
                  <ProtectedRoute>
                    <Representative />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/loksabha"
                element={
                  <ProtectedRoute>
                    <LoksabahaConstituency />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/party"
                element={
                  <ProtectedRoute>
                    <PoliticalParty />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/state_party"
                element={
                  <ProtectedRoute>
                    <StateParty />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/scheme"
                element={
                  <ProtectedRoute>
                    <Scheme />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/scheme_department"
                element={
                  <ProtectedRoute>
                    <SchemeDepartment />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/jobs"
                element={
                  <ProtectedRoute>
                    <Jobs />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/complaints"
                element={
                  <ProtectedRoute>
                    <Complaints />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user"
                element={
                  <ProtectedRoute>
                    <RegularUser />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/assembly"
                element={
                  <ProtectedRoute>
                    <AssemblyConstituency />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/corporation"
                element={
                  <ProtectedRoute>
                    <CorporationWard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Gpward"
                element={
                  <ProtectedRoute>
                    <GPWard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notifications"
                element={
                  <ProtectedRoute>
                    <NotificationScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/govt_ministry"
                element={
                  <ProtectedRoute>
                    <GovernmentMinistry />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/govt"
                element={
                  <ProtectedRoute>
                    <Department />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Routes>
        </ErrorBoundary>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
