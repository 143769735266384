/**
 * Application Name: JanSarkar(Mantine Version 6.0.1)
 * Author: Zevcore Private Limited
 * Starting date: 14.03.2023
 * Developer Name: Anand Krishna
 * Last modified date: 15.03.2023
 * Last modified by: Anand Krishna
 **/

import {
  Avatar,
  Button,
  Card,
  Grid,
  Group,
  Modal,
  NumberInput,
  Skeleton,
  Text,
  TextInput,
  useMantineTheme,
  Paper,
  FileButton,
  PasswordInput,
} from "@mantine/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import BreadCrumb from "../../components/Breadcrumbs/BreadCrumbs";
import notificationHelper from "../../helpers/Notification"; // Import notification for this page
import useStyles from "../../components/Styles/style"; // Import the style from
import {
  handleEditAdminProfile,
  handleEditState,
  handleGetUserOne,
  handleUploadAdminImage,
  handleUploadStateImage,
} from "../../helpers/Apis";
import { modals } from "@mantine/modals";
import { useForm } from "@mantine/form";
import { URL_CONSTANTS } from "../../constants/constants";
import { useDebounceEffect } from "../../common/userDebounceEffect.ts";
// for image crop
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import {
  Dropzone,
  DropzoneStatus,
  MIME_TYPES,
  IMAGE_MIME_TYPE,
} from "@mantine/dropzone";
import "react-image-crop/dist/ReactCrop.css";
// RichtextEditor

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 10, // make it 10 for smaller crop ratio
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function Profile() {
  const { classes } = useStyles();
  const [sortedData, setSortedData] = useState([]);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const theme = useMantineTheme();
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table

  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: true,
    submitLoading: false,
    data: "",
    bulkDrawer: false,
    imageDrawer: false,
    deleteIndex: 0,
  });

  //   For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        //   For get all the state data list
        const response = await handleGetUserOne();
        // On Response setting the data to variable
        if (response.status === 200) {
          setName(response.data.username);
          setEmail(response.data.email);
          editform.setFieldValue("name", response.data.username);
          editform.setFieldValue("email", response.data.email);
          editform.setFieldValue("phone", response.data.phone_number);
          SetProfileimage(response.data.image);
          setVariables({
            ...variables,
            skeletonLoading: false,
            data: response.data,
          });
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const editform = useForm({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
      confirm_password: "",
    },
  });
  // 7984651326

  // Form values for adding state values
  const formImage = useForm({
    initialValues: {
      value: "",
      image: "",
    },
  });

  // Edit function for updating state values
  const handleEdits = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditAdminProfile(e);
    // Check for response data for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Profile updated successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        openEdit: false,
        data: response.data.data,
      });
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false, openEdit: false });
    }
  };

  const [id_value, setIdValue] = useState();
  const [imageTitle, setImageTitle] = useState();
  const [profileimage, SetProfileimage] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const openUpload = (e) => {
    console.log(variables.data);
    setVariables({ ...variables, submitLoading: true });
    var datas = variables.data;
    setImageTitle(datas.username);
    SetProfileimage(datas.image);
    setIdValue(e);
    setVariables({ ...variables, deleteIndex: e, imageDrawer: true });
  };

  // upload admin image values
  const handleUpload = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    var req = {
      image: image,
      value: id_value,
    };
    e = { ...e, ...req };
    const response = await handleUploadAdminImage(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Profile Image upload successfully",
      });
      SetProfileimage(response.data.data.image);
      setVariables({
        ...variables,
        submitLoading: false,
        imageDrawer: false,
      });
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false, addDrawer: false });
    }
  };

  // Crop Image
  const openRef = useRef();
  const [image, setImage] = useState("");
  const [upImg, setUpImg] = useState("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1 / 1);
  const [cropConfig, setCropConfig] = useState({
    unit: "%",
    width: 50,
    aspect: 9 / 16,
  });
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: 24 / 12,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const ref = useRef();
  const ref45 = useRef();
  const changeHandler = (e) => {
    if (e) {
      console.log(e);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e);
      ref.current.value = "";
    }
  };
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        const TO_RADIANS = Math.PI / 180;
        const image = imgRef.current;
        const crop = completedCrop;
        const canvas = previewCanvasRef.current;
        const scale = 1;
        const rotate = 1;

        const ctx = canvas.getContext("2d");

        if (!ctx) {
          throw new Error("No 2d context");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio;
        // const pixelRatio = 1

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = "high";

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        const rotateRads = rotate * TO_RADIANS;
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;

        ctx.save();

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY);
        // 3) Rotate around the origin
        ctx.rotate(rotateRads);
        // 2) Scale the image
        ctx.scale(scale, scale);
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY);
        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight
        );
        ctx.restore();
        const base64Image = canvas.toDataURL("image/jpeg");
        setImage(base64Image);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  return (
    <div>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb
          Text="Profile Settings"
          Title="Settings"
          titleLink="/settings"
        />
      </Skeleton>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card className="border">
          <Grid>
            <Grid.Col xs={4}>
              <form
                onSubmit={formImage.onSubmit((values) => handleUpload(values))}
              >
                <Paper
                  radius="md"
                  withBorder
                  p="lg"
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[8]
                        : theme.white,
                  })}
                >
                  <Avatar
                    src={URL_CONSTANTS.REPRESENTATIVE_IMAGE + profileimage}
                    onClick={() => openUpload(1)}
                    size={120}
                    radius={120}
                    mx="auto"
                  />
                  <Text ta="center" fz="lg" weight={500} mt="md">
                    {name}
                  </Text>
                  <Text ta="center" c="dimmed" fz="sm">
                    {email}
                  </Text>

                  <Button
                    loading={loadingState}
                    mt={10}
                    fullWidth
                    radius="md"
                    onClick={() => openUpload(1)}
                    size="sm"
                    variant="outline"
                    color="orange"
                  >
                    Upload New Photo
                  </Button>
                </Paper>
              </form>
            </Grid.Col>
            <Grid.Col xs={8}>
              <Card
                withBorder
                p="lg"
                radius="md"
                className={classes.card}
                shadow="sm"
              >
                <Text size="xl" weight={500} mb={10}>
                  Edit Profile
                </Text>
                <form
                  onSubmit={editform.onSubmit((values) => handleEdits(values))}
                  style={{ padding: 5 }}
                >
                  <Grid>
                    <Grid.Col xs={6}>
                      <TextInput
                        label="Name"
                        required
                        withAsterisk
                        placeholder="Name"
                        {...editform.getInputProps("name")}
                      />
                    </Grid.Col>
                    <Grid.Col xs={6}>
                      <NumberInput
                        label="Phone Number"
                        required
                        withAsterisk
                        placeholder="Phone number"
                        {...editform.getInputProps("phone")}
                      />
                    </Grid.Col>
                    <Grid.Col xs={6}>
                      <TextInput
                        label="Email"
                        required
                        withAsterisk
                        placeholder="example@mail.com"
                        {...editform.getInputProps("email")}
                      />
                    </Grid.Col>
                    <Grid.Col xs={6}>
                      <PasswordInput
                        label="Password"
                        required
                        withAsterisk
                        placeholder="Password"
                        {...editform.getInputProps("password")}
                      />
                    </Grid.Col>
                    <Grid.Col xs={6}>
                      <PasswordInput
                        required
                        withAsterisk
                        label="Confirm password"
                        placeholder="Confirm password"
                        {...editform.getInputProps("confirm_password")}
                      />
                    </Grid.Col>
                    <Grid.Col xs={6}></Grid.Col>
                    <Grid.Col xs={4}>
                      <Button
                        loading={loadingState}
                        fullWidth
                        radius="md"
                        type="submit"
                        size="sm"
                        variant="outline"
                        color="orange"
                      >
                        Update Info
                      </Button>
                    </Grid.Col>
                  </Grid>
                </form>
              </Card>
            </Grid.Col>
          </Grid>
        </Card>
      </Skeleton>

      {/* Image Modal */}
      <Modal
        opened={variables.imageDrawer}
        onClose={() => setVariables({ ...variables, imageDrawer: false })}
        title={imageTitle}
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <form onSubmit={formImage.onSubmit((values) => handleUpload(values))}>
          <Grid style={{ padding: "10px" }}>
            <Grid.Col md={12} lg={12} mt={1}>
              {/* <Text>Upload State Image</Text> */}
            </Grid.Col>
            <Grid.Col md={12} lg={12} mt={1}>
              {/* For image cropper */}
              {/* For cropper */}
              {upImg !== "" && upImg !== null ? (
                <>
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={upImg}
                      style={{
                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                      }}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                  <div>
                    {previewCanvasRef != null ? (
                      <canvas
                        ref={previewCanvasRef}
                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                        style={{
                          width: Math.round(completedCrop?.width ?? 0),
                          height: Math.round(completedCrop?.height ?? 0),
                          marginBottom: 50,
                          display: "none",
                        }}
                      />
                    ) : null}

                    <Group position="right" mt="md" mb={20}>
                      <Button
                        type="submit"
                        color="zevcore"
                        onClick={() => {
                          setUpImg("");
                          setImage("");
                        }}
                      >
                        Clear Image
                      </Button>
                    </Group>
                  </div>
                </>
              ) : (
                // For selecting cropping image dropdown
                <div
                  style={{
                    marginTop: 15,
                    position: "relative",
                    marginBottom: 30,
                  }}
                >
                  <Paper
                    p="lg"
                    sx={(theme) => ({
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[8]
                          : theme.white,
                    })}
                  >
                    <Avatar
                      src={URL_CONSTANTS.REPRESENTATIVE_IMAGE + profileimage}
                      size={150}
                      radius={100}
                      withBorder
                      mx="auto"
                    />
                    <Text ta="center" fz="lg" weight={500} mt="md">
                      {imageTitle}
                    </Text>

                    <Group position="center">
                      <FileButton
                        resetRef={ref}
                        onChange={changeHandler}
                        accept={[
                          "image/png",
                          "image/jpeg",
                          "image/sgv+xml",
                          "image/gif",
                        ]}
                      >
                        {(props) => (
                          <Button mt={20} {...props}>
                            Select Image
                          </Button>
                        )}
                      </FileButton>
                    </Group>
                  </Paper>
                </div>
              )}
            </Grid.Col>
            <Button
              loading={loadingState}
              fullWidth
              type="submit"
              size="sm"
              variant="outline"
              color="orange"
            >
              Update Profile Image
            </Button>
          </Grid>
        </form>
      </Modal>
      {/* Image Modal */}
    </div>
  );
}

export default Profile;
