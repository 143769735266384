/**
 * Application Name: JanSarkar(Mantine Version 6.0.1)
 * Author: Zevcore Private Limited
 * Starting date: 14.03.2023
 * Developer Name: Anand Krishna
 * Last modified date: 14.03.2023
 * Last modified by: Anand Krishna
 **/

import {
  Button,
  createStyles,
  Paper,
  PasswordInput,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form"; // Mantine form import
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logins from "../../assets/images/Login.png";
import Logo from "../../assets/images/logo2.png";
import { handleLogin } from "../../helpers/Apis"; // For connect to the electron backend from this helper apis // Helper for import the axios request in open page
import { notificationHelper } from "../../helpers/Notification";

// Styles for login page
const useStyles = createStyles((theme) => ({
  body: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
  },
  wrapper: {
    backgroundSize: "center",
    backgroundPosition: "center",
    padding: 50,
    minHeight: "100vh",
    backgroundImage: `url(${Logins})`,
    backgroundRepeat: "no-repeat",
  },

  form: {
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: 400,
    borderRadius: 12,
    maxWidth: 450,
    paddingTop: 80,
    marginLeft: "auto",

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  logo: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    width: 120,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export default function Login() {
  let navigate = useNavigate();
  // Mantine custome style use
  const { classes } = useStyles();

  // Setting the variables data list here
  const [variables, setVariables] = useState({
    submitLoading: false,
    userCount: 0,
  });

  // For form validation
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) => (value.length < 1 ? "Email is required" : null),
      password: (value) => (value.length < 1 ? "Password is required" : null),
    },
  });

  // Login function
  // Submit the login information
  const handleSubmit = async (e) => {
    console.log(e);
    setVariables({ ...variables, submitLoading: true });
    const response = await handleLogin(e);
    console.log(response);
    if (response.status === 200) {
      console.log(response.data.roles);
      localStorage.setItem("token", response.data.accessToken);
      localStorage.setItem("role", response.data.roles);
      localStorage.setItem("isAuthenticated", "true");
      setTimeout(() => {
        navigate("/");
      }, 300);
      notificationHelper({
        color: "green",
        title: "Success",
        message: "You have logged in successfully",
      });
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed!",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <div className={classes.body}>
      <div className={classes.wrapper}>
        <Paper className={classes.form} radius={0} p={30}>
          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div></div>
            <Switch checked={colorScheme === 'dark'} onChange={() => toggleColorScheme()} size="md" />
          </div> */}
          {/* Login form */}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={Logo} alt="logo" style={{ width: 100 }}></img>
          </div>
          <Text
            mt={-20}
            mb={30}
            sx={{ color: "#3d3d3d", fontSize: 22, textAlign: "center" }}
            size="xl"
          >
            JanSarkar
          </Text>
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInput
              variant="filled"
              value={form.values.email}
              label="Email address or Phone No"
              placeholder="Enter Email or Phone No"
              size="md"
              {...form.getInputProps("email")}
            />
            <PasswordInput
              variant="filled"
              label="Password"
              placeholder="Your password"
              mt="md"
              size="md"
              value={form.values.password}
              {...form.getInputProps("password")}
            />
            <Button
              fullWidth
              type="submit"
              mt="xl"
              size="md"
              variant="gradient"
              gradient={{ from: "orange", to: "red" }}
              loading={variables.submitLoading}
            >
              Login
            </Button>
          </form>
        </Paper>
      </div>
    </div>
  );
}
