/**
 * Application Name: JanSarkar(Mantine Version 6.0.1)
 * Author: Zevcore Private Limited
 * Starting date: 14.03.2023
 * Developer Name: Anand Krishna
 * Last modified date: 15.03.2023
 * Last modified by: Anand Krishna
 **/

import axios from "axios";
import { URL_CONSTANTS } from "../constants/constants";

// Token
const config = {
  headers: {
    "x-access-token": localStorage.getItem("token"),
  },
};

const fetchCredentials = async () => {
  try {
    // Retrieve the credentials
    const credentials = await localStorage.getItem("token");
    if (credentials) {
      return {
        headers: {
          "x-access-token": credentials,
        },
      };
    } else {
      console.log("No credentials stored");
    }
  } catch (error) {
    console.log("Keychain couldn't be accessed!", error);
  }
  return false;
};

// AUTH API

// For handle login
export const handleLogin = async (request) => {
  try {
    // URL for login
    const response = await axios.post(URL_CONSTANTS.LOGIN_URL, request);
    return response;
  } catch (error) {
    return error.response;
  }
};

// USER API

// For handle get state data list
export const handleGetUserOne = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.USER_ONE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const handleEditAdminProfile = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.ADMIN_SETTINGS,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete admin data list
export const handleUploadAdminImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.ADMIN_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// USER API

// STATE API

// For handle get state data list
export const handleGetState = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.STATE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add state data list
export const handleAddState = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.STATE_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit state data list
export const handleEditState = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.STATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete state data list
export const handleUploadStateImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.STATE_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete state data list
export const handleDeleteState = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.STATE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit state data list
export const handleBulkState = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.STATE_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// STATE API

// DISTRICT API

// For handle get district data list
export const handleGetDistrict = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.DISTRICT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add district data list
export const handleAddDistrict = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.DISTRICT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit district data list
export const handleEditDistrict = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.DISTRICT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete state data list
export const handleUploadDistrictImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.DISTRICT_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete district data list
export const handleDeleteDistrict = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.DISTRICT_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit district data list
export const handleBulkDistrict = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.DISTRICT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// DISTRICT API

// TALUK API

// For handle get taluk data list
export const handleGetTaluk = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.TALUK_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add taluk data list
export const handleAddTaluk = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.TALUK_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit taluk data list
export const handleEditTaluk = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.TALUK_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete state data list
export const handleUploadTalukImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.TALUK_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete taluk data list
export const handleDeleteTaluk = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.TALUK_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit taluk data list
export const handleBulkTaluk = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.TALUK_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// TALUK API

// DESIGNATION API

// For handle get designation data list
export const handleGetDesignation = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.DESIGNATION_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add designation data list
export const handleAddDesignation = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.DESIGNATION_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit designation data list
export const handleEditDesignation = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.DESIGNATION_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete designation data list
export const handleDeleteDesignation = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.DESIGNATION_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit designation data list
export const handleBulkDesignation = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.DESIGNATION_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// DESIGNATION API

// COMPLAINT TYPE API

// For handle get complaint type data list
export const handleGetComplaintType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.COMPLAINT_TYPE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add complaint type data list
export const handleAddComplaintType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.COMPLAINT_TYPE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit complaint type data list
export const handleEditComplaintType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.COMPLAINT_TYPE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete complaint type data list
export const handleDeleteComplaintType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.COMPLAINT_TYPE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit complaint type data list
export const handleBulkComplaintType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.COMPLAINT_TYPE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// COMPLAINT TYPE API

// GRAMPANCHAYAT TYPE API

// For handle get grampanchayat data list
export const handleGetGrampanchayat = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.GRAMPANCHAYAT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add grampanchayat data list
export const handleAddGrampanchayat = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.GRAMPANCHAYAT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit grampanchayat data list
export const handleEditGrampanchayat = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.GRAMPANCHAYAT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete grampanchayat data list
export const handleDeleteGrampanchayat = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.GRAMPANCHAYAT_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit grampanchayat data list
export const handleBulkGrampanchayat = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.GRAMPANCHAYAT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete state data list
export const handleUploadGrampanchayatImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.GRAMPANCHAYAT_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// GRAMPANCHAYAT TYPE API

// DASHBOARD BANNER API

// For handle get dashboard banner data list
export const handleGetDashboardBanner = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.DASHBOARD_BANNER_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add dashboard banner data list
export const handleAddDashboardBanner = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.DASHBOARD_BANNER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete dashboard banner data list
export const handleDeleteDashboardBanner = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.DASHBOARD_BANNER_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// DASHBOARD BANNER API

// ANNOUNCEMENT API

// For handle get announcement data list
export const handleGetAnnouncement = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.ANNOUNCEMENT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add announcement data list
export const handleAddAnnouncement = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.ANNOUNCEMENT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit announcement data list
export const handleEditAnnouncement = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.ANNOUNCEMENT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete announcement data list
export const handleDeleteAnnouncement = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.ANNOUNCEMENT_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const handleUpdateStatusAnnouncement = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.ANNOUNCEMENT_STATUS_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete state data list
export const handleUploadAnnouncementImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.ANNOUNCEMENT_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// ANNOUNCEMENT API

// COMPLAINT DEPARTMENT API

// For handle get complaints_department data list
export const handleGetComplaintDepartment = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.DEPARTMENT_TYPE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add complaints_department data list
export const handleAddComplaintDepartment = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.DEPARTMENT_TYPE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit complaints_department data list
export const handleEditComplaintDepartment = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.DEPARTMENT_TYPE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete complaints_department data list
export const handleDeleteComplaintDepartment = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.DEPARTMENT_TYPE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit complaints_department data list
export const handleBulkComplaintDepartment = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.DEPARTMENT_TYPE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// COMPLAINT DEPARTMENT API

// SCHEME API

// For handle get scheme data list
export const handleGetScheme = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.SCHEME_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add scheme data list
export const handleAddScheme = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.SCHEME_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit scheme data list
export const handleEditScheme = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.SCHEME_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete scheme data list
export const handleDeleteScheme = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.SCHEME_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit scheme data list
export const handleBulkScheme = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.SCHEME_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete state data list
export const handleUploadSchemeImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.SCHEME_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// SCHEME API

// SCHEME DEPARTMENT API

// For handle get scheme_department data list
export const handleGetSchemeDepartment = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.SCHEME_DEPARTMENT_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add scheme_department data list
export const handleAddSchemeDepartment = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.SCHEME_DEPARTMENT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit scheme_department data list
export const handleEditSchemeDepartment = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.SCHEME_DEPARTMENT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete scheme_department data list
export const handleDeleteSchemeDepartment = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.SCHEME_DEPARTMENT_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit scheme_department data list
export const handleBulkSchemeDepartment = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.SCHEME_DEPARTMENT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// SCHEME DEPARTMENT API

// DEPARTMENT API

// For handle get department data list
export const handleGetDepartment = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.DEPARTMENT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add department data list
export const handleAddDepartment = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.DEPARTMENT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit department data list
export const handleEditDepartment = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.DEPARTMENT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete department data list
export const handleDeleteDepartment = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.DEPARTMENT_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit department data list
export const handleBulkDepartment = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.DEPARTMENT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const handleSearchDepartment = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.DEPARTMENT_SEARCH_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// DEPARTMENT API

// MINISTRY API

// For handle get government ministry data list
export const handleGetGovernmentMinistry = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.GOVERNMENT_MINISTRY_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add government ministry data list
export const handleAddGovernmentMinistry = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.GOVERNMENT_MINISTRY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit government ministry data list
export const handleEditGovernmentMinistry = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.GOVERNMENT_MINISTRY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete government ministry data list
export const handleDeleteGovernmentMinistry = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.GOVERNMENT_MINISTRY_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit government ministry data list
export const handleBulkGovernmentMinistry = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.GOVERNMENT_MINISTRY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// MINISTRY API

// CORPORATION DEPARTMENT API

// For handle get corporation data list
export const handleGetCorporation = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.CORPORATION_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add corporation data list
export const handleAddCorporation = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CORPORATION_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit corporation data list
export const handleEditCorporation = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.CORPORATION_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete corporation data list
export const handleDeleteCorporation = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.CORPORATION_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit corporation data list
export const handleBulkCorporation = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CORPORATION_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// CORPORATION DEPARTMENT API

// POLITICAL PARTY API

// For handle get political party data list
export const handleGetPoliticalParty = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.POLITICAL_PARTY_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add political party data list
export const handleAddPoliticalParty = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.POLITICAL_PARTY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit political party data list
export const handleEditPoliticalParty = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.POLITICAL_PARTY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete political party data list
export const handleDeletePoliticalParty = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.POLITICAL_PARTY_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete state data list
export const handleUploadPoliticalPartyImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.POLITICAL_PARTY_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// POLITICAL PARTY API

// QUOTE API

// For handle get quote data list
export const handleGetQuote = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.QUOTE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add quote data list
export const handleAddQuote = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.QUOTE_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit quote data list
export const handleEditQuote = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.QUOTE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete quote data list
export const handleDeleteQuote = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.QUOTE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// QUOTE API

// COMPLAINT API

// For handle get complaint data list
export const handleGetComplaints = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.COMPLAINT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete complaint data list
export const handleDeleteComplaint = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.COMPLAINT_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// COMPLAINT API

// FEEDBACK API

// For handle get feedback data list
export const handleGetFeedback = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.FEEDBACK_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add feedback data list
export const handleAddFeedback = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.FEEDBACK_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit feedback data list
export const handleEditFeedback = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.FEEDBACK_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete feedback data list
export const handleDeleteFeedback = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.FEEDBACK_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// FEEDBACK API

// REPRESENTATIVE API

export const handleGetRepresentative = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.REPRESENTATIVE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const handleAddRepresentative = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.REPRESENTATIVE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const handleEditRepresentative = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.REPRESENTATIVE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete state data list
export const handleUploadRepresentativeImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.REPRESENTATIVE_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// REPRESENTATIVE MP API
// For handle get feedback data list
export const handleGetRepresentativeMP = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.MP_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};
// REPRESENTATIVE MP API

// REPRESENTATIVE MLA API
// For handle get feedback data list
export const handleGetRepresentativeMLA = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.MLA_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};
// REPRESENTATIVE MLA API

// REPRESENTATIVE CORPORATOR API
// For handle get feedback data list
export const handleGetRepresentativeCoporator = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.CORPORATOR_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};
// REPRESENTATIVE CORPORATOR API

// REPRESENTATIVE GP_MEMBER API
// For handle get feedback data list
export const handleGetRepresentativeGPMember = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.GPMEMBER_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};
// REPRESENTATIVE GP_MEMBER API

// REPRESENTATIVE GP_MEMBER API
// For handle get feedback data list
export const handleGetRepresentativeAspirants = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.ASPIRANTS_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const handleEditRepresentativeAspirants = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.ASPIRANTS_USER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
// REPRESENTATIVE GP_MEMBER API

// For handle delete representative data list
export const handleDeleteRepresentative = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.REPRESENTATIVE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete representative data list

// REPRESENTATIVE API

// COMMON

// For handle get vidhansabha district wise data list
export const handleGetVidhansabhaDistrict = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.VIDHANSABHA_DISTRICT_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
// COMMON

// REGULAR USER API

export const handleGetRegularUser = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.REGULAR_USER_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const handleDeleteRegularUser = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.REGULAR_USER_DELETE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// REGULAR USER API

// LESSON CATEGORY API

// For handle get lesson category data list
export const handleGetLessonCategory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.LESSON_CATEGORY_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add lesson category data list
export const handleAddLessonCategory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.LESSON_CATEGORY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit lesson category data list
export const handleEditLessonCategory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.LESSON_CATEGORY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete lesson category data list
export const handleDeleteLessonCategory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.LESSON_CATEGORY_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit lesson category data list
export const handleBulkLessonCategory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.LESSON_CATEGORY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle upload lesson image
export const handleUploadLessonImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.LESSON_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// LESSON CATEGORY API

// LESSON BANNER API

// For handle get lesson banner data list
export const handleGetLessonBanner = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.LESSON_BANNER_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add lesson banner data list
export const handleAddLessonBanner = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.LESSON_BANNER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete lesson category data list
export const handleDeleteLessonBanner = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.LESSON_BANNER_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// LESSON BANNER API

// LESSON API

// For handle get lesson category data list
export const handleGetLesson = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.LESSON_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add lesson category data list
export const handleAddLesson = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.LESSON_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit lesson category data list
export const handleEditLesson = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.LESSON_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete lesson category data list
export const handleDeleteLesson = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.LESSON_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit lesson category data list
export const handleBulkLesson = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.LESSON_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// LESSON API

// GOVERNMENT JOBS API

// For handle get government jobs data list
export const handleGetGovernmentJobs = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.GOVERNMENT_JOBS_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add government jobs data list
export const handleAddGovernmentJobs = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.GOVERNMENT_JOBS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit government jobs data list
export const handleEditGovernmentJobs = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.GOVERNMENT_JOBS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete government jobs data list
export const handleDeleteGovernmentJobs = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.GOVERNMENT_JOBS_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit government jobs data list
export const handleBulkGovernmentJobs = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.GOVERNMENT_JOBS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// GOVERNMENT JOBS API

// LOKSABHA CONSTITUENCY API

// For handle get loksabha data list
export const handleGetLoksabha = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.LOKSABHA_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add loksabha data list
export const handleAddLoksabha = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.LOKSABHA_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit loksabha data list
export const handleEditLoksabha = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.LOKSABHA_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete loksabha data list
export const handleUploadLoksabhaImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.LOKSABHA_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete loksabha data list
export const handleDeleteLoksabha = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.LOKSABHA_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit loksabha data list
export const handleBulkLoksabha = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.LOKSABHA_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// LOKSABHA CONSTITUENCY API

// VIDHANSABHA CONSTITUENCY API

// For handle get vidhansabha data list
export const handleGetVidhansabha = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.VIDHANSABHA_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add vidhansabha data list
export const handleAddVidhansabha = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.VIDHANSABHA_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit vidhansabha data list
export const handleEditVidhansabha = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.VIDHANSABHA_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete vidhansabha data list
export const handleUploadVidhansabhaImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.VIDHANSABHA_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete vidhansabha data list
export const handleDeleteVidhansabha = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.VIDHANSABHA_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit vidhansabha data list
export const handleBulkVidhansabha = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.VIDHANSABHA_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// VIDHANSABHA CONSTITUENCY API

// CORPORATION WARD API

// For handle get corporation ward data list
export const handleGetCorporationWard = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.CORPORATION_WARD_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add corporation ward data list
export const handleAddCorporationWard = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CORPORATION_WARD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit corporation ward data list
export const handleEditCorporationWard = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.CORPORATION_WARD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete corporation ward data list
export const handleUploadCorporationWard = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.CORPORATION_WARD_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete corporation ward data list
export const handleDeleteCorporationWard = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.CORPORATION_WARD_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit vidhansabha data list
export const handleBulkCorporationWard = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CORPORATION_WARD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// CORPORATION WARD API

// GP WARD API

// For handle get gp_ward data list
export const handleGetGPWard = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.GP_WARD_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add gp_ward data list
export const handleAddGPWard = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.GP_WARD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit gp_ward data list
export const handleEditGPWard = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.GP_WARD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete gp_ward data list
export const handleUploadGPWard = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.GP_WARD_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete gp_ward data list
export const handleDeleteGPWard = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.GP_WARD_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit vidhansabha data list
export const handleBulkGPWard = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.GP_WARD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// GP WARD API

// DASHBOARD API

// For handle get dashboard count data list
export const handleGetCountDetails = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.DASHBOARD_COUNT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get dashboard natinal party data list
export const handleGetNationalParty = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.DASHBOARD_NATIONAL_PARTY_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get dashboard state party data list
export const handleGetStateParty = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.DASHBOARD_STATE_PARTY_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get dashboard recently addedd user data list
export const handleGetRecentUsers = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.DASHBOARD_USERS_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get dashboard recently added aspirants data list
export const handleGetRecentAspirants = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.DASHBOARD_ASPIRANTS_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get dashboard recently lessons data list
export const handleGetRecentLessons = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.DASHBOARD_LESSON_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get dashboard recently complaints data list
export const handleGetRecentComplaints = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.DASHBOARD_COMPLAINTS_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// DASHBOARD API
