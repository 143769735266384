/**
 * Application Name: JanSarkar(Mantine Version 6.0.1)
 * Author: Zevcore Private Limited
 * Starting date: 14.03.2023
 * Developer Name: Anand Krishna
 * Last modified date: 14.03.2023
 * Last modified by: Anand Krishna
 **/

import React from "react";
import { useNavigate } from "react-router-dom"; // For import the react dom for navigation components // For import react dom navigation components
import { Paper, Text } from "@mantine/core"; //For import mantine required functions and theme
import { Home } from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon

const BreadCrumb = (props) => {
  // navigation import for navigate to page
  let navigate = useNavigate();
  const handleClick = (event) => {
    navigate(event);
  };

  return (
    <Paper
      style={{
        display: "flex",
        backgroundColor: "transparent",
        justifyContent: "space-between",
        marginRight: 5,
        marginLeft: 5,
        marginBottom: 0,
        // backgroundColor: "#333333",
      }}
    >
      {/* Props text name Main page name */}
      <Text style={{ paddingTop: 12 }}>
        {props.Text} {props.Name ? <span>( {props.Name} )</span> : null}
      </Text>

      {/* Show right side bread crumb with link */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // backgroundColor: "#333333",
        }}
      >
        <p
          style={{
            cursor: "pointer",
          }}
          onClick={() => handleClick("/")}
        >
          <Home style={{ paddingTop: 0 }} size={12} />
        </p>
        {/* For title page */}
        {props.Title ? (
          <>
            <p style={{ paddingTop: 0 }}> &nbsp; / &nbsp;</p>
            <p
              style={{
                cursor: "pointer",
                fontSize: 12,
                paddingTop: 7,
              }}
              onClick={() => handleClick(props.titleLink)}
            >
              {props.Title}
            </p>
          </>
        ) : null}
        {/* For text page */}
        {props.Text ? (
          <>
            <p style={{ paddingTop: 0 }}> &nbsp; / &nbsp;</p>
            <p
              style={{
                cursor: "pointer",
                fontSize: 12,
                fontFamily: "Poppins",
                paddingTop: 8,
              }}
            >
              {props.Text}
            </p>
          </>
        ) : null}
      </div>
    </Paper>
  );
};

export default BreadCrumb;
