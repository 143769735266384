/**
 * Application Name: JanSarkar(Mantine Version 6.0.1)
 * Author: Zevcore Private Limited
 * Starting date: 14.03.2023
 * Developer Name: Anand Krishna
 * Last modified date: 15.03.2023
 * Last modified by: Anand Krishna
 **/

import {
  ActionIcon,
  Avatar,
  Anchor,
  Button,
  Card,
  Grid,
  Group,
  Loader,
  Menu,
  Modal,
  NativeSelect,
  NumberInput,
  Pagination,
  ScrollArea,
  Skeleton,
  Table,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { Dots, Pencil, Photo, Search, Trash, X } from "tabler-icons-react";
import { CSVLink } from "react-csv";
// Image
import excel from "../../assets/images/excel.png";
import pdf from "../../assets/images/pdf.png";
import BreadCrumb from "../../components/Breadcrumbs/BreadCrumbs";
import {
  dataSlice,
  getActiveColor,
  imageModal,
  selectFilter,
  onDownload,
  pageModal,
} from "../../helpers/common";
import notificationHelper from "../../helpers/Notification"; // Import notification for this page
import { Th, dataSearch, setSorting, print } from "../../helpers/tableFunction"; // For table data functions
import useStyles from "../../components/Styles/style"; // Import the style from
import {
  handleDeleteFeedback,
  handleDeleteRegularUser,
  handleGetComplaints,
  handleGetFeedback,
  handleGetRegularUser,
} from "../../helpers/Apis";
import { modals } from "@mantine/modals";
import { useForm } from "@mantine/form";
import { URL_CONSTANTS } from "../../constants/constants";
// RichtextEditor

function RegularUser() {
  const { classes } = useStyles();
  const [search, setSearch] = useState("");
  const [opened, setOpened] = useState(false);

  const [sortedData, setSortedData] = useState([]);
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const theme = useMantineTheme();
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table

  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: true,
    submitLoading: false,
    data: [],
    addDrawer: false,
    bulkDrawer: false,
    imageDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });

  //   For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        //   For get all the state data list
        const response = await handleGetRegularUser();
        console.log(response.data);
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.reverse(),
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data,
            page: 1,
            total: 10,
          });
          setSortedData(datas);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Form values for adding state values
  const form = useForm({
    initialValues: {
      state: "",
      year: "",
      value: "",
      lastupdate: "",
      statetype: "",
    },
  });

  //For delete confirm modal show Delete
  const openConfirmModalDelete = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the user <b>{datas.username}</b> ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  //   For delete db data from table and db
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteRegularUser(e);
    // Check the response for notification and actions
    console.log(response.data.data);
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "User deleted successfully",
      });
      var filter = variables.data;
      filter = filter.filter((img) => img.value !== e);
      setVariables({
        ...variables,
        data: response.data.reverse(),
        skeletonLoading: false,
      });
      const datas = dataSlice({
        data: response.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Table data arrange by using function and loop throw each data range to table body
  const rows = sortedData.map((row, index) => (
    <tr key={row.username}>
      <td>{variables.data.length - activePage * total + total - index}</td>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.image != "" ? (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.REPRESENTATIVE_IMAGE + row.image,
                  title: row.username,
                });
              }}
              radius={30}
              size={30}
              src={URL_CONSTANTS.REPRESENTATIVE_IMAGE + row.image}
            />
          ) : (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.REPRESENTATIVE_IMAGE + row.image,
                  title: row.username,
                });
              }}
              size={20}
              src={URL_CONSTANTS.REPRESENTATIVE_IMAGE + row.image}
            />
          )}

          <div>
            <Text fz="sm" fw={500}>
              <Anchor component="button" size="sm">
                {row.username}
              </Anchor>
            </Text>
          </div>
        </Group>
      </td>
      <td>{row.phone_number}</td>
      <td>{new Date(row.createdAt).toLocaleDateString()}</td>
      <td>{new Date(row.updatedAt).toLocaleDateString()}</td>
      <td>
        <Group spacing={0} position="left">
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.id)}
            color="red"
          >
            <Trash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  return (
    <div>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb
          Text="Regular User"
          Title="Settings"
          titleLink="/settings"
        />
      </Skeleton>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <ScrollArea>
          <Card className="border">
            <ScrollArea>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {/* For search the table data input forms */}
                <TextInput
                  variant="filled"
                  placeholder="Search"
                  mb="md"
                  size="sm"
                  value={search}
                  icon={<Search size={16} />}
                  onChange={async (e) => {
                    // On change search ofr the data that is enter
                    setSearch(e.currentTarget.value);
                    setPage(1);
                    const datas = await dataSearch({
                      data: variables.data,
                      value: e.currentTarget.value,
                      activePage: activePage,
                      total: total,
                    });
                    setSortedData(datas);
                    setRefreshTable(new Date());
                  }}
                  sx={{ width: 250 }}
                />
                <div>
                  <Group spacing="xs">
                    {/* For export the the table data to pdf and excels */}
                    <Text>Exports :</Text>
                    <CSVLink
                      data={variables.data}
                      headers={[{ label: "Tax Percentage", key: "label" }]}
                      filename="state.csv"
                      className={classes.pdfExcel}
                    >
                      <img
                        src={excel}
                        alt="excel"
                        width="25"
                        style={{ margin: "2px" }}
                      />
                    </CSVLink>
                    <button
                      className={classes.pdfExcel}
                      onClick={() =>
                        print({
                          name: "State",
                          data: variables.data,
                          list: ["label"],
                          header: ["Tax Percentage"],
                        })
                      }
                    >
                      <img
                        src={pdf}
                        alt="pdf"
                        width="19"
                        style={{ margin: "2px" }}
                      />
                    </button>
                  </Group>
                </div>
              </div>
              {/* className={classes.striped} */}
              <Table verticalSpacing="sm">
                <thead>
                  <tr>
                    <th>Sl.No</th>
                    <th>User Name</th>
                    <th>Phone no</th>
                    <th>Added on</th>
                    <th>Updated on</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {rows.length > 0 ? (
                  <tbody>{rows}</tbody>
                ) : (
                  <>
                    {loadingData === false ? (
                      <tbody>
                        <tr>
                          <td colSpan={4}>
                            <div style={{ textAlign: "center" }}>
                              <Loader size="xl" color="orange" variant="dots" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={4}>
                            <div style={{ textAlign: "center" }}>
                              <Text weight={500} align="center">
                                Nothing found
                              </Text>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </>
                )}
              </Table>
            </ScrollArea>
            {/* For display the pagination and no of per pages list */}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: 15,
              }}
            >
              {/* For pagination */}
              <Pagination
                withEdges
                size="sm"
                page={activePage}
                onChange={async (e) => {
                  setPage(Number(e));
                  const datas = await dataSlice({
                    data: variables.data,
                    page: Number(e),
                    total: total,
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                total={Math.ceil(variables.data.length / total)}
                color="orange"
              />
            </div>
          </Card>
        </ScrollArea>
      </Skeleton>
    </div>
  );
}

export default RegularUser;
