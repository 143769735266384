/**
 * Application Name: JanSarkar(Mantine Version 6.0.1)
 * Author: Zevcore Private Limited
 * Starting date: 14.03.2023
 * Developer Name: Anand Krishna
 * Last modified date: 15.03.2023
 * Last modified by: Anand Krishna
 **/

import {
  ActionIcon,
  Avatar,
  Anchor,
  Button,
  Card,
  Grid,
  Group,
  Loader,
  Modal,
  Pagination,
  ScrollArea,
  Skeleton,
  Table,
  Text,
  TextInput,
  useMantineTheme,
  Divider,
  Select,
  Mark,
  Paper,
  FileButton,
  NumberInput,
} from "@mantine/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dots, Pencil, Photo, Search, Trash, X } from "tabler-icons-react";
import { CSVLink } from "react-csv";
// Image
import excel from "../../assets/images/excel.png";
import pdf from "../../assets/images/pdf.png";
import BreadCrumb from "../../components/Breadcrumbs/BreadCrumbs";
import { dataSlice, imageModal } from "../../helpers/common";
import notificationHelper from "../../helpers/Notification"; // Import notification for this page
import { dataSearch, print } from "../../helpers/tableFunction"; // For table data functions
import useStyles from "../../components/Styles/style"; // Import the style from
import {
  handleGetRepresentative,
  handleGetState,
  handleGetDistrict,
  handleGetPoliticalParty,
  handleUploadRepresentativeImage,
  handleEditRepresentativeAspirants,
  handleDeleteRepresentative,
  handleGetDesignation,
  handleGetLoksabha,
  handleGetVidhansabha,
  handleGetCorporation,
  handleGetCorporationWard,
  handleGetGPWard,
  handleEditRepresentative,
  handleAddRepresentative,
} from "../../helpers/Apis";
import { modals } from "@mantine/modals";
import { useForm } from "@mantine/form";
import { URL_CONSTANTS } from "../../constants/constants";
// RichtextEditor
import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import { useDebounceEffect } from "../../common/userDebounceEffect.ts";
// RichtextEditor
// for image crop
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import {
  Dropzone,
  DropzoneStatus,
  MIME_TYPES,
  IMAGE_MIME_TYPE,
} from "@mantine/dropzone";
import "react-image-crop/dist/ReactCrop.css";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 10, // make it 10 for smaller crop ratio
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function Representative() {
  const { classes } = useStyles();
  const [search, setSearch] = useState("");
  const [opened, setOpened] = useState(false);
  const [district, setDistrict] = useState([]);
  const [state, setState] = useState([]);
  const [political, setPolitical] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [loksabha, setLoksabha] = useState([]);
  const [assembly, setAssembly] = useState([]);
  const [corporation, setCorporation] = useState([]);
  const [corporationward, setCorporationward] = useState([]);
  const [gpward, setGpward] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [sortedData2, setSortedData2] = useState([]);
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const theme = useMantineTheme();
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table

  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: true,
    submitLoading: false,
    data: [],
    addDrawer: false,
    bulkDrawer: false,
    imageDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });

  //   For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        //   For get all the state data list
        const response = await handleGetRepresentative();
        console.log(response.data.data);
        // On Response setting the data to variable
        if (response.status === 200) {
          setSortedData2(response.data.data);
          setVariables({
            ...variables,
            data: response.data.data,
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setSortedData(datas);
        }

        const response1 = await handleGetState();
        console.log(response1.data.data);
        if (response1.status === 200) {
          var data = response1.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.name.toString(),
            id: data.id,
            name: data.name,
          }));
          setState(clean);
        }

        const response2 = await handleGetDistrict();
        console.log(response2.data.data);
        if (response2.status === 200) {
          var data = response2.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.name.toString(),
            id: data.id,
            name: data.name,
          }));
          setDistrict(clean);
        }

        const response3 = await handleGetPoliticalParty();
        console.log(response3.data.data);
        if (response3.status === 200) {
          var data = response3.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.name.toString(),
            id: data.id,
            name: data.name,
          }));
          setPolitical(clean);
        }

        const response4 = await handleGetDesignation();
        console.log(response4.data.data);
        if (response4.status === 200) {
          var data = response4.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.name.toString(),
            id: data.id,
            name: data.name,
          }));
          setDesignation(clean);
        }

        const response5 = await handleGetLoksabha();
        console.log(response5.data.data);
        if (response5.status === 200) {
          var data = response5.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.name.toString(),
            id: data.id,
            name: data.name,
          }));
          setLoksabha(clean);
        }

        const response6 = await handleGetVidhansabha();
        console.log(response6.data.data);
        if (response6.status === 200) {
          var data = response6.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.name.toString(),
            id: data.id,
            name: data.name,
          }));
          setAssembly(clean);
        }

        const response7 = await handleGetCorporation();
        console.log(response7.data.data);
        if (response7.status === 200) {
          var data = response7.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.name.toString(),
            id: data.id,
            name: data.name,
          }));
          setCorporation(clean);
        }

        const response8 = await handleGetCorporationWard();
        console.log(response8.data.data);
        if (response8.status === 200) {
          var data = response8.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.name.toString(),
            id: data.id,
            name: data.name,
          }));
          setCorporationward(clean);
        }

        const response9 = await handleGetGPWard();
        console.log(response9.data.data);
        if (response9.status === 200) {
          var data = response9.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.name.toString(),
            id: data.id,
            name: data.name,
          }));
          setGpward(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Form values for adding Representative data
  const form = useForm({
    initialValues: {
      name: "",
      year: "",
      loksabha: "",
      vidhansabha: "",
      corporation: "",
      corporationward: "",
      gpward: "",
      designation: "",
      state: "",
      district: "",
      fought: "",
      won: "",
      lost: 0,
      education: "",
      nationality: "",
      party: "",
      case: "",
      net: "",
      link: "",
      achievement: "",
      accolade: "",
      description: "",
    },
    validate: (values) => ({
      name: values.name.length < 2 ? "Too short name" : null,
    }),
  });

  // Edit Form Values for updating the representative profile,portfolio,login details,career details and election history
  const editform = useForm({
    initialValues: {
      value: "",
      name: "",
      year: "",
      loksabha: "",
      vidhansabha: "",
      corporation: "",
      corporationward: "",
      gpward: "",
      designation: "",
      state: "",
      district: "",
      fought: "",
      won: "",
      lost: 0,
      education: "",
      nationality: "",
      party: "",
      case: "",
      net: "",
      link: "",
      achievement: "",
      accolade: "",
      description: "",
      lastupdate: "",
    },

    validate: (values) => ({
      name: values.name.length < 2 ? "Too short name" : null,
    }),
  });

  // Form values for adding state values
  const formImage = useForm({
    initialValues: {
      value: "",
      image: "",
    },
  });

  const handleAdd = async (e) => {
    setLoadingState(true);
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddRepresentative(e);
    // Check for response data for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Representative added successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setLoadingState(false);
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const handleState = async (e) => {};

  const rows = sortedData.map((row, index) => (
    <tr key={row.name}>
      <td>{activePage * total - total + index + 1}</td>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.image != "" ? (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.REPRESENTATIVE_IMAGE + row.image,
                  title: row.name,
                });
              }}
              radius={30}
              size={30}
              src={URL_CONSTANTS.REPRESENTATIVE_IMAGE + row.image}
            />
          ) : (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.REPRESENTATIVE_IMAGE + row.image,
                  title: row.name,
                });
              }}
              size={20}
              src={URL_CONSTANTS.REPRESENTATIVE_IMAGE + row.image}
            />
          )}

          <div>
            <Text fz="sm" fw={500}>
              <Anchor
                onClick={() => handleEdit(row.id)}
                component="button"
                size="sm"
              >
                {row.name}
              </Anchor>
            </Text>
            <Text c="dimmed" size={10}>
              {row.designation.name}
            </Text>
          </div>
        </Group>
      </td>
      <td>
        <Group spacing="sm">
          <div>
            <Text fz="sm" fw={500}>
              <Anchor
                onClick={() => handleEdit(row.id)}
                component="button"
                size="sm"
              >
                {row.phone_number !== null &&
                row.phone_number !== "" &&
                typeof row.phone_number !== "undefined"
                  ? row.phone_number
                  : "Phone: N/A"}
              </Anchor>
            </Text>
            <Text c="dimmed" fz="xs">
              {row.email !== null &&
              row.email !== "" &&
              typeof row.email !== "undefined"
                ? row.email
                : "Email: N/A"}
            </Text>
          </div>
        </Group>
      </td>
      <td>
        <Group spacing="sm">
          {row.political_party !== null &&
          row.political_party !== "" &&
          typeof row.political_party !== "undefined" ? (
            <Avatar
              onClick={() => {
                imageModal({
                  data:
                    URL_CONSTANTS.POLITICAL_PARTY_IMAGE +
                    row.political_party.symbol,
                  title: row.political_party.name,
                });
              }}
              radius={30}
              size={30}
              src={
                URL_CONSTANTS.POLITICAL_PARTY_IMAGE + row.political_party.symbol
              }
            />
          ) : (
            <Avatar radius={30} size={30} />
          )}

          <div>
            <Text fz="sm" fw={500}>
              <Anchor
                onClick={() => handleEdit(row.id)}
                component="button"
                size="sm"
              >
                {row.political_party !== null &&
                row.political_party !== "" &&
                typeof row.political_party !== "undefined"
                  ? row.political_party.abbreviation
                  : null}
              </Anchor>
            </Text>
          </div>
        </Group>
      </td>
      <td>
        {row.age !== null && row.age !== "" && typeof row.age !== "undefined"
          ? row.age
          : "Age: N/A"}
      </td>
      <td>
        {row.education !== null &&
        row.education !== "" &&
        typeof row.education !== "undefined"
          ? row.education
          : "Education: N/A"}
      </td>
      <td>
        <Group spacing={0} position="left">
          <ActionIcon onClick={() => openUpload(row.id)}>
            <Photo color="orange" size={20} />
          </ActionIcon>
          <ActionIcon onClick={() => handleEdit(row.id)}>
            <Pencil size={20} color="gray" />
          </ActionIcon>
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.id)}
            color="red"
          >
            <Trash size={20} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  const handleEdit = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    editform.setFieldValue("value", datas.id);
    editform.setFieldValue("name", datas.name);
    editform.setFieldValue(
      "year",
      new Date().getFullYear() - Number(datas.age)
    );
    {
      datas.state_id !== null
        ? editform.setFieldValue("state", datas.state_id.toString())
        : editform.setFieldValue("state", null);
    }
    {
      datas.district_id !== null
        ? editform.setFieldValue("district", datas.district_id.toString())
        : editform.setFieldValue("district", null);
    }
    editform.setFieldValue("fought", datas.election_fought);
    editform.setFieldValue("education", datas.education);
    editform.setFieldValue("won", datas.election_won);
    editform.setFieldValue("lost", datas.election_lost);
    editform.setFieldValue("nationality", datas.nationality);
    // editform.setFieldValue("party", datas.political_party.id.toString());
    {
      datas.political_party_id !== null
        ? editform.setFieldValue("party", datas.political_party_id.toString())
        : editform.setFieldValue("party", null);
    }
    editform.setFieldValue("case", datas.criminal_case);
    {
      datas.designation_id !== null
        ? editform.setFieldValue("designation", datas.designation_id.toString())
        : editform.setFieldValue("designation", null);
    }
    {
      datas.loksabha_id !== null
        ? editform.setFieldValue("loksabha", datas.loksabha_id.toString())
        : editform.setFieldValue("loksabha", null);
    }
    {
      datas.vidhansabha_id !== null
        ? editform.setFieldValue("vidhansabha", datas.vidhansabha_id.toString())
        : editform.setFieldValue("vidhansabha", null);
    }
    {
      datas.corporation_id !== null
        ? editform.setFieldValue("corporation", datas.corporation_id.toString())
        : editform.setFieldValue("corporation", null);
    }
    {
      datas.corporation_ward_id !== null
        ? editform.setFieldValue(
            "corporationward",
            datas.corporation_ward_id.toString()
          )
        : editform.setFieldValue("corporationward", null);
    }
    {
      datas.gp_ward_id !== null
        ? editform.setFieldValue("gpward", datas.gp_ward_id.toString())
        : editform.setFieldValue("gpward", null);
    }
    editform.setFieldValue("net", datas.net_worth);
    editform.setFieldValue("link", datas.youtube_link);
    editform.setFieldValue("achievement", datas.achievement_details);
    editform.setFieldValue("description", datas.description);
    editform.setFieldValue("accolade", datas.accolade_detail);
    editor.commands.setContent(datas.achievement_details);
    editor1.commands.setContent(datas.accolade_detail);
    editor2.commands.setContent(datas.description);
    setVariables({ ...variables, deleteIndex: e, openEdit: true });
  };

  // Edit function for updating designation values
  const handleEdits = async (e) => {
    setLoadingState(true);
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditRepresentative(e);
    // Check for response data for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Representative updated successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        openEdit: false,
        data: response.data.data,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setLoadingState(false);
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // For Richtext editor
  const [value, setValue] = useState("");
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [checked, setChecked] = useState(false);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("achievement", html);
      editform.setFieldValue("achievement", html);
      setValue(html);
    },
  });

  const editor1 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html1 = editor.getHTML();
      form.setFieldValue("accolade", html1);
      editform.setFieldValue("accolade", html1);
      setValue1(html1);
    },
  });

  const editor2 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html2 = editor.getHTML();
      form.setFieldValue("description", html2);
      editform.setFieldValue("description", html2);
      setValue2(html2);
    },
  });

  const openConfirmModalDelete = (e) => {
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Please confirm your action",
      children: (
        <Text size="sm">
          This action is so important that you are required to confirm it with a
          modal. Please click one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteRepresentative(e);
    // Check the response for notification and actions

    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Representative deleted successfully",
      });
      var filter = variables.data;
      filter = filter.filter((img) => img.value !== e);
      setVariables({
        ...variables,
        submitLoading: false,
        data: filter,
      });
      const datas = dataSlice({
        data: filter,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const [id_value, setIdValue] = useState();
  const [imageTitle, setImageTitle] = useState();
  const [profileimage, SetProfileimage] = useState("");
  const [loadingState, setLoadingState] = useState(false);

  const openUpload = (e) => {
    setVariables({ ...variables, submitLoading: true });
    var datas = variables.data.find((img) => img.id === e);
    setImageTitle(datas.name);
    SetProfileimage(datas.image);
    setIdValue(e);
    setVariables({ ...variables, deleteIndex: e, imageDrawer: true });
  };

  const openRef = useRef();
  const [image, setImage] = useState("");
  const [upImg, setUpImg] = useState("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(16 / 9);
  const [cropConfig, setCropConfig] = useState({
    unit: "%",
    width: 50,
    aspect: 9 / 16,
  });
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: 24 / 12,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const ref = useRef();
  const ref45 = useRef();
  const changeHandler = (e) => {
    if (e) {
      console.log(e);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e);
      ref.current.value = "";
    }
  };
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        const TO_RADIANS = Math.PI / 180;
        const image = imgRef.current;
        const crop = completedCrop;
        const canvas = previewCanvasRef.current;
        const scale = 1;
        const rotate = 1;

        const ctx = canvas.getContext("2d");

        if (!ctx) {
          throw new Error("No 2d context");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio;
        // const pixelRatio = 1

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = "high";

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        const rotateRads = rotate * TO_RADIANS;
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;

        ctx.save();

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY);
        // 3) Rotate around the origin
        ctx.rotate(rotateRads);
        // 2) Scale the image
        ctx.scale(scale, scale);
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY);
        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight
        );
        ctx.restore();
        const base64Image = canvas.toDataURL("image/jpeg");
        setImage(base64Image);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  // Adding Representative Image values
  const handleUpload = async (e) => {
    setLoadingState(true);
    setVariables({ ...variables, submitLoading: true });
    var req = {
      image: image,
      value: id_value,
    };
    e = { ...e, ...req };
    const response = await handleUploadRepresentativeImage(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Representative Image upload successfully",
      });
      setUpImg("");
      setImage("");
      setLoadingState(false);
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
      setVariables({
        ...variables,
        data: datas,
        submitLoading: false,
        imageDrawer: false,
      });
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false, addDrawer: false });
    }
  };

  return (
    <div>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb
          Text="Representative"
          Title="Settings"
          titleLink="/settings"
        />
      </Skeleton>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card className="border">
          <ScrollArea>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* For search the table data input forms */}
              <div>
                <TextInput
                  variant="filled"
                  placeholder="Search"
                  mb="md"
                  size="sm"
                  mr={10}
                  style={{ float: "left" }}
                  value={search}
                  icon={<Search size={16} />}
                  onChange={async (e) => {
                    // On change search ofr the data that is enter
                    setSearch(e.currentTarget.value);
                    setPage(1);
                    const datas = await dataSearch({
                      data: variables.data,
                      value: e.currentTarget.value,
                      activePage: activePage,
                      total: total,
                    });
                    setSortedData(datas);
                    setRefreshTable(new Date());
                  }}
                  sx={{ width: 250 }}
                />
                <Select
                  placeholder="Select Designation"
                  data={designation}
                  searchable
                  style={{ float: "left" }}
                  clearable
                  onChange={async (e) => {
                    // On change search for the data that is enter
                    setPage(1);
                    var datas = sortedData2;
                    var filter = "";
                    var date2 = "";
                    if (e != "") {
                      datas = datas.filter(function (item) {
                        if (Number(e) == Number(item.designation_id)) {
                          return true;
                        }
                        return false;
                      });
                    } else {
                      return true;
                    }
                    setVariables((variables) => {
                      return {
                        ...variables,
                        data: datas,
                        skeletonLoading: false,
                      };
                    });
                    const datas2 = dataSlice({
                      data: datas,
                      page: 1,
                      total: 10,
                    });
                    setSortedData(datas2);
                    setRefreshTable(new Date());
                  }}
                />
                <Button
                  loading={variables.submitLoading}
                  onClick={() => {
                    setState([]);
                    setSearch("");
                    setVariables((variables) => {
                      return {
                        ...variables,
                        data: sortedData2,
                        skeletonLoading: false,
                      };
                    });
                    const datas2 = dataSlice({
                      data: sortedData2,
                      page: 1,
                      total: 10,
                    });
                    setSortedData(datas2);
                  }}
                  ml={10}
                  style={{ float: "left" }}
                  color="orange"
                >
                  Clear
                </Button>
              </div>
              <div>
                <Group spacing="xs">
                  {/* For export the the table data to pdf and excels */}
                  <Text>Exports :</Text>
                  <CSVLink
                    data={variables.data}
                    headers={[{ label: "Tax Percentage", key: "label" }]}
                    filename="state.csv"
                    className={classes.pdfExcel}
                  >
                    <img
                      src={excel}
                      alt="excel"
                      width="25"
                      style={{ margin: "2px" }}
                    />
                  </CSVLink>
                  <button
                    className={classes.pdfExcel}
                    onClick={() =>
                      print({
                        name: "State",
                        data: variables.data,
                        list: ["label"],
                        header: ["Tax Percentage"],
                      })
                    }
                  >
                    <img
                      src={pdf}
                      alt="pdf"
                      width="19"
                      style={{ margin: "2px" }}
                    />
                  </button>
                  {/* Drawer open for adding new tax data */}
                  <Button
                    variant="outline"
                    color="orange"
                    size="xs"
                    onClick={() =>
                      setVariables({ ...variables, addDrawer: true })
                    }
                  >
                    + Add Representative
                  </Button>
                </Group>
              </div>
            </div>
            <Divider my="sm" />
            {/* className={classes.striped} */}
            <Table verticalSpacing="sm">
              <thead className="">
                <tr>
                  <th>Sl.No</th>
                  <th>Full Name</th>
                  <th>Contact</th>
                  <th>Political Party</th>
                  <th>Age</th>
                  <th>Education</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {rows.length > 0 ? (
                <tbody>{rows}</tbody>
              ) : (
                <>
                  {loadingData === false ? (
                    <tbody>
                      <tr>
                        <td colSpan={4}>
                          <div style={{ textAlign: "center" }}>
                            <Loader size="xl" color="orange" variant="dots" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={4}>
                          <div style={{ textAlign: "center" }}>
                            <Text weight={500} align="center">
                              Nothing found
                            </Text>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </ScrollArea>
          {/* For display the pagination and no of per pages list */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 15,
            }}
          >
            {/* For pagination */}
            <Pagination
              withEdges
              size="sm"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="orange"
            />
          </div>
        </Card>
      </Skeleton>

      {/* Representative Add Modal */}
      <Modal
        opened={variables.addDrawer}
        onClose={() => setVariables({ ...variables, addDrawer: false })}
        title={"Add New Representative"}
        size="70%"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <form onSubmit={form.onSubmit((values) => handleAdd(values))}>
              <Grid mt={10} mb={15}>
                <Grid.Col xs={3}>
                  <TextInput
                    label="Representative Name"
                    required
                    placeholder="Representative Name"
                    {...form.getInputProps("name")}
                  />
                </Grid.Col>
                <Grid.Col xs={3}>
                  <NumberInput
                    label="Birth Year"
                    placeholder="Birth Year"
                    {...form.getInputProps("year")}
                  />
                </Grid.Col>
                <Grid.Col xs={3}>
                  <Select
                    label="Select state"
                    data={state}
                    searchable
                    clearable
                    placeholder="Select state"
                    {...form.getInputProps("state")}
                  />
                </Grid.Col>
                <Grid.Col xs={3}>
                  <Select
                    label="Select district"
                    data={district}
                    searchable
                    onDropdownOpen={handleState}
                    clearable
                    placeholder="Select district"
                    {...form.getInputProps("district")}
                  />
                </Grid.Col>
                <Grid.Col xs={3}>
                  <NumberInput
                    label="Election Fought"
                    placeholder="Election Fought"
                    {...form.getInputProps("fought")}
                  />
                </Grid.Col>
                <Grid.Col xs={3}>
                  <NumberInput
                    label="Election Won"
                    placeholder="Election Won"
                    {...form.getInputProps("won")}
                  />
                </Grid.Col>
                <Grid.Col xs={3}>
                  <NumberInput
                    label="Election Lost"
                    {...form.getInputProps("lost")}
                    placeholder="Election Lost"
                    value={Number(form.values.fought) - Number(form.values.won)}
                  />
                </Grid.Col>
                <Grid.Col xs={3}>
                  <TextInput
                    label="Education"
                    placeholder="Education"
                    {...form.getInputProps("education")}
                  />
                </Grid.Col>
                <Grid.Col xs={3}>
                  <TextInput
                    label="Total Votes Secured"
                    placeholder="Total Votes Secured"
                    {...form.getInputProps("nationality")}
                  />
                </Grid.Col>
                <Grid.Col xs={3}>
                  <Select
                    label="Political party"
                    placeholder="Select Political party"
                    data={political}
                    searchable
                    clearable
                    {...form.getInputProps("party")}
                  />
                </Grid.Col>
                <Grid.Col xs={3}>
                  <TextInput
                    label="Criminal Case"
                    placeholder="Criminal Case"
                    {...form.getInputProps("case")}
                  />
                </Grid.Col>
                <Grid.Col xs={3}>
                  <TextInput
                    label="Net Worth"
                    placeholder="Net Worth"
                    {...form.getInputProps("net")}
                  />
                </Grid.Col>
                <Grid.Col xs={3}>
                  <Select
                    required
                    label="Designation"
                    placeholder="Select Designation"
                    data={designation}
                    searchable
                    clearable
                    {...form.getInputProps("designation")}
                  />
                </Grid.Col>
                {form.values.designation === "1" ? (
                  <Grid.Col xs={3}>
                    <Select
                      label="Select Loksabha Constituency"
                      placeholder="Select Loksabha Constituency"
                      data={loksabha}
                      searchable
                      clearable
                      {...form.getInputProps("loksabha")}
                    />
                  </Grid.Col>
                ) : form.values.designation === "2" ? (
                  <>
                    <Grid.Col xs={3}>
                      <Select
                        label="Select Loksabha Constituency"
                        placeholder="Select Loksabha Constituency"
                        data={loksabha}
                        searchable
                        clearable
                        {...form.getInputProps("loksabha")}
                      />
                    </Grid.Col>
                    <Grid.Col xs={3}>
                      <Select
                        label="Select Assembly Constituency"
                        placeholder="Select Assembly Constituency"
                        data={assembly}
                        searchable
                        clearable
                        {...form.getInputProps("vidhansabha")}
                      />
                    </Grid.Col>
                  </>
                ) : form.values.designation === "3" ? (
                  <>
                    <Grid.Col xs={3}>
                      <Select
                        label="Select Loksabha Constituency"
                        placeholder="Select Loksabha Constituency"
                        data={loksabha}
                        searchable
                        clearable
                        {...form.getInputProps("loksabha")}
                      />
                    </Grid.Col>
                    <Grid.Col xs={3}>
                      <Select
                        label="Select Assembly Constituency"
                        placeholder="Select Assembly Constituency"
                        data={assembly}
                        searchable
                        clearable
                        {...form.getInputProps("vidhansabha")}
                      />
                    </Grid.Col>
                    <Grid.Col xs={3}>
                      <Select
                        label="Select Corporation"
                        placeholder="Select Corporation"
                        data={corporation}
                        searchable
                        clearable
                        {...form.getInputProps("corporation")}
                      />
                    </Grid.Col>
                    <Grid.Col xs={3}>
                      <Select
                        label="Select Corporation ward"
                        placeholder="Select Corporation ward"
                        data={corporationward}
                        searchable
                        clearable
                        {...form.getInputProps("corporationward")}
                      />
                    </Grid.Col>
                  </>
                ) : form.values.designation === "4" ? (
                  <>
                    <Grid.Col xs={3}>
                      <Select
                        label="Select Loksabha Constituency"
                        placeholder="Select Loksabha Constituency"
                        data={loksabha}
                        searchable
                        clearable
                        {...form.getInputProps("loksabha")}
                      />
                    </Grid.Col>
                    <Grid.Col xs={3}>
                      <Select
                        label="Select Assembly Constituency"
                        placeholder="Select Assembly Constituency"
                        data={assembly}
                        searchable
                        clearable
                        {...form.getInputProps("vidhansabha")}
                      />
                    </Grid.Col>
                    <Grid.Col xs={3}>
                      <Select
                        label="Select Corporation ward"
                        placeholder="Select Corporation ward"
                        data={corporationward}
                        searchable
                        clearable
                        {...form.getInputProps("corporationward")}
                      />
                    </Grid.Col>
                    <Grid.Col xs={3}>
                      <Select
                        label="Select Grampanchayat ward"
                        placeholder="Select Grampanchayat ward"
                        data={gpward}
                        searchable
                        clearable
                        {...form.getInputProps("gpward")}
                      />
                    </Grid.Col>
                  </>
                ) : null}

                <Grid.Col xs={12}>
                  <label style={{ fontSize: 12, marginTop: 20 }}>
                    Youtube link ( Add link id only
                    (https://www.youtube.com/watch?v=<Mark>Em0yehSZpAY</Mark>
                    &ab_channel=BassBeats))
                  </label>
                  <TextInput
                    placeholder="Youtube Link"
                    {...form.getInputProps("link")}
                  />
                </Grid.Col>
                <Grid.Col xs={12}>
                  <Text mt={10} size="xs">
                    Achievement
                  </Text>
                  <RichTextEditor editor={editor}>
                    <RichTextEditor.Toolbar sticky stickyOffset={60}>
                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Bold />
                        <RichTextEditor.Italic />
                        <RichTextEditor.Underline />
                        <RichTextEditor.Strikethrough />
                        <RichTextEditor.ClearFormatting />
                        <RichTextEditor.Highlight />
                        <RichTextEditor.Code />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.H1 />
                        <RichTextEditor.H2 />
                        <RichTextEditor.H3 />
                        <RichTextEditor.H4 />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Blockquote />
                        <RichTextEditor.Hr />
                        <RichTextEditor.BulletList />
                        <RichTextEditor.OrderedList />
                        <RichTextEditor.Subscript />
                        <RichTextEditor.Superscript />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Link />
                        <RichTextEditor.Unlink />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.AlignLeft />
                        <RichTextEditor.AlignCenter />
                        <RichTextEditor.AlignJustify />
                        <RichTextEditor.AlignRight />
                      </RichTextEditor.ControlsGroup>
                    </RichTextEditor.Toolbar>

                    <RichTextEditor.Content
                      value={value}
                      mb={20}
                      {...form.getInputProps("achievement")}
                    />
                  </RichTextEditor>
                </Grid.Col>
                <Grid.Col xs={12}>
                  <Text mt={10} size="xs">
                    Accolade
                  </Text>
                  <RichTextEditor editor={editor1}>
                    <RichTextEditor.Toolbar sticky stickyOffset={60}>
                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Bold />
                        <RichTextEditor.Italic />
                        <RichTextEditor.Underline />
                        <RichTextEditor.Strikethrough />
                        <RichTextEditor.ClearFormatting />
                        <RichTextEditor.Highlight />
                        <RichTextEditor.Code />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.H1 />
                        <RichTextEditor.H2 />
                        <RichTextEditor.H3 />
                        <RichTextEditor.H4 />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Blockquote />
                        <RichTextEditor.Hr />
                        <RichTextEditor.BulletList />
                        <RichTextEditor.OrderedList />
                        <RichTextEditor.Subscript />
                        <RichTextEditor.Superscript />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Link />
                        <RichTextEditor.Unlink />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.AlignLeft />
                        <RichTextEditor.AlignCenter />
                        <RichTextEditor.AlignJustify />
                        <RichTextEditor.AlignRight />
                      </RichTextEditor.ControlsGroup>
                    </RichTextEditor.Toolbar>

                    <RichTextEditor.Content
                      value={value1}
                      mb={20}
                      {...form.getInputProps("accolade")}
                    />
                  </RichTextEditor>
                </Grid.Col>
                <Grid.Col xs={12}>
                  <Text mt={10} size="xs">
                    Description
                  </Text>
                  <RichTextEditor editor={editor2}>
                    <RichTextEditor.Toolbar sticky stickyOffset={60}>
                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Bold />
                        <RichTextEditor.Italic />
                        <RichTextEditor.Underline />
                        <RichTextEditor.Strikethrough />
                        <RichTextEditor.ClearFormatting />
                        <RichTextEditor.Highlight />
                        <RichTextEditor.Code />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.H1 />
                        <RichTextEditor.H2 />
                        <RichTextEditor.H3 />
                        <RichTextEditor.H4 />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Blockquote />
                        <RichTextEditor.Hr />
                        <RichTextEditor.BulletList />
                        <RichTextEditor.OrderedList />
                        <RichTextEditor.Subscript />
                        <RichTextEditor.Superscript />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Link />
                        <RichTextEditor.Unlink />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.AlignLeft />
                        <RichTextEditor.AlignCenter />
                        <RichTextEditor.AlignJustify />
                        <RichTextEditor.AlignRight />
                      </RichTextEditor.ControlsGroup>
                    </RichTextEditor.Toolbar>

                    <RichTextEditor.Content
                      value={value2}
                      mb={20}
                      {...form.getInputProps("description")}
                    />
                  </RichTextEditor>
                </Grid.Col>
                <Grid.Col xs={12}>
                  <Button
                    loading={loadingState}
                    fullWidth
                    radius="md"
                    type="submit"
                    size="md"
                    variant="gradient"
                    gradient={{ from: "orange", to: "red" }}
                  >
                    Add Representative
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
      {/* Representative Add Modal */}

      {/* Representative update Modal */}
      <Modal
        opened={variables.openEdit}
        onClose={() => setVariables({ ...variables, openEdit: false })}
        title={"Update Representative Details"}
        size="70%"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div style={{ padding: "10px" }}>
          <form onSubmit={editform.onSubmit((values) => handleEdits(values))}>
            <Grid>
              <Grid.Col xs={3}>
                <TextInput
                  label="Representative Name"
                  placeholder="Representative Name"
                  {...editform.getInputProps("name")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Birth Year"
                  placeholder="Birth Year"
                  {...editform.getInputProps("year")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <Select
                  label="State"
                  data={state}
                  placeholder="State"
                  {...editform.getInputProps("state")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <Select
                  label="District"
                  data={district}
                  placeholder="District"
                  {...editform.getInputProps("district")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Education"
                  placeholder="Education"
                  {...editform.getInputProps("education")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="No. of Elections Fought"
                  placeholder="No. of Elections Fought "
                  {...editform.getInputProps("fought")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Elections Won"
                  placeholder="Elections Won "
                  {...editform.getInputProps("won")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Elections Lost "
                  placeholder="Elections Lost "
                  {...editform.getInputProps("lost")}
                  value={
                    Number(editform.values.fought) - Number(editform.values.won)
                  }
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Total Votes Secured"
                  placeholder="Total Votes Secured"
                  {...editform.getInputProps("nationality")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <Select
                  label="Political party"
                  placeholder="Select Political party"
                  clearable
                  searchable
                  data={political}
                  {...editform.getInputProps("party")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Criminal Case"
                  placeholder="Criminal Case"
                  {...editform.getInputProps("case")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Net Worth"
                  placeholder="Net Worth"
                  {...editform.getInputProps("net")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <Select
                  label="Designation"
                  placeholder="Select Designation"
                  data={designation}
                  searchable
                  clearable
                  {...editform.getInputProps("designation")}
                />
              </Grid.Col>
              {editform.values.designation === "1" ? (
                <Grid.Col xs={3}>
                  <Select
                    label="Select Loksabha Constituency"
                    placeholder="Select Loksabha Constituency"
                    data={loksabha}
                    searchable
                    clearable
                    {...editform.getInputProps("loksabha")}
                  />
                </Grid.Col>
              ) : editform.values.designation === "2" ? (
                <>
                  <Grid.Col xs={3}>
                    <Select
                      label="Select Loksabha Constituency"
                      placeholder="Select Loksabha Constituency"
                      data={loksabha}
                      searchable
                      clearable
                      {...editform.getInputProps("loksabha")}
                    />
                  </Grid.Col>
                  <Grid.Col xs={3}>
                    <Select
                      label="Select Assembly Constituency"
                      placeholder="Select Assembly Constituency"
                      data={assembly}
                      searchable
                      clearable
                      {...editform.getInputProps("vidhansabha")}
                    />
                  </Grid.Col>
                </>
              ) : editform.values.designation === "3" ? (
                <>
                  <Grid.Col xs={3}>
                    <Select
                      label="Select Loksabha Constituency"
                      placeholder="Select Loksabha Constituency"
                      data={loksabha}
                      searchable
                      clearable
                      {...editform.getInputProps("loksabha")}
                    />
                  </Grid.Col>
                  <Grid.Col xs={3}>
                    <Select
                      label="Select Assembly Constituency"
                      placeholder="Select Assembly Constituency"
                      data={assembly}
                      searchable
                      clearable
                      {...editform.getInputProps("vidhansabha")}
                    />
                  </Grid.Col>
                  <Grid.Col xs={3}>
                    <Select
                      label="Select Corporation"
                      placeholder="Select Corporation"
                      data={corporation}
                      searchable
                      clearable
                      {...editform.getInputProps("corporation")}
                    />
                  </Grid.Col>
                  <Grid.Col xs={3}>
                    <Select
                      label="Select Corporation ward"
                      placeholder="Select Corporation ward"
                      data={corporationward}
                      searchable
                      clearable
                      {...editform.getInputProps("corporationward")}
                    />
                  </Grid.Col>
                </>
              ) : editform.values.designation === "4" ? (
                <>
                  <Grid.Col xs={3}>
                    <Select
                      label="Select Loksabha Constituency"
                      placeholder="Select Loksabha Constituency"
                      data={loksabha}
                      searchable
                      clearable
                      {...editform.getInputProps("loksabha")}
                    />
                  </Grid.Col>
                  <Grid.Col xs={3}>
                    <Select
                      label="Select Assembly Constituency"
                      placeholder="Select Assembly Constituency"
                      data={assembly}
                      searchable
                      clearable
                      {...editform.getInputProps("vidhansabha")}
                    />
                  </Grid.Col>
                  <Grid.Col xs={3}>
                    <Select
                      label="Select Corporation ward"
                      placeholder="Select Corporation ward"
                      data={corporationward}
                      searchable
                      clearable
                      {...editform.getInputProps("corporationward")}
                    />
                  </Grid.Col>
                  <Grid.Col xs={3}>
                    <Select
                      label="Select Grampanchayat ward"
                      placeholder="Select Grampanchayat ward"
                      data={gpward}
                      searchable
                      clearable
                      {...editform.getInputProps("gpward")}
                    />
                  </Grid.Col>
                </>
              ) : null}
              <Grid.Col xs={12}>
                <label style={{ fontSize: 12 }}>
                  Youtube link ( Add link id only
                  (https://www.youtube.com/watch?v=<Mark>Em0yehSZpAY</Mark>
                  &ab_channel=BassBeats))
                </label>
                <TextInput
                  placeholder="Youtube Link"
                  {...editform.getInputProps("link")}
                />
              </Grid.Col>
              <Grid.Col xs={12}>
                <Text size="xs" aria-required>
                  Achievement
                </Text>
                <RichTextEditor editor={editor}>
                  <RichTextEditor.Toolbar sticky stickyOffset={60}>
                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Bold />
                      <RichTextEditor.Italic />
                      <RichTextEditor.Underline />
                      <RichTextEditor.Strikethrough />
                      <RichTextEditor.ClearFormatting />
                      <RichTextEditor.Highlight />
                      <RichTextEditor.Code />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.H1 />
                      <RichTextEditor.H2 />
                      <RichTextEditor.H3 />
                      <RichTextEditor.H4 />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Blockquote />
                      <RichTextEditor.Hr />
                      <RichTextEditor.BulletList />
                      <RichTextEditor.OrderedList />
                      <RichTextEditor.Subscript />
                      <RichTextEditor.Superscript />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Link />
                      <RichTextEditor.Unlink />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.AlignLeft />
                      <RichTextEditor.AlignCenter />
                      <RichTextEditor.AlignJustify />
                      <RichTextEditor.AlignRight />
                    </RichTextEditor.ControlsGroup>
                  </RichTextEditor.Toolbar>

                  <RichTextEditor.Content
                    value={value}
                    mb={20}
                    {...editform.getInputProps("achievement")}
                  />
                </RichTextEditor>
              </Grid.Col>
              <Grid.Col xs={12}>
                <Text size="xs" aria-required>
                  Accolade
                </Text>
                <RichTextEditor editor={editor1}>
                  <RichTextEditor.Toolbar sticky stickyOffset={60}>
                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Bold />
                      <RichTextEditor.Italic />
                      <RichTextEditor.Underline />
                      <RichTextEditor.Strikethrough />
                      <RichTextEditor.ClearFormatting />
                      <RichTextEditor.Highlight />
                      <RichTextEditor.Code />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.H1 />
                      <RichTextEditor.H2 />
                      <RichTextEditor.H3 />
                      <RichTextEditor.H4 />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Blockquote />
                      <RichTextEditor.Hr />
                      <RichTextEditor.BulletList />
                      <RichTextEditor.OrderedList />
                      <RichTextEditor.Subscript />
                      <RichTextEditor.Superscript />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Link />
                      <RichTextEditor.Unlink />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.AlignLeft />
                      <RichTextEditor.AlignCenter />
                      <RichTextEditor.AlignJustify />
                      <RichTextEditor.AlignRight />
                    </RichTextEditor.ControlsGroup>
                  </RichTextEditor.Toolbar>

                  <RichTextEditor.Content
                    value={value1}
                    mb={20}
                    {...editform.getInputProps("accolade")}
                  />
                </RichTextEditor>
              </Grid.Col>
              <Grid.Col xs={12}>
                <Text size="xs" aria-required>
                  Description
                </Text>
                <RichTextEditor editor={editor2}>
                  <RichTextEditor.Toolbar sticky stickyOffset={60}>
                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Bold />
                      <RichTextEditor.Italic />
                      <RichTextEditor.Underline />
                      <RichTextEditor.Strikethrough />
                      <RichTextEditor.ClearFormatting />
                      <RichTextEditor.Highlight />
                      <RichTextEditor.Code />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.H1 />
                      <RichTextEditor.H2 />
                      <RichTextEditor.H3 />
                      <RichTextEditor.H4 />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Blockquote />
                      <RichTextEditor.Hr />
                      <RichTextEditor.BulletList />
                      <RichTextEditor.OrderedList />
                      <RichTextEditor.Subscript />
                      <RichTextEditor.Superscript />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Link />
                      <RichTextEditor.Unlink />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.AlignLeft />
                      <RichTextEditor.AlignCenter />
                      <RichTextEditor.AlignJustify />
                      <RichTextEditor.AlignRight />
                    </RichTextEditor.ControlsGroup>
                  </RichTextEditor.Toolbar>

                  <RichTextEditor.Content
                    value={value2}
                    mb={20}
                    {...editform.getInputProps("description")}
                  />
                </RichTextEditor>
              </Grid.Col>
              <Grid.Col xs={12}>
                <Button
                  loading={loadingState}
                  fullWidth
                  radius="md"
                  type="submit"
                  size="md"
                  variant="gradient"
                  gradient={{ from: "orange", to: "red" }}
                >
                  Update Representative
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </Modal>
      {/* Representative update Modal */}

      {/* Representative Image Modal */}
      <Modal
        opened={variables.imageDrawer}
        onClose={() => setVariables({ ...variables, imageDrawer: false })}
        size="lg"
        title={imageTitle}
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <form onSubmit={formImage.onSubmit((values) => handleUpload(values))}>
          <Grid style={{ padding: "10px" }}>
            <Grid.Col md={12} lg={12} mt={1}>
              {/* For image cropper */}
              {/* For cropper */}
              {upImg !== "" && upImg !== null ? (
                <>
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={upImg}
                      style={{
                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                      }}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                  <div>
                    {previewCanvasRef != null ? (
                      <canvas
                        ref={previewCanvasRef}
                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                        style={{
                          width: Math.round(completedCrop?.width ?? 0),
                          height: Math.round(completedCrop?.height ?? 0),
                          marginBottom: 50,
                          display: "none",
                        }}
                      />
                    ) : null}

                    <Group position="right" mt="md" mb={20}>
                      <Button
                        type="submit"
                        color="zevcore"
                        onClick={() => {
                          setUpImg("");
                          setImage("");
                        }}
                      >
                        Clear Image
                      </Button>
                    </Group>
                  </div>
                </>
              ) : (
                // For selecting cropping image dropdown
                <div
                  style={{
                    marginTop: 15,
                    position: "relative",
                    marginBottom: 30,
                  }}
                >
                  <Paper
                    p="lg"
                    sx={(theme) => ({
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[8]
                          : theme.white,
                    })}
                  >
                    <Avatar
                      src={URL_CONSTANTS.REPRESENTATIVE_IMAGE + profileimage}
                      size={150}
                      radius={100}
                      withBorder
                      mx="auto"
                    />
                    <Text ta="center" fz="lg" weight={500} mt="md">
                      {imageTitle}
                    </Text>

                    <Group position="center">
                      <FileButton
                        resetRef={ref}
                        onChange={changeHandler}
                        accept={[
                          "image/png",
                          "image/jpeg",
                          "image/sgv+xml",
                          "image/gif",
                        ]}
                      >
                        {(props) => (
                          <Button mt={20} {...props}>
                            Select Image
                          </Button>
                        )}
                      </FileButton>
                    </Group>
                  </Paper>
                </div>
              )}
            </Grid.Col>
            <Button
              loading={loadingState}
              fullWidth
              type="submit"
              size="md"
              variant="gradient"
              gradient={{ from: "orange", to: "red" }}
            >
              Upload Representative Image
            </Button>
          </Grid>
        </form>
      </Modal>
      {/* Representative Image Modal */}
    </div>
  );
}

export default Representative;
