/**
 * Application Name: JanSarkar(Mantine Version 6.0.1)
 * Author: Zevcore Private Limited
 * Starting date: 14.03.2023
 * Developer Name: Anand Krishna
 * Last modified date: 17.03.2023
 * Last modified by: Anand Krishna
 **/

import {
  ActionIcon,
  Avatar,
  Anchor,
  Button,
  Card,
  Grid,
  Group,
  Loader,
  Menu,
  Modal,
  NativeSelect,
  NumberInput,
  Pagination,
  ScrollArea,
  Skeleton,
  Table,
  Text,
  TextInput,
  useMantineTheme,
  Select,
  Input,
  Paper,
  FileButton,
  Tabs,
} from "@mantine/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { Dots, Pencil, Photo, Search, Trash, X } from "tabler-icons-react";
import { CSVLink } from "react-csv";
// Image
import excel from "../../assets/images/excel.png";
import pdf from "../../assets/images/pdf.png";
import BreadCrumb from "../../components/Breadcrumbs/BreadCrumbs";
import { useDebounceEffect } from "../../common/userDebounceEffect.ts";
import {
  dataSlice,
  getActiveColor,
  imageModal,
  selectFilter,
  onDownload,
  pageModal,
} from "../../helpers/common";
import notificationHelper from "../../helpers/Notification"; // Import notification for this page
import { Th, dataSearch, setSorting, print } from "../../helpers/tableFunction"; // For table data functions
import useStyles from "../../components/Styles/style"; // Import the style from
import {
  handleAddPoliticalParty,
  handleDeletePoliticalParty,
  handleEditPoliticalParty,
  handleGetPoliticalParty,
  handleGetState,
  handleUploadPoliticalPartyImage,
} from "../../helpers/Apis";
import { modals } from "@mantine/modals";
import { useForm } from "@mantine/form";
import { URL_CONSTANTS } from "../../constants/constants";
import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
// for image crop
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import {
  Dropzone,
  DropzoneStatus,
  MIME_TYPES,
  IMAGE_MIME_TYPE,
} from "@mantine/dropzone";
import "react-image-crop/dist/ReactCrop.css";
import { useNavigate } from "react-router-dom";
// RichtextEditor

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 10, // make it 10 for smaller crop ratio
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function StateParty() {
  const { classes } = useStyles();
  const [search, setSearch] = useState("");
  const [opened, setOpened] = useState(false);
  const [state, setState] = useState([]);
  const [state1, setState1] = useState([]);
  const [district, setDistrict] = useState([]);
  const [district1, setDistrict1] = useState([]);
  const [districtselect, Setdistrictselect] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const theme = useMantineTheme();
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table
  let navigate = useNavigate();
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: true,
    submitLoading: false,
    data: [],
    addDrawer: false,
    bulkDrawer: false,
    imageDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });

  //   For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        //   For get all the state data list
        const response = await handleGetPoliticalParty();
        console.log(response.data.data);
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data,
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setSortedData(datas);
        }

        const response2 = await handleGetState();
        console.log(response2.data);
        if (response2.status === 200) {
          var data = response2.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.name.toString(),
            id: data.id,
            name: data.name,
          }));
          setState(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Form values for adding Political Party
  const form = useForm({
    initialValues: {
      partyname: "",
      abbreviation: "",
      type: "",
      eci: "",
      year: "",
      name: "",
      origin: "",
      political_position: "",
      student: "",
      youth: "",
      women: "",
      slogan: "",
      ls: "",
      rs: "",
      mla: "",
      mp: "",
      mlc: "",
      totalvote: "",
      headq: "",
      address1: "",
      address2: "",
      state: "",
      district: "",
      ideology: "",
      description: "",
    },

    // validate: (values) => ({
    //   year: values.year.length < 0 ? "Too short name" : null,
    // }),
  });

  // Edit form values for updating Political Party
  const editform = useForm({
    initialValues: {
      partyname: "",
      abbreviation: "",
      type: "",
      eci: "",
      year: "",
      name: "",
      origin: "",
      political_position: "",
      student: "",
      youth: "",
      women: "",
      slogan: "",
      ls: "",
      rs: "",
      mla: "",
      mp: "",
      mlc: "",
      totalvote: "",
      headq: "",
      address1: "",
      address2: "",
      state: "",
      district: "",
      ideology: "",
      description: "",
      lastupdate: "",
    },

    // validate: (values) => ({
    //   year: values.year.length < 0 ? "Too short name" : null,
    // }),
  });

  // Adding State values
  const handelAdd = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddPoliticalParty(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Political Party added successfully",
      });
      form.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  //For delete confirm modal show Delete
  const openConfirmModal = (e) => {
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Please confirm your action",
      children: (
        <Text size="sm">
          This action is so important that you are required to confirm it with a
          modal. Please click one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  //   For delete db data from table and db
  const handleConfirmDelete = async (e) => {
    const response = await handleDeletePoliticalParty(e);
    // Check the response for notification and actions

    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Political Party deleted successfully",
      });
      var filter = variables.data;
      filter = filter.filter((img) => img.value !== e);
      setVariables({
        ...variables,
        submitLoading: false,
        data: filter,
      });
      const datas = dataSlice({
        data: filter,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("ideology", html);
      editform.setFieldValue("ideology", html);
      setValue(html);
    },
  });

  const editor1 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html1 = editor.getHTML();
      form.setFieldValue("description", html1);
      editform.setFieldValue("description", html1);
      setValue1(html1);
    },
  });

  // Form values for adding state values
  const formImage = useForm({
    initialValues: {
      value: "",
      image: "",
    },
  });

  // For Richtext editor
  const [value, setValue] = useState("");
  const [value1, setValue1] = useState("");

  // For editing and settings the value of state
  const handleEdit = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    editform.setFieldValue("abbreviation", datas.abbreviation);
    editform.setFieldValue("address1", datas.address1);
    editform.setFieldValue("address2", datas.address2);
    editform.setFieldValue("headq", datas.headquarter);
    editform.setFieldValue("eci", datas.eci_status);
    editform.setFieldValue("year", datas.founded_year);
    editform.setFieldValue("name", datas.founder_name);
    editform.setFieldValue("ideology", datas.ideology);
    editor.commands.setContent(datas.ideology);
    editor1.commands.setContent(datas.description);
    editform.setFieldValue("description", datas.description);
    editform.setFieldValue("power", datas.in_power);
    editform.setFieldValue("ls", Number(datas.ls_seat));
    editform.setFieldValue("partyname", datas.name);
    editform.setFieldValue("origin", datas.origin);
    editform.setFieldValue("political_position", datas.political_position);
    editform.setFieldValue("rs", Number(datas.rs_seat));
    editform.setFieldValue("slogan", datas.slogan);
    editform.setFieldValue("mla", Number(datas.total_mla));
    editform.setFieldValue("mp", Number(datas.total_mp));
    editform.setFieldValue("mlc", Number(datas.total_mlc));
    editform.setFieldValue("totalvote", datas.total_vote);
    editform.setFieldValue("type", datas.type);
    editform.setFieldValue("web", datas.web_url);
    editform.setFieldValue("women", datas.women_wing);
    editform.setFieldValue("youth", datas.youth_wing);
    editform.setFieldValue("student", datas.student_wing);
    editform.setFieldValue(
      "state",
      datas.state != "" && datas.state != null ? datas.state.id.toString() : ""
    );
    {
      datas.district !== null && datas.district !== ""
        ? editform.setFieldValue("district", datas.district.id.toString())
        : editform.setFieldValue("district", null);
    }
    editform.setFieldValue(
      "lastupdate",
      new Date(datas.updatedAt).toLocaleDateString("en-GB")
    );
    editor.commands.setContent(datas.ideology);
    editform.setFieldValue("value", datas.id);
    setVariables({ ...variables, deleteIndex: e, openEdit: true });
  };

  // Edit function for updating state values
  const handleEdits = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditPoliticalParty(e);
    // Check for response data for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Political Party updated successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        openEdit: false,
        data: response.data.data,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false, openEdit: false });
    }
  };

  // Crop Image
  const openRef = useRef();
  const [image, setImage] = useState("");
  const [image1, setImage1] = useState("");
  const [upImg, setUpImg] = useState("");
  const [upImg1, setUpImg1] = useState("");
  const [scale, setScale] = useState(1);
  const [scale1, setScale1] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [rotate1, setRotate1] = useState(0);
  const [aspect, setAspect] = useState(1 / 1);
  const [aspect1, setAspect1] = useState(1 / 1);
  const [cropConfig, setCropConfig] = useState({
    unit: "%",
    width: 50,
    aspect: 9 / 16,
  });
  const [cropConfig1, setCropConfig1] = useState({
    unit: "%",
    width: 50,
    aspect: 9 / 16,
  });
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: 24 / 12,
  });
  const [crop1, setCrop1] = useState({
    unit: "%",
    width: 50,
    aspect: 24 / 12,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [completedCrop1, setCompletedCrop1] = useState(null);
  const previewCanvasRef = useRef(null);
  const previewCanvasRef1 = useRef(null);
  const imgRef = useRef(null);
  const imgRef1 = useRef(null);
  const ref = useRef();
  const ref1 = useRef();
  const ref45 = useRef();
  const changeHandler = (e) => {
    if (e) {
      console.log(e);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e);
      ref.current.value = "";
    }
  };
  const changeHandler1 = (e) => {
    if (e) {
      console.log(e);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg1(reader.result));
      reader.readAsDataURL(e);
      ref1.current.value = "";
    }
  };
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function onImageLoad1(e) {
    if (aspect1) {
      const { width1, height1 } = e.currentTarget;
      setCrop1(centerAspectCrop(width1, height1, aspect1));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        const TO_RADIANS = Math.PI / 180;
        const image = imgRef.current;
        const crop = completedCrop;
        const canvas = previewCanvasRef.current;
        const scale = 1;
        const rotate = 1;

        const ctx = canvas.getContext("2d");

        if (!ctx) {
          throw new Error("No 2d context");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio;
        // const pixelRatio = 1

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = "high";

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        const rotateRads = rotate * TO_RADIANS;
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;

        ctx.save();

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY);
        // 3) Rotate around the origin
        ctx.rotate(rotateRads);
        // 2) Scale the image
        ctx.scale(scale, scale);
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY);
        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight
        );
        ctx.restore();
        const base64Image = canvas.toDataURL("image/jpeg");
        setImage(base64Image);
      }
      if (
        completedCrop1?.width &&
        completedCrop1?.height &&
        imgRef1.current &&
        previewCanvasRef1.current
      ) {
        const TO_RADIANS1 = Math.PI / 180;
        const image1 = imgRef1.current;
        const crop1 = completedCrop1;
        const canvas1 = previewCanvasRef1.current;
        const scale1 = 1;
        const rotate1 = 1;

        const ctx1 = canvas1.getContext("2d");

        if (!ctx1) {
          throw new Error("No 2d context");
        }

        const scaleX1 = image1.naturalWidth / image1.width;
        const scaleY1 = image1.naturalHeight / image1.height;
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio1 = window.devicePixelRatio;
        // const pixelRatio = 1

        canvas1.width = Math.floor(crop1.width * scaleX1 * pixelRatio1);
        canvas1.height = Math.floor(crop1.height * scaleY1 * pixelRatio1);

        ctx1.scale(pixelRatio1, pixelRatio1);
        ctx1.imageSmoothingQuality = "high";

        const cropX1 = crop1.x * scaleX1;
        const cropY1 = crop1.y * scaleY1;

        const rotateRads1 = rotate1 * TO_RADIANS1;
        const centerX1 = image1.naturalWidth / 2;
        const centerY1 = image1.naturalHeight / 2;

        ctx1.save();

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx1.translate(-cropX1, -cropY1);
        // 4) Move the origin to the center of the original position
        ctx1.translate(centerX1, centerY1);
        // 3) Rotate around the origin
        ctx1.rotate(rotateRads1);
        // 2) Scale the image
        ctx1.scale(scale1, scale1);
        // 1) Move the center of the image to the origin (0,0)
        ctx1.translate(-centerX1, -centerY1);
        ctx1.drawImage(
          image1,
          0,
          0,
          image1.naturalWidth,
          image1.naturalHeight,
          0,
          0,
          image1.naturalWidth,
          image1.naturalHeight
        );
        ctx1.restore();
        const base64Image1 = canvas1.toDataURL("image/jpeg");
        setImage1(base64Image1);
      }
    },
    100,
    [completedCrop1, scale1, rotate1]
  );

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const [id_value, setIdValue] = useState();
  const [imageTitle, setImageTitle] = useState();
  const [profileimage, SetProfileimage] = useState("");
  const [symbolimage, SetSymbolimage] = useState("");
  const [loadingState, setLoadingState] = useState(false);

  const openUpload = (e) => {
    setVariables({ ...variables, submitLoading: true });
    var datas = variables.data.find((img) => img.id === e);
    setImageTitle(datas.name);
    SetProfileimage(datas.image);
    SetSymbolimage(datas.symbol);
    setIdValue(e);
    setVariables({ ...variables, deleteIndex: e, imageDrawer: true });
  };

  // Adding State values
  const handleUpload = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    var req = {
      image: image,
      symbol: image1,
      value: id_value,
    };
    e = { ...e, ...req };
    const response = await handleUploadPoliticalPartyImage(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "State Image upload successfully",
      });
      setUpImg("");
      setImage("");
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
      setVariables({
        ...variables,
        data: datas,
        submitLoading: false,
        imageDrawer: false,
      });
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false, addDrawer: false });
    }
  };

  // Table data arrange by using function and loop throw each data range to table body
  const rows = sortedData.map((row, index) => (
    <tr key={row.name}>
      <>
        {row.type === "State" &&
        row.type !== "" &&
        row.type === null &&
        typeof row.type === "undefined" ? (
          <>
            <td>{activePage * total - total + index + 1}</td>
            <td style={{ cursor: "pointer" }}>
              <Group spacing="sm">
                {row.image != "" ? (
                  <Avatar
                    onClick={() => {
                      imageModal({
                        data: URL_CONSTANTS.POLITICAL_PARTY_IMAGE + row.logo,
                        title: row.name,
                      });
                    }}
                    radius={30}
                    size={30}
                    src={URL_CONSTANTS.POLITICAL_PARTY_IMAGE + row.logo}
                  />
                ) : (
                  <Avatar
                    onClick={() => {
                      imageModal({
                        data: URL_CONSTANTS.POLITICAL_PARTY_IMAGE + row.logo,
                        title: row.name,
                      });
                    }}
                    size={20}
                    src={URL_CONSTANTS.POLITICAL_PARTY_IMAGE + row.logo}
                  />
                )}
                <div>
                  <Text fz="sm" fw={500}>
                    <Anchor
                      onClick={() => handleEdit(row.id)}
                      component="button"
                      size="sm"
                    >
                      {row.name}
                    </Anchor>
                  </Text>
                </div>
              </Group>
            </td>
            <td style={{ cursor: "pointer" }}>
              <Group spacing="sm">
                {row.image != "" ? (
                  <Avatar
                    onClick={() => {
                      imageModal({
                        data: URL_CONSTANTS.POLITICAL_PARTY_IMAGE + row.symbol,
                        title: row.name,
                      });
                    }}
                    radius={30}
                    size={30}
                    src={URL_CONSTANTS.POLITICAL_PARTY_IMAGE + row.symbol}
                  />
                ) : (
                  <Avatar
                    onClick={() => {
                      imageModal({
                        data: URL_CONSTANTS.POLITICAL_PARTY_IMAGE + row.symbol,
                        title: row.name,
                      });
                    }}
                    size={20}
                    src={URL_CONSTANTS.POLITICAL_PARTY_IMAGE + row.symbol}
                  />
                )}
              </Group>
            </td>
            <td>{row.total_mp !== null ? row.total_mp : "N/A"}</td>
            <td>{row.total_mla !== null ? row.total_mla : "N/A"}</td>
            <td>{row.rs_seat !== null ? row.rs_seat : "N/A"}</td>
            <td>{row.ls_seat !== null ? row.ls_seat : "N/A"}</td>
            <td>
              <Group spacing={0} position="left">
                <ActionIcon onClick={() => openUpload(row.id)}>
                  <Photo color="orange" size={18} />
                </ActionIcon>
                <ActionIcon onClick={() => handleEdit(row.id)}>
                  <Pencil size={18} color="gray" />
                </ActionIcon>
                <ActionIcon
                  onClick={() => openConfirmModal(row.id)}
                  color="red"
                >
                  <Trash size={18} />
                </ActionIcon>
              </Group>
            </td>
          </>
        ) : null}
      </>
    </tr>
  ));

  return (
    <div>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb
          Text="Political Party"
          Title="Settings"
          titleLink="/settings"
        />
      </Skeleton>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card className="border">
          <ScrollArea>
            <Tabs defaultValue="state">
              <Tabs.List>
                <Tabs.Tab
                  onClick={() => navigate("/party")}
                  color="orange"
                  value="national"
                >
                  National Parties
                </Tabs.Tab>
                <Tabs.Tab
                  onClick={() => navigate("/state_party")}
                  color="orange"
                  value="state"
                >
                  State Parties
                </Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="state" pt="xs">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* For search the table data input forms */}
                  <TextInput
                    variant="filled"
                    placeholder="Search"
                    mb="md"
                    size="sm"
                    value={search}
                    icon={<Search size={16} />}
                    onChange={async (e) => {
                      // On change search ofr the data that is enter
                      setSearch(e.currentTarget.value);
                      setPage(1);
                      const datas = await dataSearch({
                        data: variables.data,
                        value: e.currentTarget.value,
                        activePage: activePage,
                        total: total,
                      });
                      setSortedData(datas);
                      setRefreshTable(new Date());
                    }}
                    sx={{ width: 250 }}
                  />
                  <div>
                    <Group spacing="xs">
                      {/* For export the the table data to pdf and excels */}
                      <Text>Exports :</Text>
                      <CSVLink
                        data={variables.data}
                        headers={[{ label: "Tax Percentage", key: "label" }]}
                        filename="state.csv"
                        className={classes.pdfExcel}
                      >
                        <img
                          src={excel}
                          alt="excel"
                          width="25"
                          style={{ margin: "2px" }}
                        />
                      </CSVLink>
                      <button
                        className={classes.pdfExcel}
                        onClick={() =>
                          print({
                            name: "State",
                            data: variables.data,
                            list: ["label"],
                            header: ["Tax Percentage"],
                          })
                        }
                      >
                        <img
                          src={pdf}
                          alt="pdf"
                          width="19"
                          style={{ margin: "2px" }}
                        />
                      </button>
                      {/* Drawer open for adding new tax data */}
                      <Button
                        variant="outline"
                        color="orange"
                        size="xs"
                        onClick={() =>
                          setVariables({ ...variables, addDrawer: true })
                        }
                      >
                        + Add Political Party
                      </Button>
                    </Group>
                  </div>
                </div>
                {/* className={classes.striped} */}
                <Table verticalSpacing="sm">
                  <thead>
                    <tr>
                      <th>Sl.No</th>
                      <th>Party Name</th>
                      <th>Symbol</th>
                      <th>Total MP</th>
                      <th>Total MLA</th>
                      <th>RS Seats</th>
                      <th>LS Seats</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  {rows.length > 0 ? (
                    <tbody>{rows}</tbody>
                  ) : (
                    <>
                      {loadingData === false ? (
                        <tbody>
                          <tr>
                            <td colSpan={4}>
                              <div style={{ textAlign: "center" }}>
                                <Loader
                                  size="xl"
                                  color="orange"
                                  variant="dots"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={4}>
                              <div style={{ textAlign: "center" }}>
                                <Text weight={500} align="center">
                                  Nothing found
                                </Text>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </>
                  )}
                </Table>
              </Tabs.Panel>
            </Tabs>
          </ScrollArea>
          {/* For display the pagination and no of per pages list */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 15,
            }}
          >
            {/* For pagination */}
            <Pagination
              withEdges
              size="sm"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="orange"
            />
          </div>
        </Card>
      </Skeleton>
      {/* State Add Modal */}
      <Modal
        opened={variables.addDrawer}
        onClose={() => setVariables({ ...variables, addDrawer: false })}
        size="70%"
        title={"Add New Political Party"}
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div style={{ padding: "10px" }}>
          <form onSubmit={form.onSubmit((values) => handelAdd(values))}>
            <Grid>
              <Grid.Col xs={3}>
                <TextInput
                  label="Party Name"
                  required
                  placeholder="Party name"
                  {...form.getInputProps("partyname")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Abbreviation"
                  placeholder="Abbreviation"
                  {...form.getInputProps("abbreviation")}
                />
              </Grid.Col>

              <Grid.Col xs={3}>
                <Select
                  searchable
                  label="Select Type"
                  placeholder="Select Type"
                  data={[
                    { value: "National", label: "National" },
                    { value: "State", label: "State" },
                  ]}
                  {...form.getInputProps("type")}
                />
              </Grid.Col>
              {form.values.type !== "National" ? (
                <>
                  <Grid.Col xs={3}>
                    <Select
                      label="State"
                      searchable
                      clearable
                      required
                      placeholder="Select State"
                      data={state}
                      {...form.getInputProps("state")}
                    />
                  </Grid.Col>
                </>
              ) : null}

              <Grid.Col xs={3}>
                <TextInput
                  label="Eci Status"
                  placeholder="Eci Status"
                  {...form.getInputProps("eci")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <NumberInput
                  label="Founded Year"
                  placeholder="Founded Year"
                  {...form.getInputProps("year")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Founder Name"
                  placeholder="Founder Name"
                  {...form.getInputProps("name")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Origin"
                  placeholder="Origin"
                  {...form.getInputProps("origin")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Political Position"
                  placeholder="Political Position"
                  {...form.getInputProps("political_position")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Student Wing"
                  placeholder="Student Wing"
                  {...form.getInputProps("student")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Youth Wing"
                  placeholder="Youth Wing"
                  {...form.getInputProps("youth")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Women Wing"
                  placeholder="Women Wing"
                  {...form.getInputProps("women")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Slogan"
                  placeholder="Slogan"
                  {...form.getInputProps("slogan")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <NumberInput
                  label="LS Seat"
                  placeholder="LS Seat"
                  {...form.getInputProps("ls")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <NumberInput
                  label="RS Seat"
                  placeholder="RS Seat"
                  {...form.getInputProps("rs")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <NumberInput
                  label="Total MLA"
                  placeholder="Total MLA"
                  {...form.getInputProps("mla")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <NumberInput
                  label="Total MP"
                  placeholder="Total MP"
                  {...form.getInputProps("mp")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <NumberInput
                  label="Total MLC"
                  placeholder="Total MLC"
                  {...form.getInputProps("mlc")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="In Power"
                  placeholder="In Power"
                  {...form.getInputProps("power")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Web Url"
                  placeholder="Web Url"
                  {...form.getInputProps("web")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Total Voters"
                  placeholder="Total Voters"
                  {...form.getInputProps("totalvote")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Headquarters"
                  placeholder="Headquarters"
                  {...form.getInputProps("headq")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Address line1"
                  placeholder="Address line1"
                  {...form.getInputProps("address1")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Address line2"
                  placeholder="Address line2"
                  {...form.getInputProps("address2")}
                />
              </Grid.Col>

              <Grid.Col xs={12}>
                <Text size="xs">Ideology</Text>
                <RichTextEditor editor={editor}>
                  <RichTextEditor.Toolbar sticky stickyOffset={60}>
                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Bold />
                      <RichTextEditor.Italic />
                      <RichTextEditor.Underline />
                      <RichTextEditor.Strikethrough />
                      <RichTextEditor.ClearFormatting />
                      <RichTextEditor.Highlight />
                      <RichTextEditor.Code />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.H1 />
                      <RichTextEditor.H2 />
                      <RichTextEditor.H3 />
                      <RichTextEditor.H4 />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Blockquote />
                      <RichTextEditor.Hr />
                      <RichTextEditor.BulletList />
                      <RichTextEditor.OrderedList />
                      <RichTextEditor.Subscript />
                      <RichTextEditor.Superscript />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Link />
                      <RichTextEditor.Unlink />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.AlignLeft />
                      <RichTextEditor.AlignCenter />
                      <RichTextEditor.AlignJustify />
                      <RichTextEditor.AlignRight />
                    </RichTextEditor.ControlsGroup>
                  </RichTextEditor.Toolbar>

                  <RichTextEditor.Content
                    value={value}
                    mb={20}
                    {...form.getInputProps("ideology")}
                  />
                </RichTextEditor>
              </Grid.Col>
              <Grid.Col xs={12}>
                <Text size="xs">Description</Text>
                <RichTextEditor editor={editor1}>
                  <RichTextEditor.Toolbar sticky stickyOffset={60}>
                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Bold />
                      <RichTextEditor.Italic />
                      <RichTextEditor.Underline />
                      <RichTextEditor.Strikethrough />
                      <RichTextEditor.ClearFormatting />
                      <RichTextEditor.Highlight />
                      <RichTextEditor.Code />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.H1 />
                      <RichTextEditor.H2 />
                      <RichTextEditor.H3 />
                      <RichTextEditor.H4 />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Blockquote />
                      <RichTextEditor.Hr />
                      <RichTextEditor.BulletList />
                      <RichTextEditor.OrderedList />
                      <RichTextEditor.Subscript />
                      <RichTextEditor.Superscript />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Link />
                      <RichTextEditor.Unlink />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.AlignLeft />
                      <RichTextEditor.AlignCenter />
                      <RichTextEditor.AlignJustify />
                      <RichTextEditor.AlignRight />
                    </RichTextEditor.ControlsGroup>
                  </RichTextEditor.Toolbar>

                  <RichTextEditor.Content
                    value={value1}
                    mb={20}
                    {...form.getInputProps("description")}
                  />
                </RichTextEditor>
              </Grid.Col>
              <Grid.Col xs={12}>
                <Button
                  fullWidth
                  radius="md"
                  type="submit"
                  size="md"
                  variant="gradient"
                  gradient={{ from: "orange", to: "red" }}
                >
                  Add Political Party
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </Modal>
      {/* // State Add MOdal */}

      {/* Political Party Edit Modal */}
      <Modal
        opened={variables.openEdit}
        onClose={() => setVariables({ ...variables, openEdit: false })}
        title={"Update Political Party Details"}
        size="70%"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div style={{ padding: "10px" }}>
          <form onSubmit={editform.onSubmit((values) => handleEdits(values))}>
            <Grid>
              <Grid.Col xs={3}>
                <TextInput
                  label="Party Name"
                  required
                  placeholder="State name"
                  {...editform.getInputProps("partyname")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Abbreviation"
                  placeholder="Abbreviation"
                  {...editform.getInputProps("abbreviation")}
                />
              </Grid.Col>

              {editform.values.type !== "National" ? (
                <>
                  <Grid.Col xs={3}>
                    <Select
                      label="State"
                      searchable
                      clearable
                      required
                      placeholder="Select State"
                      data={state}
                      {...editform.getInputProps("state")}
                    />
                  </Grid.Col>
                </>
              ) : null}
              <Grid.Col xs={3}>
                <Select
                  searchable
                  label="Select Type"
                  placeholder="Select Type"
                  data={[
                    { value: "National", label: "National" },
                    { value: "State", label: "State" },
                    // { value: "Both", label: "Both" },
                  ]}
                  {...editform.getInputProps("type")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Eci Status"
                  placeholder="Eci Status"
                  {...editform.getInputProps("eci")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <NumberInput
                  label="Founded Year"
                  placeholder="Founded Year"
                  {...editform.getInputProps("year")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Founder Name"
                  placeholder="Founder Name"
                  {...editform.getInputProps("name")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Origin"
                  placeholder="Origin"
                  {...editform.getInputProps("origin")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Political Position"
                  placeholder="Political Position"
                  {...editform.getInputProps("political_position")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Student Wing"
                  placeholder="Student Wing"
                  {...editform.getInputProps("student")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Youth Wing"
                  placeholder="Youth Wing"
                  {...editform.getInputProps("youth")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Women Wing"
                  placeholder="Women Wing"
                  {...editform.getInputProps("women")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Slogan"
                  placeholder="Slogan"
                  {...editform.getInputProps("slogan")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <NumberInput
                  label="LS Seat"
                  placeholder="LS Seat"
                  {...editform.getInputProps("ls")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <NumberInput
                  label="RS Seat"
                  placeholder="RS Seat"
                  {...editform.getInputProps("rs")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <NumberInput
                  label="Total MLA"
                  placeholder="Total MLA"
                  {...editform.getInputProps("mla")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <NumberInput
                  label="Total MP"
                  placeholder="Total MP"
                  {...editform.getInputProps("mp")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <NumberInput
                  label="Total MLC"
                  placeholder="Total MLC"
                  {...editform.getInputProps("mlc")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="In Power"
                  placeholder="In Power"
                  {...editform.getInputProps("power")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Web Url"
                  placeholder="Web Url"
                  {...editform.getInputProps("web")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Total Voters"
                  placeholder="Total Voters"
                  {...editform.getInputProps("totalvote")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Headquarters"
                  placeholder="Headquarters"
                  {...editform.getInputProps("headq")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Address line1"
                  placeholder="Address line1"
                  {...editform.getInputProps("address1")}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <TextInput
                  label="Address line2"
                  placeholder="Address line2"
                  {...editform.getInputProps("address2")}
                />
              </Grid.Col>
              <Grid.Col xs={12}>
                <Text size="xs" mt={20}>
                  Ideology
                </Text>
                <RichTextEditor editor={editor}>
                  <RichTextEditor.Toolbar sticky stickyOffset={60}>
                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Bold />
                      <RichTextEditor.Italic />
                      <RichTextEditor.Underline />
                      <RichTextEditor.Strikethrough />
                      <RichTextEditor.ClearFormatting />
                      <RichTextEditor.Highlight />
                      <RichTextEditor.Code />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.H1 />
                      <RichTextEditor.H2 />
                      <RichTextEditor.H3 />
                      <RichTextEditor.H4 />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Blockquote />
                      <RichTextEditor.Hr />
                      <RichTextEditor.BulletList />
                      <RichTextEditor.OrderedList />
                      <RichTextEditor.Subscript />
                      <RichTextEditor.Superscript />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Link />
                      <RichTextEditor.Unlink />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.AlignLeft />
                      <RichTextEditor.AlignCenter />
                      <RichTextEditor.AlignJustify />
                      <RichTextEditor.AlignRight />
                    </RichTextEditor.ControlsGroup>
                  </RichTextEditor.Toolbar>

                  <RichTextEditor.Content
                    value={value}
                    mb={20}
                    {...editform.getInputProps("ideology")}
                  />
                </RichTextEditor>
              </Grid.Col>
              <Grid.Col xs={12}>
                <Text size="xs" mt={10}>
                  Description
                </Text>
                <RichTextEditor editor={editor1}>
                  <RichTextEditor.Toolbar sticky stickyOffset={60}>
                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Bold />
                      <RichTextEditor.Italic />
                      <RichTextEditor.Underline />
                      <RichTextEditor.Strikethrough />
                      <RichTextEditor.ClearFormatting />
                      <RichTextEditor.Highlight />
                      <RichTextEditor.Code />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.H1 />
                      <RichTextEditor.H2 />
                      <RichTextEditor.H3 />
                      <RichTextEditor.H4 />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Blockquote />
                      <RichTextEditor.Hr />
                      <RichTextEditor.BulletList />
                      <RichTextEditor.OrderedList />
                      <RichTextEditor.Subscript />
                      <RichTextEditor.Superscript />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Link />
                      <RichTextEditor.Unlink />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.AlignLeft />
                      <RichTextEditor.AlignCenter />
                      <RichTextEditor.AlignJustify />
                      <RichTextEditor.AlignRight />
                    </RichTextEditor.ControlsGroup>
                  </RichTextEditor.Toolbar>

                  <RichTextEditor.Content
                    value={value1}
                    mb={20}
                    {...editform.getInputProps("description")}
                  />
                </RichTextEditor>
              </Grid.Col>
              <Grid.Col xs={12}>
                <Button
                  loading={loadingState}
                  fullWidth
                  radius="md"
                  mt="xl"
                  mb={10}
                  type="submit"
                  size="md"
                  variant="gradient"
                  gradient={{ from: "orange", to: "red" }}
                >
                  Update Political Party
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </Modal>
      {/* Political Party Edit Modal */}

      {/* Political Party Image Modal */}
      <Modal
        opened={variables.imageDrawer}
        onClose={() => setVariables({ ...variables, imageDrawer: false })}
        title={imageTitle}
        size="xl"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <form onSubmit={formImage.onSubmit((values) => handleUpload(values))}>
          <Grid style={{ padding: "10px" }}>
            <Grid.Col md={6} lg={6} mt={1}>
              <Text style={{ textAlign: "center", fontWeight: "bold" }}>
                LOGO
              </Text>
              {/* For image croper */}
              {/* For croper */}
              {upImg !== "" && upImg !== null ? (
                <>
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={upImg}
                      style={{
                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                      }}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                  <div>
                    {previewCanvasRef != null ? (
                      <canvas
                        ref={previewCanvasRef}
                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                        style={{
                          width: Math.round(completedCrop?.width ?? 0),
                          height: Math.round(completedCrop?.height ?? 0),
                          marginBottom: 50,
                          display: "none",
                        }}
                      />
                    ) : null}

                    <Group position="right" mt="md" mb={20}>
                      <Button
                        type="submit"
                        color="zevcore"
                        onClick={() => {
                          setUpImg("");
                          setImage("");
                        }}
                      >
                        Clear Image
                      </Button>
                    </Group>
                  </div>
                </>
              ) : (
                // For selecting cropping image dropdown
                <div
                  style={{
                    marginTop: 15,
                    position: "relative",
                    marginBottom: 30,
                  }}
                >
                  <Paper
                    p="lg"
                    sx={(theme) => ({
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[8]
                          : theme.white,
                    })}
                  >
                    <Avatar
                      src={URL_CONSTANTS.POLITICAL_PARTY_IMAGE + profileimage}
                      size={150}
                      radius={100}
                      withBorder
                      mx="auto"
                    />

                    <Group position="center">
                      <FileButton
                        resetRef={ref}
                        onChange={changeHandler}
                        accept={[
                          "image/png",
                          "image/jpeg",
                          "image/sgv+xml",
                          "image/gif",
                        ]}
                      >
                        {(props) => (
                          <Button mt={20} {...props}>
                            Select Image
                          </Button>
                        )}
                      </FileButton>
                    </Group>
                  </Paper>
                </div>
              )}
            </Grid.Col>
            <Grid.Col md={6} lg={6} mt={1}>
              <Text style={{ textAlign: "center", fontWeight: "bold" }}>
                Symbol
              </Text>
              {/* For image croper */}
              {/* For croper */}
              {upImg1 !== "" && upImg1 !== null ? (
                <>
                  <ReactCrop
                    crop={crop1}
                    onChange={(_, percentCrop) => setCrop1(percentCrop)}
                    onComplete={(c) => setCompletedCrop1(c)}
                    aspect={aspect1}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={upImg1}
                      style={{
                        transform: `scale(${scale1}) rotate(${rotate1}deg)`,
                      }}
                      onLoad={onImageLoad1}
                    />
                  </ReactCrop>
                  <div>
                    {previewCanvasRef1 != null ? (
                      <canvas
                        ref={previewCanvasRef1}
                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                        style={{
                          width: Math.round(completedCrop1?.width ?? 0),
                          height: Math.round(completedCrop1?.height ?? 0),
                          marginBottom: 50,
                          display: "none",
                        }}
                      />
                    ) : null}

                    <Group position="right" mt="md" mb={20}>
                      <Button
                        type="submit"
                        color="zevcore"
                        onClick={() => {
                          setUpImg1("");
                          setImage1("");
                        }}
                      >
                        Clear Image
                      </Button>
                    </Group>
                  </div>
                </>
              ) : (
                // For selecting cropping image dropdown
                <div
                  style={{
                    marginTop: 15,
                    position: "relative",
                    marginBottom: 30,
                  }}
                >
                  <Paper
                    p="lg"
                    sx={(theme) => ({
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[8]
                          : theme.white,
                    })}
                  >
                    <Avatar
                      src={URL_CONSTANTS.POLITICAL_PARTY_IMAGE + symbolimage}
                      size={150}
                      radius={100}
                      withBorder
                      mx="auto"
                    />

                    <Group position="center">
                      <FileButton
                        resetRef={ref1}
                        onChange={changeHandler1}
                        accept={[
                          "image/png",
                          "image/jpeg",
                          "image/sgv+xml",
                          "image/gif",
                        ]}
                      >
                        {(props) => (
                          <Button mt={20} {...props}>
                            Select Image
                          </Button>
                        )}
                      </FileButton>
                    </Group>
                  </Paper>
                </div>
              )}
            </Grid.Col>
            <Button
              loading={loadingState}
              fullWidth
              type="submit"
              size="md"
              variant="gradient"
              gradient={{ from: "orange", to: "red" }}
            >
              Update State Image
            </Button>
          </Grid>
        </form>
      </Modal>
      {/* Political Party Image Modal */}
    </div>
  );
}

export default StateParty;
