/**
 * Application Name: JanSarkar(Mantine Version 6.0.1)
 * Author: Zevcore Private Limited
 * Starting date: 14.03.2023
 * Developer Name: Anand Krishna
 * Last modified date: 15.03.2023
 * Last modified by: Anand Krishna
 **/

import {
  ActionIcon,
  Avatar,
  Anchor,
  Button,
  Card,
  Grid,
  Group,
  Loader,
  Menu,
  Modal,
  NativeSelect,
  NumberInput,
  Pagination,
  ScrollArea,
  Skeleton,
  Table,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { Dots, Pencil, Photo, Search, Trash, X } from "tabler-icons-react";
import { CSVLink } from "react-csv";
// Image
import excel from "../../assets/images/excel.png";
import pdf from "../../assets/images/pdf.png";
import BreadCrumb from "../../components/Breadcrumbs/BreadCrumbs";
import {
  dataSlice,
  getActiveColor,
  imageModal,
  selectFilter,
  onDownload,
  pageModal,
} from "../../helpers/common";
import notificationHelper from "../../helpers/Notification"; // Import notification for this page
import { Th, dataSearch, setSorting, print } from "../../helpers/tableFunction"; // For table data functions
import useStyles from "../../components/Styles/style"; // Import the style from
import {
  handleDeleteComplaint,
  handleDeleteFeedback,
  handleGetComplaints,
  handleGetFeedback,
} from "../../helpers/Apis";
import { modals } from "@mantine/modals";
import { useForm } from "@mantine/form";
import { URL_CONSTANTS } from "../../constants/constants";
// RichtextEditor

function Complaints() {
  const { classes } = useStyles();
  const [search, setSearch] = useState("");
  const [opened, setOpened] = useState(false);

  const [sortedData, setSortedData] = useState([]);
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const theme = useMantineTheme();
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table

  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: true,
    submitLoading: false,
    data: [],
    addDrawer: false,
    bulkDrawer: false,
    imageDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });

  //   For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        //   For get all the state data list
        const response = await handleGetComplaints();
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data.reverse(),
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setSortedData(datas);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Form values for adding state values
  const form = useForm({
    initialValues: {
      state: "",
      year: "",
      value: "",
      lastupdate: "",
      statetype: "",
    },
  });

  //For delete confirm modal show Delete
  const openConfirmModalDelete = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the complaint{" "}
          <b>{datas.complaint_type.name}</b> ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  //   For delete db data from table and db
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteComplaint(e);
    // Check the response for notification and actions

    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Complaint deleted successfully",
      });
      var filter = variables.data;
      filter = filter.filter((img) => img.value !== e);
      setVariables({
        ...variables,
        data: response.data.data.reverse(),
        skeletonLoading: false,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Table data arrange by using function and loop throw each data range to table body
  const rows = sortedData.map((row, index) => (
    <tr key={row.name}>
      <td>{variables.data.length - activePage * total + total - index}</td>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.image != "" ? (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.COMPLAINT_IMAGE + row.image,
                  title: row.complaint_type.name,
                });
              }}
              radius={30}
              size={30}
              src={URL_CONSTANTS.COMPLAINT_IMAGE + row.image}
            />
          ) : (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.COMPLAINT_IMAGE + row.image,
                  title: row.complaint_type.name,
                });
              }}
              size={20}
              src={URL_CONSTANTS.COMPLAINT_IMAGE + row.image}
            />
          )}

          <div>
            <Text fz="sm" fw={500}>
              <Anchor component="button" size="sm">
                {row.complaint_type.name}
              </Anchor>
            </Text>
          </div>
        </Group>
      </td>
      <td>
        {row.corporation_ward !== null ? (
          <>{row.corporation_ward.name}</>
        ) : (
          "--"
        )}
      </td>
      <td>{row.user.username}</td>
      <td>{new Date(row.createdAt).toLocaleDateString()}</td>
      <td>{row.representative !== null ? row.representative.name : "-"}</td>
      <td>
        <Group spacing={0} position="left">
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.id)}
            color="red"
          >
            <Trash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  return (
    <div>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb
          Text="All Complaints"
          Title="Settings"
          titleLink="/settings"
        />
      </Skeleton>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card className="border">
          <ScrollArea>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* For search the table data input forms */}
              <TextInput
                variant="filled"
                placeholder="Search"
                mb="md"
                size="sm"
                value={search}
                icon={<Search size={16} />}
                onChange={async (e) => {
                  // On change search ofr the data that is enter
                  setSearch(e.currentTarget.value);
                  setPage(1);
                  const datas = await dataSearch({
                    data: variables.data,
                    value: e.currentTarget.value,
                    activePage: activePage,
                    total: total,
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                sx={{ width: 250 }}
              />
              <div>
                <Group spacing="xs">
                  {/* For export the the table data to pdf and excels */}
                  <Text>Exports :</Text>
                  <CSVLink
                    data={variables.data}
                    headers={[
                      { label: "id", key: "id" },
                      {
                        label: "complaints_type_id",
                        key: "complaints_type_id",
                      },
                      { label: "designation", key: "designation" },
                      { label: "loksabha_id", key: "loksabha_id" },
                      {
                        label: "corporation_ward_id",
                        key: "corporation_ward_id",
                      },
                      { label: "vidhansabha_id", key: "vidhansabha_id" },
                      { label: "gpward_id", key: "gpward_id" },
                      { label: "representative_id", key: "representative_id" },
                      { label: "district_id", key: "district_id" },
                      { label: "latitude", key: "latitude" },
                      { label: "altitude", key: "altitude" },
                      { label: "address", key: "address" },
                      { label: "local_time", key: "local_time" },
                      { label: "global_time", key: "global_time" },
                      { label: "date_time", key: "date_time" },
                      { label: "added_by", key: "added_by" },
                      { label: "points", key: "points" },
                      { label: "status", key: "status" },
                      { label: "verifiedStatus", key: "verifiedStatus" },
                      { label: "vote", key: "vote" },
                      { label: "view", key: "view" },
                      { label: "createdAt", key: "createdAt" },
                      { label: "updatedAt", key: "updatedAt" },
                    ]}
                    filename="complaints.csv"
                    className={classes.pdfExcel}
                  >
                    <img
                      src={excel}
                      alt="excel"
                      width="25"
                      style={{ margin: "2px" }}
                    />
                  </CSVLink>
                  <button
                    className={classes.pdfExcel}
                    onClick={() =>
                      print({
                        name: "complaints",
                        data: variables.data,
                        list: [
                          "id",
                          "complaints_type_id",
                          "designation",
                          "loksabha_id",
                          "corporation_ward_id",
                          "vidhansabha_id",
                          "gpward_id",
                          "representative_id",
                          "district_id",
                          "latitude",
                          "longitude",
                          "altitude",
                          "address",
                          "local_time",
                          "global_time",
                          "date_time",
                          "added_by",
                          "points",
                          "status",
                          "verifiedStatus",
                          "vote",
                          "view",
                          "createdAt",
                          "updatedAt",
                        ],
                        header: [
                          "id",
                          "complaints_type_id",
                          "designation",
                          "loksabha_id",
                          "corporation_ward_id",
                          "vidhansabha_id",
                          "gpward_id",
                          "representative_id",
                          "district_id",
                          "latitude",
                          "longitude",
                          "altitude",
                          "address",
                          "local_time",
                          "global_time",
                          "date_time",
                          "added_by",
                          "points",
                          "status",
                          "verifiedStatus",
                          "vote",
                          "view",
                          "createdAt",
                          "updatedAt",
                        ],
                      })
                    }
                  >
                    <img
                      src={pdf}
                      alt="pdf"
                      width="19"
                      style={{ margin: "2px" }}
                    />
                  </button>
                </Group>
              </div>
            </div>
            {/* className={classes.striped} */}
            <Table verticalSpacing="sm">
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Complaint Name</th>
                  <th>Ward No.</th>
                  <th>Added By</th>
                  <th>Added On</th>
                  <th>Representative</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {rows.length > 0 ? (
                <tbody>{rows}</tbody>
              ) : (
                <>
                  {loadingData === false ? (
                    <tbody>
                      <tr>
                        <td colSpan={4}>
                          <div style={{ textAlign: "center" }}>
                            <Loader size="xl" color="orange" variant="dots" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={4}>
                          <div style={{ textAlign: "center" }}>
                            <Text weight={500} align="center">
                              Nothing found
                            </Text>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </ScrollArea>
          {/* For display the pagination and no of per pages list */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 15,
            }}
          >
            {/* For pagination */}
            <Pagination
              withEdges
              size="sm"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="orange"
            />
          </div>
        </Card>
      </Skeleton>
    </div>
  );
}

export default Complaints;
