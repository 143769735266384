import {
  ActionIcon,
  Avatar,
  Card,
  Divider,
  Grid,
  Group,
  Loader,
  Paper,
  Progress,
  ScrollArea,
  Skeleton,
  Text,
  Table,
  Anchor,
  Button,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { ChevronRight } from "tabler-icons-react";
import BreadCrumb from "../components/Breadcrumbs/BreadCrumbs";
import { URL_CONSTANTS } from "../constants/constants";
import {
  handleGetCountDetails,
  handleGetNationalParty,
  handleGetRecentAspirants,
  handleGetRecentComplaints,
  handleGetRecentUsers,
  handleGetStateParty,
} from "../helpers/Apis";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    addDrawer: false,
    bulkDrawer: false,
    imageDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });

  let navigate = useNavigate();
  // Representatives
  const [total_mp, setTotal_mp] = useState(0);
  const [total_mla, setTotal_mla] = useState(0);
  const [total_corp, setTotal_corp] = useState(0);
  const [total_gp_member, setTotal_gp_member] = useState(0);

  // Constituencies
  const [total_loksabha, setTotal_loksabha] = useState(0);
  const [total_vidhansabha, setTotal_vidhansabha] = useState(0);
  const [total_corporation, setTotal_corporation] = useState(0);
  const [total_gp_ward, setTotal_gp_ward] = useState(0);

  // National Parties
  const [national_parties, setNational_parties] = useState([]);

  // State Parties
  const [state_parties, setState_parties] = useState([]);

  // Users
  const [users, setUsers] = useState([]);

  // Aspirants
  const [aspirants, setAspirants] = useState([]);

  // Complaints
  const [complaints, setComplaints] = useState([]);

  // Loading Status
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the state data list
        const response = await handleGetCountDetails();
        console.log(response.data.data);
        setTotal_mp(response.data.data[0]);
        setTotal_mla(response.data.data[1]);
        setTotal_corp(response.data.data[2]);
        setTotal_gp_member(response.data.data[3]);
        setTotal_loksabha(response.data.data[4]);
        setTotal_vidhansabha(response.data.data[5]);
        setTotal_corporation(response.data.data[6]);
        setTotal_gp_ward(response.data.data[7]);
        // On Response setting the data to variable

        // For get all the national data list
        const response1 = await handleGetNationalParty();
        if (response1.status === 200) {
          console.log(response1.data.data);
          setNational_parties(response1.data.data);
        }

        // For get all the state data list
        const response2 = await handleGetStateParty();
        if (response2.status === 200) {
          console.log(response2.data.data);
          setState_parties(response2.data.data);
        }

        // For get all the recently registered user data list
        const response3 = await handleGetRecentUsers();
        if (response3.status === 200) {
          console.log(response3.data.data);
          setUsers(response3.data.data);
        }

        // For get all the recently registered aspirants data list
        const response5 = await handleGetRecentAspirants();
        if (response5.status === 200) {
          console.log(response5.data.data);
          setAspirants(response5.data.data);
        }

        // For get all the recently added complaints data list
        const response4 = await handleGetRecentComplaints();
        if (response4.status === 200) {
          console.log(response4.data.data);
          setComplaints(response4.data.data);
          setLoadingData(false);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Table data arrange by using function and loop throw each data range to table body
  const rows = complaints.map((row, index) => (
    <tr key={row.id}>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.image != "" ? (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.COMPLAINT_IMAGE + row.image,
                  title: row.complaint_type.name,
                });
              }}
              radius={30}
              size={30}
              src={URL_CONSTANTS.COMPLAINT_IMAGE + row.image}
            />
          ) : (
            <Avatar size={20} />
          )}

          <div>
            <Text fz="sm" fw={500}>
              <Anchor component="button" radius={30} size="sm">
                {row.complaint_type.name}
              </Anchor>
            </Text>
          </div>
        </Group>
      </td>
      <td>
        {row.corporation_ward !== null ? (
          <>{row.corporation_ward.name}</>
        ) : (
          "--"
        )}
      </td>
      <td>{row.user.username}</td>
      <td>{new Date(row.createdAt).toLocaleDateString()}</td>
      <td>{row.representative !== null ? row.representative.name : "-"}</td>
    </tr>
  ));

  const rows1 = aspirants.map((row, index) => (
    <tr key={row.id}>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.image != "" ? (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.REPRESENTATIVE_IMAGE + row.image,
                  title: row.name,
                });
              }}
              radius={30}
              size={30}
              src={URL_CONSTANTS.REPRESENTATIVE_IMAGE + row.image}
            />
          ) : (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.REPRESENTATIVE_IMAGE + row.image,
                  title: row.name,
                });
              }}
              size={20}
              src={URL_CONSTANTS.REPRESENTATIVE_IMAGE + row.image}
            />
          )}

          <div>
            <Text fz="sm" fw={500}>
              <Anchor
                onClick={() => handleEdit(row.id)}
                component="button"
                size="sm"
              >
                {row.name}
              </Anchor>
            </Text>
            <Text c="dimmed" size={10}>
              {row.state !== null &&
              row.state !== "" &&
              typeof row.state !== "undefined"
                ? row.state.name
                : "--"}
            </Text>
          </div>
        </Group>
      </td>
      <td>
        <Group spacing="sm">
          <div>
            <Text fz="sm" fw={500}>
              <Anchor
                onClick={() => handleEdit(row.id)}
                component="button"
                size="sm"
              >
                {row.phone_number !== null &&
                row.phone_number !== "" &&
                typeof row.phone_number !== "undefined"
                  ? row.phone_number
                  : "Phone: N/A"}
              </Anchor>
            </Text>
            <Text c="dimmed" fz="xs">
              {row.email !== null &&
              row.email !== "" &&
              typeof row.email !== "undefined"
                ? row.email
                : "Email: N/A"}
            </Text>
          </div>
        </Group>
      </td>
    </tr>
  ));

  // Table data arrange by using function and loop throw each data range to table body
  const rows2 = users.map((row, index) => (
    <tr key={row.id}>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.image != "" ? (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.REPRESENTATIVE_IMAGE + row.image,
                  title: row.username,
                });
              }}
              radius={30}
              size={30}
              src={URL_CONSTANTS.REPRESENTATIVE_IMAGE + row.image}
            />
          ) : (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.REPRESENTATIVE_IMAGE + row.image,
                  title: row.username,
                });
              }}
              size={20}
              src={URL_CONSTANTS.REPRESENTATIVE_IMAGE + row.image}
            />
          )}

          <div>
            <Text fz="sm" fw={500}>
              <Anchor component="button" size="sm">
                {row.username}
              </Anchor>
            </Text>
            <Text c="dimmed" size={10}>
              {row.state !== null &&
              row.state !== "" &&
              typeof row.state !== "undefined"
                ? row.state.name
                : "--"}
            </Text>
          </div>
        </Group>
      </td>
      <td>
        <Group spacing="sm">
          <div>
            <Text fz="sm" fw={500}>
              <Anchor
                onClick={() => handleEdit(row.id)}
                component="button"
                size="sm"
              >
                {row.phone_number !== null &&
                row.phone_number !== "" &&
                typeof row.phone_number !== "undefined"
                  ? row.phone_number
                  : "Phone: N/A"}
              </Anchor>
            </Text>
            <Text c="dimmed" fz="xs">
              {row.email !== null &&
              row.email !== "" &&
              typeof row.email !== "undefined"
                ? row.email
                : "Email: N/A"}
            </Text>
          </div>
        </Group>
      </td>
    </tr>
  ));

  return (
    <div>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb Text="Dashboard" />
      </Skeleton>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <ScrollArea>
          <Paper radius="md" p="md">
            <Group position="apart" mb={10}>
              <Text fz="xs" tt="uppercase" ta="center" fw={700} c="dimmed">
                Representatives
              </Text>
            </Group>
            <Grid>
              <Grid.Col xs={3}>
                <Card
                  withBorder
                  radius="md"
                  padding="xl"
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[7]
                        : theme.white,
                  })}
                >
                  <Group position="apart">
                    <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                      Total MP
                    </Text>
                    <Button
                      variant="outline"
                      color="orange"
                      radius="sm"
                      size="xs"
                      onClick={() => navigate("/Mp")}
                    >
                      View All
                    </Button>
                  </Group>
                  <Text fz="lg" fw={500}>
                    {total_mp}
                  </Text>
                  <Progress
                    value={54.31}
                    mt="md"
                    color="orange"
                    size="lg"
                    radius="xl"
                  />
                </Card>
              </Grid.Col>
              <Grid.Col xs={3}>
                <Card
                  withBorder
                  radius="md"
                  padding="xl"
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[7]
                        : theme.white,
                  })}
                >
                  <Group position="apart">
                    <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                      Total MLA
                    </Text>
                    <Button
                      variant="outline"
                      color="orange"
                      radius="sm"
                      size="xs"
                      onClick={() => navigate("/Mla")}
                    >
                      View All
                    </Button>
                  </Group>
                  <Text fz="lg" fw={500}>
                    {total_mla}
                  </Text>
                  <Progress
                    value={54.31}
                    mt="md"
                    size="lg"
                    color="orange"
                    radius="xl"
                  />
                </Card>
              </Grid.Col>
              <Grid.Col xs={3}>
                <Card
                  withBorder
                  radius="md"
                  padding="xl"
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[7]
                        : theme.white,
                  })}
                >
                  <Group position="apart">
                    <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                      Total Corporator
                    </Text>
                    <Button
                      variant="outline"
                      color="orange"
                      radius="sm"
                      size="xs"
                      onClick={() => navigate("/corporator")}
                    >
                      View All
                    </Button>
                  </Group>
                  <Text fz="lg" fw={500}>
                    {total_corp}
                  </Text>
                  <Progress
                    value={54.31}
                    mt="md"
                    color="orange"
                    size="lg"
                    radius="xl"
                  />
                </Card>
              </Grid.Col>
              <Grid.Col xs={3}>
                <Card
                  withBorder
                  radius="md"
                  padding="xl"
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[7]
                        : theme.white,
                  })}
                >
                  <Group position="apart">
                    <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                      Total GP Member
                    </Text>
                    <Button
                      variant="outline"
                      color="orange"
                      radius="sm"
                      size="xs"
                      onClick={() => navigate("/Gp")}
                    >
                      View All
                    </Button>
                  </Group>
                  <Text fz="lg" fw={500}>
                    {total_gp_member}
                  </Text>
                  <Progress
                    value={54.31}
                    mt="md"
                    color="orange"
                    size="lg"
                    radius="xl"
                  />
                </Card>
              </Grid.Col>
            </Grid>
          </Paper>
          <Paper mt={10} radius="md" p="md">
            <Group position="apart" mb={10}>
              <Text fz="xs" tt="uppercase" ta="center" fw={700} c="dimmed">
                Constituencies
              </Text>
            </Group>

            <Grid>
              <Grid.Col xs={3}>
                <Card
                  withBorder
                  radius="md"
                  padding="xl"
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[7]
                        : theme.white,
                  })}
                >
                  <Group position="apart">
                    <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                      Total Loksabha
                    </Text>
                    <Button
                      variant="outline"
                      color="orange"
                      radius="sm"
                      size="xs"
                      onClick={() => navigate("/loksabha")}
                    >
                      View All
                    </Button>
                  </Group>
                  <Text fz="lg" fw={500}>
                    {total_loksabha}
                  </Text>
                  <Progress
                    value={54.31}
                    mt="md"
                    color="orange"
                    size="lg"
                    radius="xl"
                  />
                </Card>
              </Grid.Col>
              <Grid.Col xs={3}>
                <Card
                  withBorder
                  radius="md"
                  padding="xl"
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[7]
                        : theme.white,
                  })}
                >
                  <Group position="apart">
                    <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                      Total Vidhansabha
                    </Text>
                    <Button
                      variant="outline"
                      color="orange"
                      radius="sm"
                      size="xs"
                      onClick={() => navigate("/assembly")}
                    >
                      View All
                    </Button>
                  </Group>
                  <Text fz="lg" fw={500}>
                    {total_vidhansabha}
                  </Text>
                  <Progress
                    value={54.31}
                    mt="md"
                    size="lg"
                    color="orange"
                    radius="xl"
                  />
                </Card>
              </Grid.Col>
              <Grid.Col xs={3}>
                <Card
                  withBorder
                  radius="md"
                  padding="xl"
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[7]
                        : theme.white,
                  })}
                >
                  <Group position="apart">
                    <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                      Total Corporation
                    </Text>
                    <Button
                      variant="outline"
                      color="orange"
                      radius="sm"
                      size="xs"
                      onClick={() => navigate("/corporation")}
                    >
                      View All
                    </Button>
                  </Group>
                  <Text fz="lg" fw={500}>
                    {total_corporation}
                  </Text>
                  <Progress
                    value={54.31}
                    mt="md"
                    color="orange"
                    size="lg"
                    radius="xl"
                  />
                </Card>
              </Grid.Col>
              <Grid.Col xs={3}>
                <Card
                  withBorder
                  radius="md"
                  padding="xl"
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[7]
                        : theme.white,
                  })}
                >
                  <Group position="apart">
                    <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                      Total GP Wards
                    </Text>
                    <Button
                      variant="outline"
                      color="orange"
                      radius="sm"
                      size="xs"
                      onClick={() => navigate("/Gpward")}
                    >
                      View All
                    </Button>
                  </Group>
                  <Text fz="lg" fw={500}>
                    {total_gp_ward}
                  </Text>
                  <Progress
                    value={54.31}
                    mt="md"
                    color="orange"
                    size="lg"
                    radius="xl"
                  />
                </Card>
              </Grid.Col>
            </Grid>
          </Paper>

          <Paper mt={10} radius="md" p="md">
            <Grid>
              <Grid.Col xs={6}>
                <Card shadow="xs" padding="lg" radius="md" withBorder>
                  <Group position="apart">
                    <Text fz="md" tt="uppercase" fw={700} c="dimmed">
                      National Parties
                    </Text>
                    <Button
                      variant="outline"
                      color="orange"
                      radius="sm"
                      size="xs"
                      onClick={() => navigate("/party")}
                    >
                      View All
                    </Button>
                  </Group>
                  <Grid mt={10}>
                    {national_parties.map((row) => (
                      <Grid.Col xs={4}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            src={
                              URL_CONSTANTS.POLITICAL_PARTY_IMAGE + row.symbol
                            }
                            radius={30}
                            width="50"
                            alt="maps"
                          />
                        </div>
                        <Text size="xs" style={{ textAlign: "center" }}>
                          {row.abbreviation}
                        </Text>
                      </Grid.Col>
                    ))}
                  </Grid>
                </Card>
              </Grid.Col>

              <Grid.Col xs={6}>
                <Card shadow="xs" padding="lg" radius="md" withBorder>
                  <Group position="apart">
                    <Text fz="md" tt="uppercase" fw={700} c="dimmed">
                      State Parties
                    </Text>
                    <Button
                      variant="outline"
                      color="orange"
                      radius="sm"
                      size="xs"
                      onClick={() => navigate("/party")}
                    >
                      View All
                    </Button>
                  </Group>
                  <Grid mt={10}>
                    {state_parties.map((row) => (
                      <Grid.Col xs={4}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            src={
                              URL_CONSTANTS.POLITICAL_PARTY_IMAGE + row.symbol
                            }
                            width="50"
                            alt="maps"
                          />
                        </div>
                        <Text size="xs" style={{ textAlign: "center" }}>
                          {row.abbreviation}
                        </Text>
                      </Grid.Col>
                    ))}
                  </Grid>
                </Card>
              </Grid.Col>
            </Grid>
          </Paper>

          <Paper mt={10} radius="md" p="md">
            <Grid>
              <Grid.Col xs={6}>
                <Card shadow="xs" padding="lg" radius="md" withBorder>
                  <Group position="apart">
                    <Text fz="md" tt="uppercase" fw={700} c="dimmed">
                      Recently Registered Users
                    </Text>
                    <Button
                      variant="outline"
                      color="orange"
                      radius="sm"
                      size="xs"
                      onClick={() => navigate("/user")}
                    >
                      View All
                    </Button>
                  </Group>
                  <Table
                    horizontalSpacing="md"
                    verticalSpacing="xs"
                    sx={{ tableLayout: "fixed" }}
                  >
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Contact</th>
                      </tr>
                    </thead>
                    {rows2.length > 0 ? (
                      <tbody>{rows2}</tbody>
                    ) : (
                      <>
                        {loadingData === false ? (
                          <tbody>
                            <tr>
                              <td colSpan={4}>
                                <div style={{ textAlign: "center" }}>
                                  <Loader
                                    size="xl"
                                    color="orange"
                                    variant="dots"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan={4}>
                                <div style={{ textAlign: "center" }}>
                                  <Text weight={500} align="center">
                                    Nothing found
                                  </Text>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </>
                    )}
                  </Table>
                </Card>
              </Grid.Col>
              <Grid.Col xs={6}>
                <Card shadow="xs" padding="lg" radius="md" withBorder>
                  <Group position="apart">
                    <Text fz="md" tt="uppercase" fw={700} c="dimmed">
                      Recently Registered Aspirants
                    </Text>
                    <Button
                      variant="outline"
                      color="orange"
                      radius="sm"
                      size="xs"
                      onClick={() => navigate("/aspirants")}
                    >
                      View All
                    </Button>
                  </Group>
                  <Table
                    horizontalSpacing="md"
                    verticalSpacing="xs"
                    sx={{ tableLayout: "fixed" }}
                  >
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Contact</th>
                      </tr>
                    </thead>
                    {rows1.length > 0 ? (
                      <tbody>{rows1}</tbody>
                    ) : (
                      <>
                        {loadingData === false ? (
                          <tbody>
                            <tr>
                              <td colSpan={4}>
                                <div style={{ textAlign: "center" }}>
                                  <Loader
                                    size="xl"
                                    color="orange"
                                    variant="dots"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan={4}>
                                <div style={{ textAlign: "center" }}>
                                  <Text weight={500} align="center">
                                    Nothing found
                                  </Text>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </>
                    )}
                  </Table>
                </Card>
              </Grid.Col>
            </Grid>
          </Paper>
          <Paper mt={10} radius="md" p="md">
            <Grid>
              <Grid.Col xs={12}>
                <Card shadow="xs" padding="lg" radius="md" withBorder>
                  <Group position="apart">
                    <Text fz="md" tt="uppercase" fw={700} c="dimmed">
                      Recently Added Complaints
                    </Text>
                    <Button
                      variant="outline"
                      color="orange"
                      radius="sm"
                      size="xs"
                      onClick={() => navigate("/complaints")}
                    >
                      View All
                    </Button>
                  </Group>
                  <Table
                    horizontalSpacing="md"
                    verticalSpacing="xs"
                    sx={{ tableLayout: "fixed" }}
                  >
                    <thead>
                      <tr>
                        <th>Complaint Name</th>
                        <th>Ward No.</th>
                        <th>Added By</th>
                        <th>Added On</th>
                        <th>Representative</th>
                      </tr>
                    </thead>
                    {rows.length > 0 ? (
                      <tbody>{rows}</tbody>
                    ) : (
                      <>
                        {loadingData === false ? (
                          <tbody>
                            <tr>
                              <td colSpan={4}>
                                <div style={{ textAlign: "center" }}>
                                  <Loader
                                    size="xl"
                                    color="orange"
                                    variant="dots"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan={4}>
                                <div style={{ textAlign: "center" }}>
                                  <Text weight={500} align="center">
                                    Nothing found
                                  </Text>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </>
                    )}
                  </Table>
                </Card>
              </Grid.Col>
            </Grid>
          </Paper>
        </ScrollArea>
      </Skeleton>
    </div>
  );
}

export default Dashboard;
