/**
 * Application Name: JanSarkar(Mantine Version 6.0.1)
 * Author: Zevcore Private Limited
 * Starting date: 14.03.2023
 * Developer Name: Anand Krishna
 * Last modified date: 14.03.2023
 * Last modified by: Anand Krishna
 **/

import React from "react";
import { Navigate, useLocation } from "react-router-dom";

// Function for user doesn't have a token and try to login to dashboard will be redirected to login page.
// Start
function ProtectedRoute({ children }) {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const location = useLocation();

  if (isAuthenticated == null) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    if (isAuthenticated === "true") {
      return children;
    } else {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  }
}

export default ProtectedRoute;
// End
