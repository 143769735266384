/**
 * Application Name: JanSarkar(Mantine Version 6.0.1)
 * Author: Zevcore Private Limited
 * Starting date: 14.03.2023
 * Developer Name: Anand Krishna
 * Last modified date: 14.03.2023
 * Last modified by: Anand Krishna
 **/

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/styles/style.css";
import { HashRouter } from "react-router-dom"; // For import the react dom for navigation components // for import react dom navigation components
import { createBrowserHistory } from "history";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
const history = createBrowserHistory({ window });
root.render(
  <HashRouter history={history}>
    <App />
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
