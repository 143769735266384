/**
 * Application Name: JanSarkar(Mantine Version 6.0.1)
 * Author: Zevcore Private Limited
 * Starting date: 14.03.2023
 * Developer Name: Anand Krishna
 * Last modified date: 15.03.2023
 * Last modified by: Anand Krishna
 **/

import {
  ActionIcon,
  Avatar,
  Anchor,
  Button,
  Card,
  Grid,
  Group,
  Loader,
  Menu,
  Modal,
  NativeSelect,
  NumberInput,
  Pagination,
  ScrollArea,
  Skeleton,
  Table,
  Text,
  TextInput,
  useMantineTheme,
  Paper,
  FileButton,
  Divider,
  Select,
} from "@mantine/core";
import { DatePicker, DatePickerInput } from "@mantine/dates";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import {
  CloudUpload,
  Dots,
  Download,
  Pencil,
  Photo,
  Search,
  Trash,
  X,
} from "tabler-icons-react";
import { CSVLink } from "react-csv";
// Image
import excel from "../../assets/images/excel.png";
import pdf from "../../assets/images/pdf.png";
import BreadCrumb from "../../components/Breadcrumbs/BreadCrumbs";
import {
  dataSlice,
  getActiveColor,
  imageModal,
  selectFilter,
  onDownload,
  pageModal,
} from "../../helpers/common";
import notificationHelper from "../../helpers/Notification"; // Import notification for this page
import { Th, dataSearch, setSorting, print } from "../../helpers/tableFunction"; // For table data functions
import useStyles from "../../components/Styles/style"; // Import the style from
import {
  handleAddGovernmentJobs,
  handleAddScheme,
  handleDeleteGovernmentJobs,
  handleDeleteScheme,
  handleEditGovernmentJobs,
  handleEditScheme,
  handleGetDepartment,
  handleGetGovernmentJobs,
  handleGetScheme,
  handleGetSchemeDepartment,
  handleGetState,
  handleUploadSchemeImage,
  handleUploadStateImage,
} from "../../helpers/Apis";
import { modals } from "@mantine/modals";
import { useForm } from "@mantine/form";
import { URL_CONSTANTS } from "../../constants/constants";
import { useDebounceEffect } from "../../common/userDebounceEffect.ts";
// for image crop
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import {
  Dropzone,
  DropzoneStatus,
  MIME_TYPES,
  IMAGE_MIME_TYPE,
} from "@mantine/dropzone";
import "react-image-crop/dist/ReactCrop.css";
import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
// RichtextEditor

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 10, // make it 10 for smaller crop ratio
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function Jobs() {
  const { classes } = useStyles();
  const [search, setSearch] = useState("");
  const [opened, setOpened] = useState(false);
  const [openedBulk, setOpenedBulk] = useState(false);
  const [department, SetDepartment] = useState([]);
  const [state, setState] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const theme = useMantineTheme();
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table

  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: true,
    submitLoading: false,
    data: [],
    addDrawer: false,
    bulkDrawer: false,
    imageDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });

  //   For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        //   For get all the state data list
        const response = await handleGetGovernmentJobs();
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data.reverse(),
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setSortedData(datas);
        }

        const response2 = await handleGetDepartment();
        if (response2.status === 200) {
          var data = response2.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.name.toString(),
            id: data.id,
            name: data.name,
            state_id: data.state_id,
          }));
          console.log(clean);
          SetDepartment(clean);
        }

        const response3 = await handleGetState();
        if (response3.status === 200) {
          var data = response3.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.name.toString(),
            id: data.id,
            name: data.name,
          }));
          setState(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Form values for adding state values
  const form = useForm({
    initialValues: {
      type: "",
      state: "",
      department: "",
      title: "",
      postno: "",
      salary: "",
      qualification: "",
      agelimit: "",
      gender: "",
      joblocation: "",
      lastdate: new Date(),
      onlinedate: new Date(),
      description: "",
    },
  });

  const editform = useForm({
    initialValues: {
      value: "",
      type: "",
      state: "",
      department: "",
      title: "",
      postno: "",
      salary: "",
      qualification: "",
      agelimit: "",
      gender: "",
      joblocation: "",
      lastdate: new Date(),
      onlinedate: new Date(),
      description: "",
    },
  });

  // Form values for adding state values

  // For Richtext editor
  const [value2, setValue2] = useState("");

  const editor2 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html2 = editor.getHTML();
      form.setFieldValue("description", html2);
      editform.setFieldValue("description", html2);
      setValue2(html2);
    },
  });

  // Adding State values
  const handleAdd = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    setLoadingState(true);
    const response = await handleAddGovernmentJobs(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Government Job added successfully",
      });
      form.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setLoadingState(false);
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false, addDrawer: false });
    }
  };

  //For delete confirm modal show Delete
  const openConfirmModalDelete = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the government job{" "}
          <b>{datas.title}</b> ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  //   For delete db data from table and db
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteGovernmentJobs(e);
    // Check the response for notification and actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Government Job deleted successfully",
      });
      var filter = variables.data;
      filter = filter.filter((img) => img.value !== e);
      setVariables({
        ...variables,
        data: response.data.data.reverse(),
        skeletonLoading: false,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // For editing and settings the value of state
  const handleEdit = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    editform.setFieldValue("value", datas.id);
    editform.setFieldValue("type", datas.type);
    {
      datas.govt_department_id !== null
        ? editform.setFieldValue(
            "department",
            datas.govt_department_id.toString()
          )
        : editform.setFieldValue("department", null);
    }
    {
      datas.title !== null
        ? editform.setFieldValue("title", datas.title)
        : editform.setFieldValue("title", null);
    }
    {
      datas.no_of_post !== null
        ? editform.setFieldValue("postno", datas.no_of_post)
        : editform.setFieldValue("postno", null);
    }
    {
      datas.salary !== null
        ? editform.setFieldValue("salary", datas.salary)
        : editform.setFieldValue("salary", null);
    }
    {
      datas.qualification !== null
        ? editform.setFieldValue("qualification", datas.qualification)
        : editform.setFieldValue("qualification", null);
    }
    {
      datas.age_limit !== null
        ? editform.setFieldValue("agelimit", datas.age_limit)
        : editform.setFieldValue("agelimit", null);
    }
    {
      datas.gender !== null
        ? editform.setFieldValue("gender", datas.gender)
        : editform.setFieldValue("gender", null);
    }
    {
      datas.job_location !== null
        ? editform.setFieldValue("joblocation", datas.job_location)
        : editform.setFieldValue("joblocation", null);
    }
    {
      datas.last_date !== null
        ? editform.setFieldValue("lastdate", new Date(datas.last_date))
        : editform.setFieldValue("lastdate", null);
    }
    {
      datas.last_date_online !== null
        ? editform.setFieldValue("onlinedate", new Date(datas.last_date_online))
        : editform.setFieldValue("onlinedate", null);
    }
    {
      datas.description !== null
        ? editform.setFieldValue("description", datas.description)
        : editform.setFieldValue("description", null);
    }

    {
      datas.state_id !== null
        ? editform.setFieldValue("state", datas.state_id.toString())
        : editform.setFieldValue("state", null);
    }
    editor2.commands.setContent(datas.description);
    setVariables({ ...variables, deleteIndex: e, openEdit: true });
  };

  // Edit function for updating state values
  const handleEdits = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    setLoadingState(true);
    const response = await handleEditGovernmentJobs(e);
    // Check for response data for actions
    console.log(response.data.data);
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Government Job updated successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        openEdit: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setLoadingState(false);
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false, openEdit: false });
    }
  };

  const [id_value, setIdValue] = useState();
  const [imageTitle, setImageTitle] = useState();
  const [profileimage, SetProfileimage] = useState("");
  const [loadingState, setLoadingState] = useState(false);

  // Crop Image
  const openRef = useRef();
  const [image, setImage] = useState("");
  const [upImg, setUpImg] = useState("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1 / 1);
  const [cropConfig, setCropConfig] = useState({
    unit: "%",
    width: 50,
    aspect: 9 / 16,
  });
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: 24 / 12,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const ref = useRef();
  const ref45 = useRef();
  const changeHandler = (e) => {
    if (e) {
      console.log(e);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e);
      ref.current.value = "";
    }
  };
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        const TO_RADIANS = Math.PI / 180;
        const image = imgRef.current;
        const crop = completedCrop;
        const canvas = previewCanvasRef.current;
        const scale = 1;
        const rotate = 1;

        const ctx = canvas.getContext("2d");

        if (!ctx) {
          throw new Error("No 2d context");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio;
        // const pixelRatio = 1

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = "high";

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        const rotateRads = rotate * TO_RADIANS;
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;

        ctx.save();

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY);
        // 3) Rotate around the origin
        ctx.rotate(rotateRads);
        // 2) Scale the image
        ctx.scale(scale, scale);
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY);
        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight
        );
        ctx.restore();
        const base64Image = canvas.toDataURL("image/jpeg");
        setImage(base64Image);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  // Table data arrange by using function and loop throw each data range to table body
  const rows = sortedData.map((row, index) => (
    <tr key={row.title}>
      <td>{variables.data.length - activePage * total + total - index}</td>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          <div>
            <Text fz="sm" fw={500}>
              <Anchor
                onClick={() => handleEdit(row.id)}
                component="button"
                size="sm"
              >
                {row.title}
              </Anchor>
            </Text>
          </div>
        </Group>
      </td>
      {row.govt_department_type !== null && row.govt_department_type !== "" ? (
        <td style={{ width: 250 }}>{row.govt_department_type.name}</td>
      ) : (
        <td style={{ width: 250 }}>-</td>
      )}

      {row.last_date !== null && row.last_date !== "" ? (
        <td style={{ width: 250 }}>
          {new Date(row.last_date).toLocaleDateString("en-GB")}
        </td>
      ) : (
        <td style={{ width: 250 }}>-</td>
      )}

      {row.last_date_online !== null && row.last_date_online !== "" ? (
        <td style={{ width: 250 }}>
          {new Date(row.last_date_online).toLocaleDateString("en-GB")}
        </td>
      ) : (
        <td style={{ width: 250 }}>-</td>
      )}
      <td>{row.salary}</td>
      <td>{row.age_limit}</td>
      <td>{row.gender}</td>
      <td>{row.job_location}</td>
      <td>
        <Group spacing={0} position="left">
          <ActionIcon onClick={() => handleEdit(row.id)}>
            <Pencil size={18} color="gray" />
          </ActionIcon>
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.id)}
            color="red"
          >
            <Trash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  // Gender dropdown data
  const Genderdata = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Both", label: "Both" },
    { value: "Others", label: "Others" },
  ];
  const [gender, setGender] = useState("");

  return (
    <div>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb
          Text="Government Jobs"
          Title="Settings"
          titleLink="/settings"
        />
      </Skeleton>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card className="border">
          <ScrollArea>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* For search the table data input forms */}
              <TextInput
                variant="filled"
                placeholder="Search"
                mb="md"
                size="sm"
                value={search}
                icon={<Search size={16} />}
                onChange={async (e) => {
                  // On change search ofr the data that is enter
                  setSearch(e.currentTarget.value);
                  setPage(1);
                  const datas = await dataSearch({
                    data: variables.data,
                    value: e.currentTarget.value,
                    activePage: activePage,
                    total: total,
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                sx={{ width: 250 }}
              />
              <div>
                <Group spacing="xs">
                  {/* For export the the table data to pdf and excels */}
                  <Text>Exports :</Text>
                  <CSVLink
                    data={variables.data}
                    headers={[
                      { label: "id", key: "id" },
                      { label: "title", key: "title" },
                      {
                        label: "govt_department_id",
                        key: "govt_department_id",
                      },
                      { label: "description", key: "description" },
                      { label: "no_of_post", key: "no_of_post" },
                      { label: "salary", key: "salary" },
                      { label: "qualification", key: "qualification" },
                      { label: "age_limit", key: "age_limit" },
                      { label: "gender", key: "gender" },
                      { label: "job_location", key: "job_location" },
                      { label: "last_date", key: "last_date" },
                      { label: "last_date_online", key: "last_date_online" },
                      { label: "state_id", key: "state_id" },
                      { label: "type", key: "type" },
                      { label: "createdAt", key: "createdAt" },
                      { label: "updatedAt", key: "updatedAt" },
                    ]}
                    filename="jobs.csv"
                    className={classes.pdfExcel}
                  >
                    <img
                      src={excel}
                      alt="excel"
                      width="25"
                      style={{ margin: "2px" }}
                    />
                  </CSVLink>
                  <button
                    className={classes.pdfExcel}
                    onClick={() =>
                      print({
                        name: "Jobs",
                        data: variables.data,
                        list: [
                          "id",
                          "title",
                          "govt_department_id",
                          "description",
                          "no_of_post",
                          "salary",
                          "qualification",
                          "age_limit",
                          "gender",
                          "job_location",
                          "last_date",
                          "last_date_online",
                          "job_description",
                          "education_description",
                          "age_description",
                          "age_relaxation",
                          "application_fee",
                          "state_id",
                          "type",
                          "createdAt",
                          "updatedAt",
                        ],
                        header: [
                          "id",
                          "title",
                          "govt_department_id",
                          "description",
                          "no_of_post",
                          "salary",
                          "qualification",
                          "age_limit",
                          "gender",
                          "job_location",
                          "last_date",
                          "last_date_online",
                          "job_description",
                          "education_description",
                          "age_description",
                          "age_relaxation",
                          "application_fee",
                          "state_id",
                          "type",
                          "createdAt",
                          "updatedAt",
                        ],
                      })
                    }
                  >
                    <img
                      src={pdf}
                      alt="pdf"
                      width="19"
                      style={{ margin: "2px" }}
                    />
                  </button>
                  {/* Drawer open for adding new tax data */}
                  <Button
                    variant="outline"
                    color="orange"
                    size="xs"
                    onClick={() =>
                      setVariables({ ...variables, addDrawer: true })
                    }
                  >
                    + Add Government Jobs
                  </Button>
                </Group>
              </div>
            </div>
            {/* className={classes.striped} */}
            <Table verticalSpacing="sm">
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Job Title</th>
                  <th>Department</th>
                  <th>Last Date</th>
                  <th>Last Online Date</th>
                  <th>Salary</th>
                  <th>Age limit</th>
                  <th>Gender</th>
                  <th>Job location</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {rows.length > 0 ? (
                <tbody>{rows}</tbody>
              ) : (
                <>
                  {loadingData === false ? (
                    <tbody>
                      <tr>
                        <td colSpan={4}>
                          <div style={{ textAlign: "center" }}>
                            <Loader size="xl" color="orange" variant="dots" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={4}>
                          <div style={{ textAlign: "center" }}>
                            <Text weight={500} align="center">
                              Nothing found
                            </Text>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </ScrollArea>
          {/* For display the pagination and no of per pages list */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 15,
            }}
          >
            {/* For pagination */}
            <Pagination
              withEdges
              size="sm"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="orange"
            />
          </div>
        </Card>
      </Skeleton>
      {/* Scheme Add Modal */}
      <Modal
        opened={variables.addDrawer}
        onClose={() => setVariables({ ...variables, addDrawer: false })}
        title={"Add New Government Job"}
        size="70%"
        scrollAreaComponent={Modal.NativeScrollArea}
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <form onSubmit={form.onSubmit((values) => handleAdd(values))}>
          <Grid>
            <Grid.Col md={4} lg={4}>
              <Select
                searchable
                clearable
                required
                withAsterisk
                label="Select Type"
                placeholder="Select Type"
                data={[
                  { value: "Union", label: "Union" },
                  { value: "State", label: "State" },
                ]}
                {...form.getInputProps("type")}
              />
            </Grid.Col>
            {form.values.type == "State" ? (
              <Grid.Col md={4} lg={4}>
                <Select
                  searchable
                  clearable
                  required
                  withAsterisk
                  label="Select State"
                  placeholder="Select State"
                  data={state}
                  {...form.getInputProps("state")}
                />
              </Grid.Col>
            ) : null}
            <Grid.Col md={4} lg={4}>
              <Select
                label="Select Department"
                searchable
                clearable
                required
                withAsterisk
                placeholder="Select Department"
                data={department.filter((raw) => {
                  if (Number(form.values.state) != "") {
                    return Number(raw.state_id) === Number(form.values.state);
                  }
                  return true;
                })}
                {...form.getInputProps("department")}
              />
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              <TextInput
                label="Job Title"
                required
                withAsterisk
                placeholder="Job Title"
                {...form.getInputProps("title")}
              />
            </Grid.Col>

            <Grid.Col md={4} lg={4}>
              <TextInput
                label="No. Of Post Available"
                required
                withAsterisk
                placeholder="No. Of Post Available"
                {...form.getInputProps("postno")}
              />
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              <TextInput
                label="Salary"
                required
                withAsterisk
                placeholder="Salary"
                {...form.getInputProps("salary")}
              />
            </Grid.Col>

            <Grid.Col md={4} lg={4}>
              <TextInput
                label="Age limit"
                required
                withAsterisk
                placeholder="Age limit"
                {...form.getInputProps("agelimit")}
              />
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              <Select
                label="Gender"
                searchable
                clearable
                required
                withAsterisk
                data={Genderdata}
                placeholder="Gender"
                {...form.getInputProps("gender")}
              />
            </Grid.Col>

            <Grid.Col md={4} lg={4}>
              <TextInput
                label="Job Location"
                required
                withAsterisk
                placeholder="Job Location"
                {...form.getInputProps("joblocation")}
              />
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              <DatePickerInput
                placeholder="last date"
                label="last date"
                required
                withAsterisk
                {...form.getInputProps("lastdate")}
              />
            </Grid.Col>

            <Grid.Col md={4} lg={4}>
              <DatePickerInput
                placeholder="Online last date"
                label="Online last date"
                required
                withAsterisk
                {...form.getInputProps("onlinedate")}
              />
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              <TextInput
                label="Qualification"
                required
                withAsterisk
                placeholder="Qualification"
                {...form.getInputProps("qualification")}
              />
            </Grid.Col>
            <Grid.Col md={12} lg={12}>
              <Text mt={10} size="xs">
                Description
              </Text>
              <RichTextEditor editor={editor2}>
                <RichTextEditor.Toolbar sticky stickyOffset={60}>
                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Bold />
                    <RichTextEditor.Italic />
                    <RichTextEditor.Underline />
                    <RichTextEditor.Strikethrough />
                    <RichTextEditor.ClearFormatting />
                    <RichTextEditor.Highlight />
                    <RichTextEditor.Code />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.H1 />
                    <RichTextEditor.H2 />
                    <RichTextEditor.H3 />
                    <RichTextEditor.H4 />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Blockquote />
                    <RichTextEditor.Hr />
                    <RichTextEditor.BulletList />
                    <RichTextEditor.OrderedList />
                    <RichTextEditor.Subscript />
                    <RichTextEditor.Superscript />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Link />
                    <RichTextEditor.Unlink />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.AlignLeft />
                    <RichTextEditor.AlignCenter />
                    <RichTextEditor.AlignJustify />
                    <RichTextEditor.AlignRight />
                  </RichTextEditor.ControlsGroup>
                </RichTextEditor.Toolbar>

                <RichTextEditor.Content
                  value={value2}
                  mb={20}
                  {...form.getInputProps("description")}
                />
              </RichTextEditor>
            </Grid.Col>
            <Grid.Col md={12} lg={12}>
              <Button
                loading={loadingState}
                fullWidth
                radius="md"
                type="submit"
                size="md"
                variant="gradient"
                gradient={{ from: "orange", to: "red" }}
              >
                Add Job
              </Button>
            </Grid.Col>
          </Grid>
        </form>
      </Modal>
      {/* // State Add MOdal */}
      {/* Edit Modal */}
      <Modal
        opened={variables.openEdit}
        onClose={() => setVariables({ ...variables, openEdit: false })}
        title={"Update Government Job Details"}
        size="70%"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <Modal.Body style={{ zIndex: -100 }}>
          <Group>
            <form onSubmit={editform.onSubmit((values) => handleEdits(values))}>
              <Grid>
                <Grid.Col md={4} lg={4}>
                  <Select
                    searchable
                    clearable
                    required
                    withAsterisk
                    label="Select Type"
                    placeholder="Select Type"
                    data={[
                      { value: "Union", label: "Union" },
                      { value: "State", label: "State" },
                    ]}
                    {...editform.getInputProps("type")}
                  />
                </Grid.Col>

                {editform.values.type == "State" ? (
                  <Grid.Col md={4} lg={4}>
                    <Select
                      searchable
                      clearable
                      required
                      withAsterisk
                      label="Select State"
                      placeholder="Select State"
                      data={state}
                      {...editform.getInputProps("state")}
                    />
                  </Grid.Col>
                ) : null}
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Select Department"
                    searchable
                    clearable
                    required
                    withAsterisk
                    placeholder="Select Department"
                    data={department}
                    {...editform.getInputProps("department")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Job Title"
                    placeholder="Job Title"
                    required
                    withAsterisk
                    {...editform.getInputProps("title")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Post no"
                    placeholder="Post no"
                    required
                    withAsterisk
                    {...editform.getInputProps("postno")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Salary"
                    placeholder="Salary"
                    required
                    withAsterisk
                    {...editform.getInputProps("salary")}
                  />
                </Grid.Col>

                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Age limit"
                    placeholder="Age limit"
                    required
                    withAsterisk
                    {...editform.getInputProps("agelimit")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Gender"
                    searchable
                    clearable
                    required
                    withAsterisk
                    data={Genderdata}
                    placeholder="Gender"
                    {...editform.getInputProps("gender")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Job Location"
                    placeholder="Job Location"
                    required
                    withAsterisk
                    {...editform.getInputProps("joblocation")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <DatePickerInput
                    placeholder="last date"
                    label="last date"
                    required
                    withAsterisk
                    {...editform.getInputProps("lastdate")}
                  />
                </Grid.Col>

                <Grid.Col md={4} lg={4}>
                  <DatePickerInput
                    placeholder="Online last date"
                    label="Online last date"
                    required
                    withAsterisk
                    {...editform.getInputProps("onlinedate")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Qualification"
                    placeholder="Qualification"
                    required
                    withAsterisk
                    {...editform.getInputProps("qualification")}
                  />
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Text mt={10} size="xs">
                    Description
                  </Text>
                  <RichTextEditor editor={editor2}>
                    <RichTextEditor.Toolbar sticky stickyOffset={60}>
                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Bold />
                        <RichTextEditor.Italic />
                        <RichTextEditor.Underline />
                        <RichTextEditor.Strikethrough />
                        <RichTextEditor.ClearFormatting />
                        <RichTextEditor.Highlight />
                        <RichTextEditor.Code />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.H1 />
                        <RichTextEditor.H2 />
                        <RichTextEditor.H3 />
                        <RichTextEditor.H4 />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Blockquote />
                        <RichTextEditor.Hr />
                        <RichTextEditor.BulletList />
                        <RichTextEditor.OrderedList />
                        <RichTextEditor.Subscript />
                        <RichTextEditor.Superscript />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Link />
                        <RichTextEditor.Unlink />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.AlignLeft />
                        <RichTextEditor.AlignCenter />
                        <RichTextEditor.AlignJustify />
                        <RichTextEditor.AlignRight />
                      </RichTextEditor.ControlsGroup>
                    </RichTextEditor.Toolbar>

                    <RichTextEditor.Content
                      value={value2}
                      mb={20}
                      {...editform.getInputProps("description")}
                    />
                  </RichTextEditor>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Button
                    loading={loadingState}
                    fullWidth
                    radius="md"
                    type="submit"
                    size="md"
                    variant="gradient"
                    gradient={{ from: "orange", to: "red" }}
                  >
                    Update Job
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          </Group>
        </Modal.Body>
      </Modal>
      {/* Edit Modal */}
    </div>
  );
}

export default Jobs;
