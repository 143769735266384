/**
 * Application Name: JanSarkar(Mantine Version 6.0.1)
 * Author: Zevcore Private Limited
 * Starting date: 14.03.2023
 * Developer Name: Anand Krishna
 * Last modified date: 14.03.2023
 * Last modified by: Anand Krishna
 **/

import {
  ActionIcon,
  AppShell,
  Burger,
  ColorSchemeProvider,
  createStyles,
  getStylesRef,
  Group,
  Header,
  MantineProvider,
  MediaQuery,
  Menu,
  Navbar,
  ScrollArea,
  Text,
  TextInput,
  useMantineTheme,
  rem,
  Indicator,
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { ModalsProvider } from "@mantine/modals";
import { openConfirmModal } from "@mantine/modals";
import { SpotlightProvider, useSpotlight } from "@mantine/spotlight";
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  Ad,
  AdjustmentsHorizontal,
  ArrowLeft,
  ArrowRight,
  Bell,
  Flag,
  Friends,
  GitPullRequest,
  Home,
  Logout,
  Map,
  MoonStars,
  News,
  QuestionMark,
  Quote,
  Search,
  Settings,
  Speakerphone,
  Sun,
  User,
  Users,
} from "tabler-icons-react";
import Logo from "../../assets/images/logo.gif";
import { LinksGroup } from "../Sidebar/Navbarlink";
// Sidebar styles
const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.gray,
    paddingBottom: 200,
    height: "auto",
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    color: theme.colorScheme === "dark" ? theme.gray : theme.black,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

// Spotlight routes

function Sidebar({ children }) {
  // Sidebar array data
  const mockdata = [
    { label: "Dashboard", icon: Home, link: "/" },
    {
      label: "Constituency",
      icon: Map,
      initiallyOpened: false,
      links: [
        { label: "Loksabha(MP)", link: "/loksabha" },
        { label: "Vidhansabha(MLA)", link: "/assembly" },
        { label: "Corporation wards", link: "/corporation" },
        { label: "GP wards", link: "/Gpward" },
      ],
    },

    { label: "Political Party", icon: Flag, link: "/party" },
    { label: "Aspirants", icon: Friends, link: "/aspirants" },
    {
      label: "Representatives",
      icon: Users,
      initiallyOpened: false,
      links: [
        { label: "MP", link: "/Mp" },
        { label: "MLA", link: "/Mla" },
        { label: "Corporator", link: "/corporator" },
        { label: "GP Member", link: "/Gp" },
        { label: "Add Representative", link: "/representative" },
      ],
    },
    { label: "Complaints", icon: QuestionMark, link: "/complaints" },

    {
      label: "Lessons",
      icon: News,
      initiallyOpened: false,
      links: [
        { label: "Lesson Banners", link: "/lesson_banner" },
        { label: "Lessons", link: "/lessons" },
        { label: "Lesson Category", link: "/lesson_category" },
      ],
    },
    { label: "Regular Users", icon: User, link: "/user" },
    {
      label: "Govt Ministry",
      icon: AdjustmentsHorizontal,
      link: "/govt_ministry",
    },
    { label: "Jobs Department", icon: AdjustmentsHorizontal, link: "/govt" },
    { label: "Jobs", icon: Search, link: "/jobs" },
    {
      label: "Scheme Department",
      icon: AdjustmentsHorizontal,
      link: "/scheme_department",
    },
    { label: "Schemes", icon: Ad, link: "/scheme" },
    { label: "Announcement", icon: Speakerphone, link: "/announcement" },
    { label: "Quotes", icon: Quote, link: "/quote" },
    { label: "Feedback", icon: GitPullRequest, link: "/feedback" },
    { label: "Settings", icon: Settings, link: "/settings" },
  ];

  // Spotlight array data
  const actions = [
    {
      title: "Dashboard",
      description: "Get full information about current system status",
      onTrigger: () => navigate("/"),
    },
    {
      title: "Loksabha Constituency",
      description: "Get full information about Assembly Constituency",
      onTrigger: () => navigate("/district"),
    },
    {
      title: "Assembly Constituency",
      description: "Get full information about Assembly Constituency",
      onTrigger: () => navigate("/assembly"),
    },
    {
      title: "GP wards",
      description: "Get full information about Gp wards",
      onTrigger: () => navigate("/Gpward"),
    },
    {
      title: "MP",
      description: "Get full information about Mp",
      onTrigger: () => navigate("/Mp"),
    },
    {
      title: "MLA",
      description: "Get full information about Mla",
      onTrigger: () => navigate("/Mla"),
    },
    {
      title: "Corporator",
      description: "Get full information about Corporator",
      onTrigger: () => navigate("/corporator"),
    },
    {
      title: "Complaints",
      description: "Get full information about Complaints",
      onTrigger: () => navigate("/complaints"),
    },
    {
      title: "Lessons",
      description: "Get full information about Lessons",
      onTrigger: () => navigate("/lessons"),
    },
    {
      title: "Regular Users",
      description: "Get full information about Regular Users",
      onTrigger: () => navigate("/user"),
    },
    {
      title: "Jobs Department",
      description: "Get full information about Jobs Department",
      onTrigger: () => navigate("/govt"),
    },
    {
      title: "Jobs",
      description: "Get full information about Jobs",
      onTrigger: () => navigate("/jobs"),
    },
    {
      title: "Scheme",
      description: "Get full information about Scheme",
      onTrigger: () => navigate("/scheme"),
    },
    {
      title: "Announcement",
      description: "Get full information about Announcement",
      onTrigger: () => navigate("/announcement"),
    },
    {
      title: "Quotes",
      description: "Get full information about Quotes",
      onTrigger: () => navigate("/quote"),
    },
    {
      title: "Settings",
      description: "Get full information about Settings",
      onTrigger: () => navigate("/settings"),
    },
  ];
  // Variables for sidebar
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();
  const { classes, cx } = useStyles();
  const [colorScheme, setColorScheme] = useLocalStorage({
    key: "mantine-color-scheme",
    defaultValue: "light",
  });
  // toggle function for dark and light mode
  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  let navigate = useNavigate();
  const links = mockdata.map((item) => (
    <LinksGroup {...item} key={item.label} />
  ));
  const dark = colorScheme === "dark";

  function SpotlightControl(props) {
    const theme = useMantineTheme();
    const spotlight = useSpotlight();
    return (
      <Group position="center">
        <TextInput
          icon={<Search size={18} />}
          radius="xl"
          size="md"
          rightSection={
            <ActionIcon
              size={32}
              radius="xl"
              color={dark ? "yellow" : "orange"}
              variant="filled"
            >
              {theme.dir === "ltr" ? (
                <ArrowRight size={18} />
              ) : (
                <ArrowLeft size={18} />
              )}
            </ActionIcon>
          }
          placeholder="Search "
          sx={{ marginLeft: 60 }}
          onClick={spotlight.openSpotlight}
          rightSectionWidth={42}
          {...props}
        />
      </Group>
    );
  }

  // Logout function
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    // For light and dark mode toggle color
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{
          fontFamily: "Poppins",
          fontWeight: 300,
          colorScheme,
          colors: {
            orange: [
              "rgba(252, 226, 210, 0.2)",
              "#FF9248",
              "#FF9248",
              "#FF9248",
              "#FF9248",
              "#FF9248",
              "#FF9248",
              "#FF9248",
              "#FF9248",
              "#FF9248",
            ],
          },
        }}
      >
        {/* Notifications provider for notification component */}
        {/* <NotificationsProvider autoClose={3000}> */}
        <ModalsProvider>
          <AppShell
            // navbarOffsetBreakpoint controls when navbar should no longer be offset with padding-left
            navbarOffsetBreakpoint="sm"
            // fixed prop on AppShell will be automatically added to Header and Navbar
            styles={(theme) => ({
              main: {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[8]
                    : theme.colors.gray[0],
              },
            })}
            fixed
            // Navbar section
            navbar={
              <Navbar
                height={850}
                width={{ sm: 300 }}
                p="md"
                className={classes.navbar}
              >
                <Navbar.Section
                  grow
                  className={classes.links}
                  component={ScrollArea}
                >
                  <div className={classes.linksInner}>{links}</div>
                </Navbar.Section>
              </Navbar>
            }
            // Header section
            header={
              <Header height={70} p="md">
                {/* Handle other responsive styles with MediaQuery component or createStyles function */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  {/* Mediaquery for small screens */}
                  <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                    <Burger
                      opened={opened}
                      onClick={() => setOpened((o) => !o)}
                      size="sm"
                      color={theme.colors.gray[6]}
                      mr="xl"
                    />
                  </MediaQuery>
                  {/* Logo and Text */}
                  <div style={{ display: "flex" }}>
                    <img
                      src={Logo}
                      alt="logo"
                      style={{ width: 45, height: 45 }}
                    ></img>
                    <Text
                      mt={8}
                      sx={{
                        color: dark ? "#FFFFFF" : "#3d3d3d",
                        fontWeight: "Bold",
                        fontFamily: "Poppins",
                      }}
                      size="xl"
                    >
                      JanSarkar
                    </Text>
                  </div>
                  {/* Spotlight provider for search tab */}
                  <SpotlightProvider
                    actions={actions}
                    searchIcon={<Search size={18} />}
                    searchPlaceholder="Search..."
                    shortcut={["mod + P", "mod + K", "/"]}
                    nothingFoundMessage="Nothing found..."
                  >
                    <SpotlightControl />
                  </SpotlightProvider>
                  {/* Dark and light mode */}
                  {/* Bell icon for depletion indicated */}

                  <ActionIcon
                    variant="outline"
                    mr={5}
                    color={dark ? "yellow" : "orange"}
                    onClick={() => toggleColorScheme()}
                    title="Toggle color scheme"
                    style={{ marginLeft: "auto" }}
                  >
                    {dark ? <Sun size={18} /> : <MoonStars size={18} />}
                  </ActionIcon>
                  <ActionIcon
                    color={dark ? "yellow" : "orange"}
                    variant="outline"
                    mr={5}
                    // onClick={() => setNotificationDrawer(!notificationDrawer)}
                    title="Notification"
                  >
                    <Indicator color="red">
                      <Bell strokeWidth="0.8" size={18} />
                    </Indicator>
                  </ActionIcon>
                  <Menu
                    variant="outline"
                    color={dark ? "yellow" : "orange"}
                    shadow="md"
                    width={200}
                  >
                    <Menu.Target>
                      <ActionIcon
                        color={dark ? "yellow" : "orange"}
                        type="button"
                        title="Setting"
                        variant="outline"
                      >
                        <Settings strokeWidth="0.8" size={20} />
                      </ActionIcon>
                    </Menu.Target>
                    {/* Profile setting page */}
                    <Menu.Dropdown>
                      <Menu.Item
                        icon={<Settings size={14} />}
                        onClick={() => navigate("/settings")}
                      >
                        Settings
                      </Menu.Item>
                      {/* For logout button */}
                      <Menu.Item
                        // icon={<Logout size={14} />}
                        // color="red"
                        // onClick={handleLogout}
                        onClick={() => {
                          openConfirmModal({
                            title: "Confirm Logout ",
                            children: (
                              <Text size="sm">
                                This action is so important that you are
                                required to confirm logout. Please click one of
                                these buttons to proceed.
                              </Text>
                            ),

                            labels: {
                              confirm: "Confirm",
                              cancel: "Cancel",
                            },
                            confirmProps: { color: "red" },
                            onCancel: () => console.log("Cancel"),
                            onConfirm: () => {
                              localStorage.clear();
                              navigate("/login");
                            },
                          });
                        }}
                        color="red"
                        icon={<Logout size={14} />}
                      >
                        Logout
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </div>
              </Header>
            }
          >
            {/* Outlet is used as children for wrapping the sidebar around the children components */}
            <Outlet />
          </AppShell>
        </ModalsProvider>
        {/* </NotificationsProvider> */}
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default Sidebar;
