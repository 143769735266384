/**
 * Application Name: JanSarkar(Mantine Version 6.0.1)
 * Author: Zevcore Private Limited
 * Starting date: 14.03.2023
 * Developer Name: Anand Krishna
 * Last modified date: 15.03.2023
 * Last modified by: Anand Krishna
 **/

import {
  ActionIcon,
  Avatar,
  Anchor,
  Button,
  Card,
  Grid,
  Group,
  Loader,
  Menu,
  Modal,
  NativeSelect,
  NumberInput,
  Pagination,
  ScrollArea,
  Skeleton,
  Table,
  Text,
  TextInput,
  useMantineTheme,
  Paper,
  FileButton,
  Divider,
  Select,
} from "@mantine/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import {
  CloudUpload,
  Dots,
  Download,
  Pencil,
  Photo,
  Search,
  Trash,
  X,
} from "tabler-icons-react";
import { CSVLink } from "react-csv";
// Image
import excel from "../../assets/images/excel.png";
import pdf from "../../assets/images/pdf.png";
import BreadCrumb from "../../components/Breadcrumbs/BreadCrumbs";
import {
  dataSlice,
  getActiveColor,
  imageModal,
  selectFilter,
  onDownload,
  pageModal,
} from "../../helpers/common";
import notificationHelper from "../../helpers/Notification"; // Import notification for this page
import { Th, dataSearch, setSorting, print } from "../../helpers/tableFunction"; // For table data functions
import useStyles from "../../components/Styles/style"; // Import the style from
import {
  handleAddDepartment,
  handleAddGovernmentMinistry,
  handleDeleteGovernmentMinistry,
  handleEditGovernmentMinistry,
  handleGetGovernmentMinistry,
  handleGetRepresentative,
  handleGetState,
  handleUploadRepresentativeImage,
} from "../../helpers/Apis";
import { modals } from "@mantine/modals";
import { useForm } from "@mantine/form";
import { URL_CONSTANTS } from "../../constants/constants";
import { useDebounceEffect } from "../../common/userDebounceEffect.ts";
// for image crop
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import {
  Dropzone,
  DropzoneStatus,
  MIME_TYPES,
  IMAGE_MIME_TYPE,
} from "@mantine/dropzone";
import "react-image-crop/dist/ReactCrop.css";
// RichtextEditor

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 10, // make it 10 for smaller crop ratio
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function GovernmentMinistry() {
  const { classes } = useStyles();
  const [search, setSearch] = useState("");
  const [opened, setOpened] = useState(false);
  const [openedBulk, setOpenedBulk] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [state, SetState] = useState([]);
  const [state2, SetState2] = useState([]);
  const [representative, setRepresentative] = useState([]);
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const theme = useMantineTheme();
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table

  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: true,
    submitLoading: false,
    data: [],
    addDrawer: false,
    bulkDrawer: false,
    imageDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });

  //   For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        //   For get all the state data list
        const response = await handleGetGovernmentMinistry();
        console.log(response.data.data);
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data.reverse(),
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setSortedData(datas);
        }

        const response1 = await handleGetState();
        console.log(response1.data.data);
        if (response1.status === 200) {
          var data = response1.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.name.toString(),
            id: data.id,
            name: data.name,
          }));
          SetState(clean);
        }

        const response2 = await handleGetRepresentative();
        console.log(response2.data.data);
        if (response2.status === 200) {
          var data = response2.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.name.toString(),
            id: data.id,
            name: data.name,
          }));
          setRepresentative(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // form values for adding government department data
  const form = useForm({
    initialValues: {
      state_id: "",
      department: "",
      representative_id: "",
      from: new Date(),
      type: "",
    },
  });

  // Edit Form Values for updating Government Job data
  const editForm = useForm({
    initialValues: {
      state_id: "",
      department: "",
      representative_id: "",
      from: new Date(),
      type: "",
    },
  });

  // Form values for adding state values
  const formImage = useForm({
    initialValues: {
      value: "",
      image: "",
    },
  });

  // Adding State values
  const handleAdd = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    setLoadingState(true);
    const response = await handleAddGovernmentMinistry(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Ministry added successfully",
      });
      form.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setLoadingState(false);
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false, addDrawer: false });
    }
  };

  //For delete confirm modal show Delete
  const openConfirmModalDelete = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the ministry{" "}
          <b>{datas.representative.name}</b> ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  //   For delete db data from table and db
  const handleConfirmDelete = async (e) => {
    setLoadingState(true);
    const response = await handleDeleteGovernmentMinistry(e);
    // Check the response for notification and actions

    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Ministry deleted successfully",
      });
      var filter = variables.data;
      filter = filter.filter((img) => img.value !== e);
      setVariables({
        ...variables,
        data: response.data.data.reverse(),
        skeletonLoading: false,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setLoadingState(false);
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // For editing and settings the value of state
  const handleEdit = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    editForm.setFieldValue("value", datas.id);
    editForm.setFieldValue(
      "state_id",
      datas.state_id != null ? datas.state_id.toString() : ""
    );
    editForm.setFieldValue("department", datas.department);
    editForm.setFieldValue("type", datas.type.toString());
    editForm.setFieldValue("from", new Date(datas.from));
    editForm.setFieldValue(
      "representative_id",
      datas.representative_id.toString()
    );
    setVariables({ ...variables, deleteIndex: e, openEdit: true });
  };

  // Edit function for updating state values
  const handleEdits = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditGovernmentMinistry(e);
    // Check for response data for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Ministry updated successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        openEdit: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false, openEdit: false });
    }
  };

  const [id_value, setIdValue] = useState();
  const [imageTitle, setImageTitle] = useState();
  const [profileimage, SetProfileimage] = useState("");
  const [loadingState, setLoadingState] = useState(false);

  const openUpload = (e) => {
    setVariables({ ...variables, submitLoading: true });
    var datas = variables.data.find((img) => img.id === e);
    setImageTitle(datas.representative.name);
    SetProfileimage(datas.representative.image);
    setIdValue(datas.representative.id);
    setVariables({ ...variables, deleteIndex: e, imageDrawer: true });
  };

  // Adding State values
  const handleUpload = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    var req = {
      image: image,
      value: id_value,
    };
    e = { ...e, ...req };

    const response = await handleUploadRepresentativeImage(e);
    // Check for response for actions

    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Ministry Image upload successfully",
      });
      setUpImg("");
      setImage("");
      setVariables({
        ...variables,
        submitLoading: false,
        imageDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
    }
  };

  // Crop Image
  const openRef = useRef();
  const [image, setImage] = useState("");
  const [upImg, setUpImg] = useState("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(16 / 9);
  const [cropConfig, setCropConfig] = useState({
    unit: "%",
    width: 50,
    aspect: 9 / 16,
  });
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: 24 / 12,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const ref = useRef();
  const ref45 = useRef();
  const changeHandler = (e) => {
    if (e) {
      console.log(e);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e);
      ref.current.value = "";
    }
  };
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        const TO_RADIANS = Math.PI / 180;
        const image = imgRef.current;
        const crop = completedCrop;
        const canvas = previewCanvasRef.current;
        const scale = 1;
        const rotate = 1;

        const ctx = canvas.getContext("2d");

        if (!ctx) {
          throw new Error("No 2d context");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio;
        // const pixelRatio = 1

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = "high";

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        const rotateRads = rotate * TO_RADIANS;
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;

        ctx.save();

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY);
        // 3) Rotate around the origin
        ctx.rotate(rotateRads);
        // 2) Scale the image
        ctx.scale(scale, scale);
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY);
        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight
        );
        ctx.restore();
        const base64Image = canvas.toDataURL("image/jpeg");
        setImage(base64Image);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  // Table data arrange by using function and loop throw each data range to table body
  const rows = sortedData.map((row, index) => (
    <tr key={row.name}>
      <td>{variables.data.length - activePage * total + total - index}</td>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.representative.image != "" ? (
            <Avatar
              onClick={() => {
                imageModal({
                  data:
                    URL_CONSTANTS.REPRESENTATIVE_IMAGE +
                    row.representative.image,
                  title: row.representative.name,
                });
              }}
              radius={30}
              size={30}
              src={
                URL_CONSTANTS.REPRESENTATIVE_IMAGE + row.representative.image
              }
            />
          ) : (
            <Avatar
              onClick={() => {
                imageModal({
                  data:
                    URL_CONSTANTS.REPRESENTATIVE_IMAGE +
                    row.representative.image,
                  title: row.representative.name,
                });
              }}
              size={20}
              src={
                URL_CONSTANTS.REPRESENTATIVE_IMAGE + row.representative.image
              }
            />
          )}

          <div>
            <Text fz="sm" fw={500}>
              <Anchor
                onClick={() => handleEdit(row.id)}
                component="button"
                size="sm"
              >
                {row.representative.name}
              </Anchor>
            </Text>
            <Text c="dimmed" fz="xs">
              {/* Designation: {row.representative.designation.name} */}
            </Text>
          </div>
        </Group>
      </td>
      <td>{row.type}</td>
      <td>
        {row.state_id !== null &&
        row.state_id != "" &&
        typeof row.state_id != "undefined"
          ? row.state.name
          : "Govt. Of India"}
      </td>
      <td>{row.department}</td>
      <td>
        <Group spacing={0} position="left">
          <ActionIcon onClick={() => openUpload(row.id)}>
            <Photo color="orange" size={18} />
          </ActionIcon>
          <ActionIcon onClick={() => handleEdit(row.id)}>
            <Pencil size={18} color="gray" />
          </ActionIcon>
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.id)}
            color="red"
          >
            <Trash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  return (
    <div>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb
          Text="Government Ministry"
          Title="Settings"
          titleLink="/settings"
        />
      </Skeleton>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card className="border">
          <ScrollArea>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* For search the table data input forms */}
              <TextInput
                variant="filled"
                placeholder="Search"
                mb="md"
                size="sm"
                value={search}
                icon={<Search size={16} />}
                onChange={async (e) => {
                  // On change search ofr the data that is enter
                  setSearch(e.currentTarget.value);
                  setPage(1);
                  const datas = await dataSearch({
                    data: variables.data,
                    value: e.currentTarget.value,
                    activePage: activePage,
                    total: total,
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                sx={{ width: 250 }}
              />
              <div>
                <Group spacing="xs">
                  {/* For export the the table data to pdf and excels */}
                  <Text>Exports :</Text>
                  <CSVLink
                    data={variables.data}
                    headers={[
                      { label: "State", key: "name" },
                      { label: "Formation Year", key: "formation_year" },
                    ]}
                    filename="SchemeDepartment.csv"
                    className={classes.pdfExcel}
                  >
                    <img
                      src={excel}
                      alt="excel"
                      width="25"
                      style={{ margin: "2px" }}
                    />
                  </CSVLink>
                  <button
                    className={classes.pdfExcel}
                    onClick={() =>
                      print({
                        name: "Scheme Department",
                        data: variables.data,
                        list: ["name", "formation_year"],
                        header: ["State", "Formation Year"],
                      })
                    }
                  >
                    <img
                      src={pdf}
                      alt="pdf"
                      width="19"
                      style={{ margin: "2px" }}
                    />
                  </button>
                  {/* Drawer open for adding new tax data */}
                  <Button
                    variant="outline"
                    color="orange"
                    size="xs"
                    onClick={() =>
                      setVariables({ ...variables, addDrawer: true })
                    }
                  >
                    + Add Government Ministry
                  </Button>
                </Group>
              </div>
            </div>
            {/* className={classes.striped} */}
            <Table verticalSpacing="sm">
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Representative Name</th>
                  <th>Govt. Type</th>
                  <th>State / Union</th>
                  <th>Department</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {rows.length > 0 ? (
                <tbody>{rows}</tbody>
              ) : (
                <>
                  {loadingData === false ? (
                    <tbody>
                      <tr>
                        <td colSpan={4}>
                          <div style={{ textAlign: "center" }}>
                            <Loader size="xl" color="orange" variant="dots" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={4}>
                          <div style={{ textAlign: "center" }}>
                            <Text weight={500} align="center">
                              Nothing found
                            </Text>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </ScrollArea>
          {/* For display the pagination and no of per pages list */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 15,
            }}
          >
            {/* For pagination */}
            <Pagination
              withEdges
              size="sm"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="orange"
            />
          </div>
        </Card>
      </Skeleton>
      {/* State Add Modal */}
      <Modal
        opened={variables.addDrawer}
        onClose={() => setVariables({ ...variables, addDrawer: false })}
        title={"Add New Government Ministry"}
        size="lg"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div style={{ padding: "10px" }}>
          <form onSubmit={form.onSubmit((values) => handleAdd(values))}>
            <Grid>
              <Grid.Col xs={12}>
                <Select
                  searchable
                  clearable
                  required
                  label="Select Type"
                  placeholder="Select Type"
                  data={[
                    { value: "Union", label: "Union" },
                    { value: "State", label: "State" },
                  ]}
                  {...form.getInputProps("type")}
                />
              </Grid.Col>
              {form.values.type == "State" ? (
                <Grid.Col xs={12}>
                  <Select
                    required
                    searchable
                    clearable
                    label="Select State"
                    placeholder="Select State"
                    data={state}
                    {...form.getInputProps("state_id")}
                  />
                </Grid.Col>
              ) : null}

              <Grid.Col xs={12}>
                <TextInput
                  required
                  label="Department Name"
                  placeholder="Department Name"
                  {...form.getInputProps("department")}
                />
              </Grid.Col>
              <Grid.Col xs={12}>
                <Select
                  required
                  label="Representative"
                  searchable
                  clearable
                  placeholder="Select Representative"
                  data={representative}
                  // onDropdownOpen={handleState}
                  {...form.getInputProps("representative_id")}
                />
              </Grid.Col>
              <Grid.Col xs={12}>
                <Button
                  loading={loadingState}
                  fullWidth
                  radius="md"
                  type="submit"
                  size="md"
                  variant="gradient"
                  gradient={{ from: "orange", to: "red" }}
                >
                  Add Government Ministry
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </Modal>
      {/* // State Add MOdal */}
      {/* Edit Modal */}
      <Modal
        opened={variables.openEdit}
        onClose={() => setVariables({ ...variables, openEdit: false })}
        title={"Update Government Ministry Details"}
        size="lg"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div style={{ padding: "10px" }}>
          <form onSubmit={editForm.onSubmit((values) => handleEdits(values))}>
            <Grid>
              <Grid.Col xs={12}>
                <Select
                  searchable
                  clearable
                  required
                  label="Select Type"
                  placeholder="Select Type"
                  data={[
                    { value: "Union", label: "Union" },
                    { value: "State", label: "State" },
                  ]}
                  {...editForm.getInputProps("type")}
                />
              </Grid.Col>
              {editForm.values.type == "State" ? (
                <Grid.Col xs={12}>
                  <Select
                    required
                    searchable
                    clearable
                    label="Select State"
                    placeholder="Select State"
                    data={state}
                    {...editForm.getInputProps("state_id")}
                  />
                </Grid.Col>
              ) : null}

              <Grid.Col xs={12}>
                <TextInput
                  required
                  label="Department Name"
                  placeholder="Department Name"
                  {...editForm.getInputProps("department")}
                />
              </Grid.Col>
              <Grid.Col xs={12}>
                <Select
                  required
                  label="Representative"
                  searchable
                  clearable
                  placeholder="Select Representative"
                  data={representative}
                  {...editForm.getInputProps("representative_id")}
                />
              </Grid.Col>
              <Grid.Col xs={12}>
                <Button
                  loading={loadingState}
                  fullWidth
                  radius="md"
                  type="submit"
                  size="md"
                  variant="gradient"
                  gradient={{ from: "orange", to: "red" }}
                >
                  Update Government Ministry
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </Modal>
      {/* Edit Modal */}

      {/* Image Modal */}
      <Modal
        opened={variables.imageDrawer}
        onClose={() => setVariables({ ...variables, imageDrawer: false })}
        title={imageTitle}
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <form onSubmit={formImage.onSubmit((values) => handleUpload(values))}>
          <Grid style={{ padding: "10px" }}>
            <Grid.Col md={12} lg={12} mt={1}>
              {/* <Text>Upload State Image</Text> */}
            </Grid.Col>
            <Grid.Col md={12} lg={12} mt={1}>
              {/* For image croper */}
              {/* For croper */}
              {upImg !== "" && upImg !== null ? (
                <>
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={upImg}
                      style={{
                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                      }}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                  <div>
                    {previewCanvasRef != null ? (
                      <canvas
                        ref={previewCanvasRef}
                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                        style={{
                          width: Math.round(completedCrop?.width ?? 0),
                          height: Math.round(completedCrop?.height ?? 0),
                          marginBottom: 50,
                          display: "none",
                        }}
                      />
                    ) : null}

                    <Group position="right" mt="md" mb={20}>
                      <Button
                        type="submit"
                        color="zevcore"
                        onClick={() => {
                          setUpImg("");
                          setImage("");
                        }}
                      >
                        Clear Image
                      </Button>
                    </Group>
                  </div>
                </>
              ) : (
                // For selecting cropping image dropdown
                <div
                  style={{
                    marginTop: 15,
                    position: "relative",
                    marginBottom: 30,
                  }}
                >
                  <Paper
                    p="lg"
                    sx={(theme) => ({
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[8]
                          : theme.white,
                    })}
                  >
                    <Avatar
                      src={URL_CONSTANTS.REPRESENTATIVE_IMAGE + profileimage}
                      size={150}
                      radius={100}
                      withBorder
                      mx="auto"
                    />
                    <Text ta="center" fz="lg" weight={500} mt="md">
                      {imageTitle}
                    </Text>

                    <Group position="center">
                      <FileButton
                        resetRef={ref}
                        onChange={changeHandler}
                        accept={[
                          "image/png",
                          "image/jpeg",
                          "image/sgv+xml",
                          "image/gif",
                        ]}
                      >
                        {(props) => (
                          <Button mt={20} {...props}>
                            Select Image
                          </Button>
                        )}
                      </FileButton>
                    </Group>
                  </Paper>
                </div>
              )}
            </Grid.Col>
            <Button
              loading={loadingState}
              fullWidth
              type="submit"
              size="md"
              variant="gradient"
              gradient={{ from: "orange", to: "red" }}
            >
              Upload Ministry Image
            </Button>
          </Grid>
        </form>
      </Modal>
      {/* Image Modal */}
    </div>
  );
}

export default GovernmentMinistry;
